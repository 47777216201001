import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Label, Icon, Popup, Header } from 'semantic-ui-react';
import moment from 'moment';
import connect from 'lib/reduxConnect';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';

import { truncate } from 'app/util/text';

const getHeaderName = ({ targetType }, extraTargets) => {
  switch (targetType) {
    case 'aircraft':
      return 'Aircraft';
    case 'comment':
      return 'Comments';
    default:
      return (extraTargets && extraTargets[targetType]) || '...';
  }
};

class Latest extends Component {
  state = {
    loading: true,
    error: false,
    extraTargets: {},
  };

  componentDidMount() {
    this.getData();
    const latestKPIOptions = this.props.customerOptions.find(
      option => option.tei === 'latestKPI',
    );
    if (latestKPIOptions) {
      const extraTargets = JSON.parse(latestKPIOptions.value);
      this.setState({ extraTargets });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = async () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data.length) {
        this.setState({
          loading: false,
          data: this.props.data,
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  onSettingsClose = async () => {
    await this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
    this.forceUpdate();
  };

  setTarget = async targetType => {
    await this.props.saveKPI(this.props.kpi.i, { targetType });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  goToRequests = async datum => {
    const filters = [];
    const selection = [];
    let ignore = false;
    switch (this.props.kpi.payload.targetType) {
      case 'comment':
        ignore = true;
        break;
      case 'aircraft':
        filters.push({
          name: 'Aircraft',
          type: 'Aircraft',
        });
        selection.push({
          value: datum.id,
        });
        break;

      default:
        if (datum.interopId) {
          filters.push({
            type: 'Interop',
            id: datum.interopId,
            name: this.state.extraTargets[this.props.kpi.payload.targetType],
          });
          selection.push({
            title: datum.text,
          });
        }
        break;
    }
    if (ignore) return;
    this.props.actions.setFilters(filters, selection);
    this.props.actions.markDirty(true);
    this.props.actions.navigate('/explorer');
  };

  getHeader() {
    const targets = [
      {
        title: 'Comments',
        type: 'comment',
      },
      {
        title: 'Aircraft',
        type: 'aircraft',
      },
    ];
    Object.keys(this.state.extraTargets).forEach(type => {
      targets.push({ type, title: this.state.extraTargets[type] });
    });
    return (
      <KPI.Header
        title={`Latest ${getHeaderName(
          this.props.kpi.payload,
          this.state.extraTargets,
        )}`}
        onClose={this.onSettingsClose}
      >
        <List relaxed divided selection>
          <Header>
            Latest
            <Header.Subheader>
              Choose which kind of data you wish to display in this block
            </Header.Subheader>
          </Header>
          {targets.map((filter, index) => (
            <List.Item key={index} onClick={() => this.setTarget(filter.type)}>
              <List.Content floated="right">
                <Icon name="chevron right" />
              </List.Content>
              <List.Content>
                <List.Header>{filter.title}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </KPI.Header>
    );
  }

  render() {
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.state.loading}
        error={this.state.error}
        getData={this.getData}
        deleteKPI={this.props.deleteKPI}
        extraClasses="kpiContainerTimeline"
      >
        {this.getHeader()}
        <KPI.Data>
          <div style={{ flex: 1 }}>
            <List relaxed inverted>
              {this.state.data &&
                this.state.data.map((d, index) => {
                  let date = moment(d.date);
                  if (date.isValid()) date = date.format('YYYY-MM-DD HH:mm');
                  else date = d.date;
                  return (
                    <Popup
                      header={d.text}
                      content={`${d.requestCount} requests - ${date}`}
                      key={index}
                      position="top left"
                      trigger={
                        <List.Item onClick={() => this.goToRequests(d)}>
                          <List.Content>
                            <List.Description as={d.id ? 'a' : null}>
                              <Label size="tiny" horizontal color="pink">
                                <Icon name="bolt" /> {d.requestCount}
                              </Label>
                              <span title={d.text}>{truncate(d.text, 40)}</span>
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      }
                    />
                  );
                })}
            </List>
          </div>
        </KPI.Data>
      </KPI>
    );
  }
}

Latest.propTypes = {
  ...KPIProps,
  customerOptions: PropTypes.array,
};

const mapStateToProps = state => ({
  customerOptions: state.auth.customer.options,
});

export default connect(mapStateToProps)(Latest);
