import React, { useState } from 'react';
import { PropTypes } from 'victory';
import Lightbox from 'react-image-lightbox';

const ImagesLighBox = ({ photo, src }) => {
  const [lighboxOpen, setLightboxOpen] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setLightboxOpen(true);
        }}
        style={{
          border: 0,
          background: 'transparent',
          margin: 0,
          padding: 0,
          cursor: 'pointer',
        }}
      >
        <img class="ui image" src={src} alt={photo.fileName} />
      </button>

      {lighboxOpen && (
        <Lightbox mainSrc={src} onCloseRequest={() => setLightboxOpen(false)} />
      )}
    </>
  );
};

const ImagesInLine = ({ photos, getPhotoSrc }) => {
  return (
    <div class="ui tiny images">
      {photos.map(photo => (
        <>
          <ImagesLighBox photo={photo} src={getPhotoSrc(photo.fileName)} />
        </>
      ))}
    </div>
  );
};

ImagesInLine.propTypes = {
  photos: PropTypes.Array,
  getPhotoSrc: PropTypes.func,
};

export default ImagesInLine;
