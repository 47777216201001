import { FILE_API_URL } from 'config/network';
import placeholder from 'assets/logo-placeholder.png';

export const getAvatarURL = (avatarLocal, options, customer, token) => {
  let avatar = null;
  if (avatarLocal && options && customer && token) {
    let file = avatarLocal;
    if (file.includes(',')) {
      const fileArray = file.split(',');
      file = fileArray[fileArray.length - 1];
    }
    if (!file || file === 'NC') {
      avatar = placeholder;
    } else {
      const prefix = options.customer ? customer.directory : 'COMMON/';
      avatar = `${FILE_API_URL}/${prefix}${options.path}${file}?token=${token}`;
    }
  }
  return avatar;
};
