import React from 'react';

export default ({
  closed,
  open,
  critical,
  none,
  style,
  displayLabel = false,
}) => {
  return (
    <div className="cardProgress" style={style}>
      <div
        style={{
          flex: closed,
          background: '#6A9929',
          ...blockStyle,
        }}
      >
        {displayLabel && closed != 0 && <div style={labelStyle}>{closed}</div>}
      </div>
      <div
        style={{
          flex: open,
          background: 'orange',
          ...blockStyle,
        }}
      >
        {displayLabel && open != 0 && <div style={labelStyle}>{open}</div>}
      </div>
      <div
        style={{
          flex: critical,
          background: '#BC0300',
          ...blockStyle,
        }}
      >
        {displayLabel && critical != 0 && (
          <div style={labelStyle}>{critical}</div>
        )}
      </div>
      <div
        style={{
          flex: none,
          background: 'rgb(240, 240, 240)',
        }}
      />
    </div>
  );
};

const blockStyle = {
  position: 'relative',
  filter: 'drop-shadow(0 0 0.25em rgba(0, 0, 0, 0.25))',
};
const labelStyle = {
  color: 'white',
  fontSize: '0.75em',
  fontWeight: 'lighter',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  filter: 'drop-shadow(0 0 0.25em rgba(0, 0, 0, 0.25))',
};
