import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

import AircraftDetails from './aircraftDetail';

export default class Aircraft extends Component {
  renderBody() {
    return (
      <AircraftDetails
        {...this.props}
        selectEquipment={this.props.selectEquipment}
        selectedEquipment={this.props.selectedEquipment}
        selectCategory={this.props.selectCategory}
        selectedCategory={this.props.selectedCategory}
        rawTags={this.props.rawTags}
        tagFilter={this.props.tagFilter}
        setTagFilter={this.props.setTagFilter}
        setEditedTag={this.props.setEditedTag}
      />
    );
  }

  render() {
    return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div
          className="rfidPlane"
          onClick={() => this.props.selectAircraft(this.props.aircraft.id)}
          tabIndex={0}
          role="button"
          style={{ cursor: this.props.show ? 'inherit' : 'pointer' }}
        >
          <div style={{ flex: 1 }}>{this.props.aircraft.registration}</div>
          <div style={{ flex: 1 }}>{this.props.aircraft.type}</div>
          <div style={{ flex: 2, display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Label color="orange">
                {this.props.aircraft.routineCount || 0}
              </Label>
            </div>
            <div style={{ flex: 1 }}>
              <Label color="green">
                {this.props.aircraft.closedCount || 0}
              </Label>
            </div>
            <div style={{ flex: 1 }}>
              <Label color="blue">{this.props.aircraft.unassignedCount}</Label>
            </div>
            <div style={{ flex: 1 }}>
              <Label color="red">{this.props.aircraft.expiredCount}</Label>
            </div>
            <div style={{ flex: 1 }}>
              <Label color="orange">
                {this.props.aircraft.willExpireCount}
              </Label>
            </div>
          </div>
        </div>
        {this.props.show && this.renderBody()}
      </div>
    );
  }
}
Aircraft.propTypes = {
  aircraft: PropTypes.object,
  rawTags: PropTypes.array,
  selectAircraft: PropTypes.func,
  show: PropTypes.bool,
  scene: PropTypes.array,
  categories: PropTypes.array,
  selectEquipment: PropTypes.func,
  selectedEquipment: PropTypes.number,
  selectCategory: PropTypes.func,
  selectedCategory: PropTypes.string,
  tagFilter: PropTypes.string,
  setTagFilter: PropTypes.func,
  setEditedTag: PropTypes.func,
};
