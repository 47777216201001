import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  aircraftId: null,
  documentId: null,
  lopaId: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CREATION_SET_AIRCRAFT:
        draft.aircraftId = action.payload.aircraftId;
        break;
      case types.CREATION_SET_CMM:
        draft.documentId = action.payload.documentId;
        draft.lopaId = action.payload.lopaId;
        break;
    }
  });
