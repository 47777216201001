import React, { Component, Fragment } from 'react';
import { Segment, Header, Form, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { debounce } from 'debounce';
import moment from 'moment';
import hash from 'object-hash';
import {
  hasPermissionToDelete,
  hasPermissionToEdit,
  hasPermissionToRead,
} from 'lib/permissions';

import ModifyPrioritiesModal from './modifyPrioritiesModal';
import { round } from '../utils';
import { formatWrDate } from 'app/util/dates';

export default class ModifyHero extends Component {
  state = {
    critical: false,
    priority: '',
    reqQty: 1,
    description: '',
    date: '',
    time: '',
    pn: '',

    changeset: [],

    dateError: false,
    timeError: false,
    updateError: false,
    dataHash: '',

    prioritiesOpen: false,
  };
  componentDidMount() {
    this.setupComponent();
  }
  componentDidUpdate(prevProps, prevState) {
    if (hash(this.props.data) !== prevState.dataHash) this.setupComponent();
  }

  setupComponent = () => {
    this.setState(
      produce(draft => {
        if (this.props.data.ids.length === 1) {
          const request = this.props.data.requests[0];
          draft.critical = request.critical;
          draft.priority = request.priority;
          draft.reqQty = parseFloat(request.reqQty);
          draft.description = request.description;
          draft.pn = request.pn;
          draft.date = moment(request.date).format('YYYY-MM-DD');
          draft.time = moment(request.date).format('HH:mm');
        }
        draft.dataHash = hash(this.props.data);
        draft.changeset = [];
      }),
    );
  };

  restoreItems = () => {
    const request = this.props.data.requests[0];
    const item = this.props.data.items.find(i => i.id === request.item);
    if (item) {
      this.updateField('description')(null, { value: item.descriptionShort });
      this.updateField('pn')(null, { value: item.partNumber });
    }
  };

  buildUpdateChangeset = async () => {
    if (this.state.changeset.length === 0) return;
    const changeset = { Request: [] };
    let dateSet = false;
    const data = this.state.changeset.reduce((dataObj, field) => {
      if (field === 'reqQty')
        return { ...dataObj, quantity: this.state[field] };
      if (field === 'date' || field === 'time') {
        if (!dateSet) {
          dateSet = true;
          const date = moment(this.state.date, 'YYYY-MM-DD', true);
          const time = moment(this.state.time, 'HH:mm', true);
          date.hour(time.hour());
          date.minute(time.minute());
          return { ...dataObj, date: date.toISOString() };
        } else return dataObj;
      }
      if (field === 'priority') {
        const priority = this.props.data.priorities.find(
          p => p.groupName === this.state.priority,
        );
        if (!priority) return dataObj;
        const request = this.props.data.requests[0];
        const families = priority.families.split(',');
        const index = families.indexOf(request.family.toString());
        if (index === -1) return dataObj;
        const PriorityId = priority.ids.split(',')[index];
        return { ...dataObj, PriorityId };
      }
      if (field === 'description') {
        return { ...dataObj, itemDescriptionShort: this.state.description };
      }
      if (field === 'pn') {
        return { ...dataObj, itemPartNumber: this.state.pn };
      }
      if (field === 'critical' && this.props.data.requests.length === 1) {
        return { ...dataObj, notificationFlag: this.state.critical ? 1 : 0 };
      }
      return { ...dataObj, [field]: this.state[field] };
    }, {});
    this.props.data.requests.forEach(request => {
      changeset.Request.push({
        action: 'update',
        data: {
          id: request.id,
          ...data,
        },
      });
    });
    const update = await this.props.applyChangeset(changeset);
    if (!update || update.changeset.length === 0) {
      this.setState(
        produce(draft => {
          draft.updateError = true;
        }),
      );
      this.setupComponent();
    } else {
      this.setState(
        produce(draft => {
          draft.updateError = false;
        }),
      );
    }
  };

  buildDeleteChangeset = () => {
    const changeset = { Request: [] };
    this.props.data.requests.forEach(request => {
      changeset.Request.push({
        action: 'delete',
        data: {
          id: request.id,
        },
      });
    });
    this.props.applyChangeset(changeset);
  };

  updateField = field => (e, { value }) => {
    let doChangeset = false;
    if (!this.state.changeset.includes(field)) doChangeset = true;
    this.setState(
      produce(draft => {
        draft[field] = value;
        if (doChangeset) draft.changeset.push(field);
      }),
    );
    if (field === 'reqQty') this.keepQuantityCast();
    if (field === 'date') this.keepDateCast();
    if (field === 'time') this.keepTimeCast();
  };

  updateCritical = () => {
    this.setState(
      produce(draft => {
        draft.critical = !this.state.critical;
        if (!this.state.changeset.includes('critical'))
          draft.changeset.push('critical');
      }),
    );
  };

  keepQuantityCast = debounce(() => {
    const reqQty = parseFloat(this.state.reqQty);
    this.setState(
      produce(draft => {
        if (!isNaN(reqQty) && reqQty > 0) draft.reqQty = round(reqQty, 1);
        else draft.reqQty = 0.1;
      }),
    );
  }, 500);

  keepDateCast = debounce(() => {
    const date = moment(this.state.date, 'YYYY-MM-DD', true);
    this.setState(
      produce(draft => {
        if (date.isValid() && this.state.dateError) {
          draft.dateError = false;
          draft.date = date.format('YYYY-MM-DD');
        } else if (!date.isValid()) {
          draft.dateError = true;
        }
      }),
    );
  }, 500);

  keepTimeCast = debounce(() => {
    const time = moment(this.state.time, 'HH:mm', true);
    this.setState(
      produce(draft => {
        if (time.isValid() && this.state.timeError) {
          draft.timeError = false;
          draft.time = time.format('HH:mm');
        } else if (!time.isValid()) {
          draft.timeError = true;
        }
      }),
    );
  }, 500);

  incrementQuantity = value => () => {
    const reqQty = parseFloat(this.state.reqQty);
    if (!isNaN(value))
      this.setState(
        produce(draft => {
          if (reqQty + value > 0) draft.reqQty = round(reqQty + value, 1);
          else draft.reqQty = 0.1;
          if (!this.state.changeset.includes('reqQty'))
            draft.changeset.push('reqQty');
        }),
      );
  };

  multiChangePriorities = async newPriorities => {
    const changeset = { Request: [] };
    newPriorities.forEach(PriorityId => {
      if (PriorityId === -1) return;
      const priority = this.props.data.priorities.find(p =>
        p.ids.includes(PriorityId),
      );
      if (!priority) return;
      const prioIndex = priority.ids.split(',').indexOf(PriorityId.toString());
      const families = priority.families.split(',');
      const familyId = families[prioIndex];
      if (familyId !== -1) {
        this.props.data.requests
          .filter(r => r.family.toString() === familyId)
          .forEach(request => {
            changeset.Request.push({
              action: 'update',
              data: {
                id: request.id,
                PriorityId,
              },
            });
          });
      }
    });
    this.closeChangePrioritiesModal();
    this.props.applyChangeset(changeset);
  };

  closeChangePrioritiesModal = () => {
    this.setState(
      produce(draft => {
        draft.prioritiesOpen = false;
      }),
    );
  };

  openChangePrioritiesModal = () => {
    this.setState(
      produce(draft => {
        draft.prioritiesOpen = true;
      }),
    );
  };

  render() {
    const families = [];

    this.props.data.requests.forEach(r => {
      if (!families.includes(r.family)) families.push(r.family);
    });
    return (
      <Segment id="Hero" padded color="pink">
        <Header as="h2">
          Request
          <Header.Subheader>Basic information</Header.Subheader>
        </Header>
        {this.state.updateError && (
          <div className="modifyRequestError">
            Could not update request, please check data
          </div>
        )}

        {hasPermissionToRead(this.props.permissions, 'Request') && (
          <Fragment>
            <Form>
              <Form.Group widths="equal">
                {families.length > 1 ? (
                  <Form.Button
                    label="Action"
                    content="Update action"
                    fluid
                    onClick={() => this.openChangePrioritiesModal()}
                  />
                ) : (
                  <Form.Dropdown
                    label="Action"
                    placeholder="Action"
                    selection
                    options={this.props.data.priorities.map(prio => ({
                      text: prio.groupName,
                      value: prio.groupName,
                    }))}
                    value={this.state.priority}
                    onChange={this.updateField('priority').bind(this)}
                    fluid
                  />
                )}
                <Form.Input
                  label="Quantity"
                  placeholder="Quantity"
                  fluid
                  value={this.state.reqQty}
                  onChange={this.updateField('reqQty').bind(this)}
                  action
                >
                  <input />
                  <Button
                    icon="minus"
                    onClick={this.incrementQuantity(-1).bind(this)}
                  />
                  <Button
                    icon="add"
                    onClick={this.incrementQuantity(1).bind(this)}
                  />
                </Form.Input>
              </Form.Group>
              <Form.Checkbox
                label="Critical"
                className="criticalCheckbox"
                checked={this.state.critical}
                toggle
                onChange={this.updateCritical.bind(this)}
              />
              <Form.Group>
                <Form.TextArea
                  label="Part Number"
                  placeholder="Part Number"
                  value={this.state.pn}
                  onChange={this.updateField('pn').bind(this)}
                  width="6"
                />
                <Form.TextArea
                  label="Description"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={this.updateField('description').bind(this)}
                  width="6"
                />
                <div style={{ marginLeft: '1em', marginTop: '3em' }}>
                  <Form.Button
                    icon="sync"
                    fluid
                    basic
                    content="Restore items"
                    onClick={() => this.restoreItems()}
                    disabled={this.props.data.ids.length > 1}
                  />
                </div>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label={`Date (YYYY-MM-DD) ${
                    this.state.dateError ? '- Invalid format' : ''
                  }`}
                  placeholder="Date (YYYY-MM-DD)"
                  fluid
                  value={formatWrDate(this.state.date)}
                  onChange={this.updateField('date').bind(this)}
                  error={this.state.dateError}
                />
                <Form.Input
                  label={`Time (HH:mm) ${
                    this.state.timeError ? '- Invalid format' : ''
                  }`}
                  placeholder="Time (HH:mm)"
                  fluid
                  value={this.state.time}
                  onChange={this.updateField('time').bind(this)}
                  error={this.state.timeError}
                />
              </Form.Group>
              <Form.Group inline>
                <Form.Button
                  icon="save"
                  basic
                  primary
                  content="Apply modifications"
                  disabled={
                    !hasPermissionToEdit(this.props.permissions, 'Request') ||
                    this.state.changeset.length === 0 ||
                    this.state.dateError ||
                    this.state.timeError
                  }
                  onClick={() => this.buildUpdateChangeset()}
                />
                <Form.Button
                  color="red"
                  icon="remove"
                  basic
                  content="Delete all requests"
                  disabled={
                    !hasPermissionToDelete(this.props.permissions, 'Request')
                  }
                  onClick={() => this.buildDeleteChangeset()}
                />
              </Form.Group>
            </Form>
            <ModifyPrioritiesModal
              open={this.state.prioritiesOpen}
              onClose={this.closeChangePrioritiesModal}
              save={this.multiChangePriorities}
              {...this.props.data}
            />
          </Fragment>
        )}
      </Segment>
    );
  }
}

ModifyHero.propTypes = {
  data: PropTypes.object,
  applyChangeset: PropTypes.func,
  permissions: PropTypes.array,
};
