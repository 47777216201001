import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  firstCall: true,
  chartConfig: { groupBy: 'date', periode: 'm-12', data: [], pagination: 0 },
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.MAINTENANCE_SET_FIRST_CALL:
        draft.firstCall = action.payload.firstCall;
        break;
      case types.MAINTENANCE_SET_CHART_CONFIG:
        draft.chartConfig = action.payload.chartConfig;
        break;
    }
  });
