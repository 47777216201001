import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Icon } from 'semantic-ui-react';

import connect from 'lib/reduxConnect';
import { verifyFeature } from 'config/navigation';

class AdminHome extends Component {
  static getPathName = location => {
    if (location.pathname.includes('users')) return 'User permissions';
    if (location.pathname.includes('devices')) return 'Devices';
    if (location.pathname.includes('documents')) return 'Documents';
    if (location.pathname.includes('linked')) return 'Linked Data';
    return null;
  };

  navigate = path => {
    this.props.actions.navigate(`/admin/${path}`);
  };

  render() {
    return (
      <div className="contentMargin">
        <h1
          style={{
            fontSize: '2em',
            fontWeight: 'lighter',
            textAlign: 'center',
          }}
        >
          Welcome to the administration
        </h1>
        <p
          style={{
            textAlign: 'center',
            marginBottom: '2em',
          }}
        >
          What do you wish to modify?
        </p>
        <Grid
          columns={2}
          centered
          relaxed
          style={{ maxWidth: '50em', margin: '0 auto' }}
        >
          <Grid.Row centered columns={2}>
            <Grid.Column>
              {(verifyFeature('editUsers', this.props.authUserFeatures) && (
                <Button
                  primary
                  fluid
                  size="huge"
                  icon="users"
                  content="User permissions"
                  onClick={() => this.navigate('users')}
                />
              )) || <Icon name="lock" size="huge" />}
            </Grid.Column>
            <Grid.Column>
              {(verifyFeature('deviceList', this.props.authUserFeatures) && (
                <Button
                  primary
                  fluid
                  size="huge"
                  icon="tablet alternate"
                  content="Devices"
                  onClick={() => this.navigate('devices')}
                />
              )) || <Icon name="lock" size="huge" />}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered columns={2}>
            <Grid.Column>
              {(verifyFeature('documentList', this.props.authUserFeatures) && (
                <Button
                  primary
                  fluid
                  size="huge"
                  icon="file outline"
                  content="Documents"
                  onClick={() => this.navigate('documents')}
                />
              )) || <Icon name="lock" size="huge" />}
            </Grid.Column>
            <Grid.Column>
              {(verifyFeature('linkedData', this.props.authUserFeatures) && (
                <Button
                  primary
                  fluid
                  size="huge"
                  icon="database"
                  content="Linked data"
                  onClick={() => this.navigate('linked')}
                />
              )) || <Icon name="lock" size="huge" />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

AdminHome.propTypes = {
  authUserFeatures: PropTypes.array,
};

const mapStateToProps = state => ({
  authUserFeatures: state.auth.profile.features,
});

export default connect(mapStateToProps)(AdminHome);
