import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';

import Detail from './containers/detail';

class DetailContainer extends Component {
  static getActions(location, actions) {
    return (
      <div className="pageActions">
        <Button
          primary
          icon="chevron left"
          content="Go back"
          onClick={() => actions.navBack()}
        />
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  scrollToTop = () => {
    if (this.containerRef.current) this.containerRef.current.scrollTo(0, 0);
  };

  render() {
    const prefix = this.props.match.url;
    return (
      <div
        className="smallContentMargin scrollableContainer"
        ref={this.containerRef}
      >
        <Switch>
          <Route
            path={`${prefix}/:requestId([0-9]+)`}
            render={() => <Detail scrollToTop={this.scrollToTop} />}
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

export default connect()(DetailContainer);
