import * as types from 'config/types';
import { API, post, buildHeaders, buildURL, buildData } from 'lib/rest';
import { ENDPOINTS } from 'config/network';
import { defaultColumns } from 'app/util/defaultColumns';
import { convertBlobToBase64 } from 'app/util/blob';

// TODO handle offline data fetching
export const getReportData = (reportId, type) => async (dispatch, getState) => {
  dispatch(setReportId(reportId, type));
  const token = getState().auth.token;
  const layout = await API.post(ENDPOINTS.report.layout, token, {
    reportId,
    type,
  });
  if (!layout || !layout.metadata) {
    dispatch({
      type: types.REPORT_SET_LAYOUT,
      payload: {
        type,
        layout: false,
      },
    });
    return;
  }
  dispatch({
    type: types.REPORT_SET_LAYOUT,
    payload: {
      type,
      layout,
    },
  });
  if (layout.metadata.pdf !== 'print') {
    dispatch(getReportPDF(reportId, layout.metadata, type));
  } else {
    dispatch(
      getReportSectionData('metadata', reportId, layout.metadata.dataAccessor, [
        { accessor: layout.metadata.leadAccessor },
        { accessor: layout.metadata.thumbnailAccessor },
      ]),
    );

    await Promise.all(
      Object.keys(layout.sections).map(async section => {
        const rows = layout.sections[section].rows;
        const widgets = [];
        rows.forEach(row => {
          row.forEach(widget => {
            widgets.push({ accessor: widget.dataAccessor });
          });
        });
        dispatch(
          getReportSectionData(
            section,
            reportId,
            layout.metadata.dataAccessor,
            widgets,
          ),
        );
      }),
    );
  }
};

export const getReportSectionData = (
  section,
  reportId,
  baseAccessor,
  widgets,
  extraData,
) => async (dispatch, getState) => {
  if (section.requests) dispatch(setReportBusy('Getting data, please wait...'));
  const token = getState().auth.token;
  const data = await API.post(ENDPOINTS.report.data, token, {
    reportId,
    baseAccessor,
    widgets,
    extraData,
  });
  // this means we want a request table
  if (section.requests) return dispatch(getRequestList(data[0]));
  dispatch({
    type: types.REPORT_SET_DATA,
    payload: {
      section,
      data,
    },
  });
};

export const getReportPDF = (reportId, metadata, type) => async (
  dispatch,
  getState,
) => {
  const token = getState().auth.token;
  const headers = buildHeaders(token);
  const body = buildData({
    reportId,
    accessor: metadata.pdf,
    baseAccessor: metadata.dataAccessor,
    type,
  });
  const rawData = await post(
    buildURL(ENDPOINTS.report.pdf),
    {
      body,
      headers,
    },
    false,
  );
  const blob = await rawData.blob();
  const data = await convertBlobToBase64(blob);
  dispatch({
    type: types.REPORT_SET_DATA,
    payload: {
      section: 'pdf',
      data,
    },
  });
};

/**
 * show an overlay to the user to indicate something's happening on the backend
 * @param {*} busy can be a string to be shown in overlay, or false to hide
 */
export const setReportBusy = busy => ({
  type: types.REPORT_IS_BUSY,
  payload: {
    busy,
  },
});

const getRequestList = requestIds => async (dispatch, getState) => {
  const token = getState().auth.token;
  const response = await API.post(ENDPOINTS.webreporting.requests, token, {
    requestIds,
  });
  const displayedColumns =
    response.userSettings.length === 0 ? defaultColumns : response.userSettings;
  const data = [[...displayedColumns]];
  response.requests.forEach(request => {
    const rawRequestRow = [];
    displayedColumns.forEach(column => {
      if (!response.columns[column]) return;
      rawRequestRow.push(request[column]);
    });
    data.push(rawRequestRow);
  });
  dispatch(setReportBusy(false));
  return data;
};

export const setReportId = (reportId, type) => ({
  type: types.REPORT_SET_CURRENT,
  payload: {
    reportId,
    type,
  },
});

export const getReportList = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const lists = await API.post(ENDPOINTS.report.list, token);
  dispatch({
    type: types.REPORT_SET_LISTS,
    payload: {
      lists,
    },
  });
};

export const setReportListLayout = listLayout => ({
  type: types.REPORT_SET_LIST_LAYOUT,
  payload: {
    listLayout,
  },
});
