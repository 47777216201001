/* eslint-disable */
import React, { useState } from 'react';

export const SimpleCounters = ({ dataExtraKpi }) => {
  const [counter] = useState(dataExtraKpi);
  return (
    <div class="ui five column grid" style={{ marginTop: '5px' }}>
      <div class="column">
        <div class="ui segment" style={{ padding: '10px' }}>
          <div class="ais-stat card">
            <div class="content">
              <div class="description">
                <div class="statistic" style={{ marginTop: '20px' }}>
                  <div class="value">
                    <i class="user icon grey" />{' '}
                    <strong>{counter.users}</strong>
                  </div>
                  <div class="label">
                    Users
                    {counter.usersDisabled > 0 && (
                      <div
                        class="ui label red"
                        style={{ fontSize: '10px', marginLeft: '5px' }}
                      >
                        Disabled
                        <div class="detail">{counter.usersDisabled}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui segment" style={{ padding: '10px' }}>
          <div class="ais-stat card">
            <div class="content">
              <div class="description">
                <div class="statistic" style={{ marginTop: '20px' }}>
                  <div class="value">
                    <i class="tablet alternate icon grey" />{' '}
                    <strong>{counter.devices}</strong>
                  </div>
                  <div class="label">
                    Devices
                    {counter.devicesDisabled > 0 && (
                      <div
                        class="ui label red"
                        style={{ fontSize: '10px', marginLeft: '5px' }}
                      >
                        Blocked
                        <div class="detail">{counter.devicesDisabled}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui segment" style={{ padding: '10px' }}>
          <div class="ais-stat card">
            <div class="content">
              <div class="description">
                <div class="statistic" style={{ marginTop: '20px' }}>
                  <div class="value">
                    <i class="plane icon grey" />{' '}
                    <strong>{counter.aircrafts}</strong>
                  </div>
                  <div class="label">Aircrafts</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui segment" style={{ padding: '10px' }}>
          <div class="ais-stat card">
            <div class="content">
              <div class="description">
                <div class="statistic" style={{ marginTop: '20px' }}>
                  <div class="value">
                    <i class="table icon grey" />{' '}
                    <strong>{counter.requests}</strong>
                  </div>
                  <div class="label">
                    Requests
                    <div
                      class="ui label red"
                      style={{ fontSize: '10px', marginLeft: '5px' }}
                    >
                      Not corrected
                      <div class="detail">{counter.requestsNotCorrected}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="ui segment" style={{ padding: '10px' }}>
          <div class="ais-stat ais-critical card">
            <div class="content">
              <div class="description">
                <div class="statistic" style={{ marginTop: '20px' }}>
                  <div class="value">
                    <i class="table icon red" />{' '}
                    <strong>{counter.criticals}</strong>
                  </div>
                  <div class="label">
                    Criticals
                    <div
                      class="ui label red"
                      style={{ fontSize: '10px', marginLeft: '5px' }}
                    >
                      Not corrected
                      <div class="detail">{counter.criticalsNotCorrected}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
