import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Image, Button, List, Header } from 'semantic-ui-react';
import moment from 'moment';

import { FILE_API_URL } from 'config/network';
import placeholder from 'assets/logo-placeholder.png';
import StackedChart from 'app/util/stackedChart';

export default class ReportCollection extends Component {
  constructor(props) {
    super(props);
    const maxRequests = this.props.collection.list.reduce((maxReq, report) => {
      const requestCount =
        (report.requests &&
          parseFloat(report.requests.closed) +
            parseFloat(report.requests.open) +
            parseFloat(report.requests.critical)) ||
        0;
      if (requestCount > maxReq) return requestCount;
      return maxReq;
    }, 0);
    this.state = { maxRequests };
  }

  navigateToReport = report => {
    window.open(`/report/${this.props.type}/${report.id}`, '_blank');
  };

  renderCard(report, index) {
    const requestCount =
      (report.requests &&
        parseFloat(report.requests.closed) +
          parseFloat(report.requests.open) +
          parseFloat(report.requests.critical)) ||
      0;
    const coverImg =
      (report.thumbnail &&
        `${FILE_API_URL}/${this.props.customer.directory}${
          report.thumbnail
        }?token=${this.props.token}`) ||
      placeholder;
    return (
      <Card key={index}>
        <Image src={coverImg} className="cardImage" />
        <Card.Content>
          <Card.Header>{report.title}</Card.Header>
          <Card.Meta>{report.subtitle}</Card.Meta>
          {report.requests && (
            <Card.Description>
              <h4 style={{ fontWeight: 'lighter', marginBottom: '0.1em' }}>
                Requests
              </h4>
              <StackedChart
                displayLabel
                closed={parseFloat(report.requests.closed)}
                open={parseFloat(report.requests.open)}
                critical={parseFloat(report.requests.critical)}
                none={this.state.maxRequests - requestCount}
              />
            </Card.Description>
          )}
        </Card.Content>
        <Card.Content extra>
          <Button
            primary
            floated="right"
            content="View"
            icon="chevron right"
            labelPosition="right"
            onClick={() => this.navigateToReport(report)}
          />
        </Card.Content>
      </Card>
    );
  }

  renderList(report, index) {
    const requestCount =
      (report.requests &&
        parseFloat(report.requests.closed) +
          parseFloat(report.requests.open) +
          parseFloat(report.requests.critical)) ||
      0;
    const coverImg =
      (report.thumbnail &&
        `${FILE_API_URL}/${this.props.customer.directory}${
          report.thumbnail
        }?token=${this.props.token}`) ||
      placeholder;
    return (
      <List.Item key={index}>
        <List.Content floated="right">
          <Button
            primary
            content="View"
            icon="chevron right"
            labelPosition="right"
            onClick={() => this.navigateToReport(report)}
          />
        </List.Content>
        {report.requests && (
          <List.Content floated="right" style={{ minWidth: '30em' }}>
            <List.Description>
              <StackedChart
                displayLabel
                closed={parseFloat(report.requests.closed)}
                open={parseFloat(report.requests.open)}
                critical={parseFloat(report.requests.critical)}
                none={this.state.maxRequests - requestCount}
              />
            </List.Description>
          </List.Content>
        )}
        <Image src={coverImg} avatar />
        <List.Content>
          <List.Header>{report.title}</List.Header>
          <List.Description>{report.subtitle}</List.Description>
        </List.Content>
      </List.Item>
    );
  }

  render() {
    let data = this.props.fullscreen
      ? this.props.collection.list
      : [...this.props.collection.list]
          .sort((a, b) => {
            // in case report has a date, order by date instead
            if (a.date) {
              const momentA = moment(a.date);
              const momentB = moment(b.date);
              if (momentA.isBefore(momentB)) return 1;
              if (momentB.isBefore(momentA)) return -1;
              return 0;
            }
            const requestCountA =
              (a.requests &&
                parseFloat(a.requests.closed) +
                  parseFloat(a.requests.open) +
                  parseFloat(a.requests.critical)) ||
              0;

            const requestCountB =
              (b.requests &&
                parseFloat(b.requests.closed) +
                  parseFloat(b.requests.open) +
                  parseFloat(b.requests.critical)) ||
              0;
            if (requestCountA > requestCountB) return -1;
            if (requestCountA < requestCountB) return 1;
            return 0;
          })
          .slice(0, 5);
    if (this.props.search && this.props.search !== '') {
      data = this.props.collection.list.filter(
        d =>
          (d.title &&
            d.title.toLowerCase().includes(this.props.search.toLowerCase())) ||
          (d.subtitle &&
            d.subtitle.toLowerCase().includes(this.props.search.toLowerCase())),
      );
    }
    return (
      <div style={{ marginTop: '1em' }}>
        <Header style={{ fontWeight: 'lighter' }} as="h1">
          {this.props.collection.title}
          <Button
            primary
            style={{ marginLeft: '1em' }}
            onClick={() => {
              if (this.props.fullscreen) this.props.navigate('/reports');
              else this.props.navigate(`/reports/${this.props.type}`);
            }}
          >
            {this.props.fullscreen ? 'Back to summary' : 'All reports'}
          </Button>
        </Header>
        {(!this.props.listLayout || this.props.listLayout === 'grid') && (
          <Card.Group>{data.map(this.renderCard.bind(this))}</Card.Group>
        )}
        {this.props.listLayout === 'list' && (
          <List divided relaxed="very" verticalAlign="middle">
            {data.map(this.renderList.bind(this))}
          </List>
        )}
      </div>
    );
  }
}

ReportCollection.propTypes = {
  collection: PropTypes.object,
  type: PropTypes.string,
  customer: PropTypes.object,
  token: PropTypes.string,
  search: PropTypes.string,
  listLayout: PropTypes.string,
  navigate: PropTypes.func,
  fullscreen: PropTypes.bool,
};
