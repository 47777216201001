import React from 'react';
import moment from 'moment';
import {
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  VictoryLabel,
} from 'victory';

import {
  maxRowWidth,
  maxRowHeight,
  maxRowColumns,
} from 'app/report/reportUtils';

export default ({ loading, widget, data }) => {
  if (loading) return <div>Loading...</div>;
  if (!data) return <div>No data available</div>;
  const width = (widget.width * maxRowWidth) / maxRowColumns;
  const categories = [];
  let flatData = [];
  Object.keys(data).forEach(key => {
    categories.push(key);
    let formattedData;
    const { values, color } = data[key];
    switch (widget.options.scale) {
      case 'time':
        formattedData = values.map(record => {
          const date = moment(record.x);
          return {
            ...record,
            x: date,
            fill: color,
          };
        });
        break;

      default:
        formattedData = values.map(record => {
          return {
            ...record,
            fill: color,
          };
        });
        break;
    }
    flatData.push(...formattedData);
  });
  const maxCount = flatData.reduce((max, current) => {
    if (current.z > max) return current.z;
    return max;
  }, 0);
  flatData = flatData.map(record => ({
    ...record,
    z: (record.z / maxCount) * 3,
    fullValue: record.z,
  }));
  return (
    <div>
      <VictoryChart
        domainPadding={50}
        width={width}
        padding={{ top: 50, bottom: 50, left: 100, right: 50 }}
        height={categories.length * maxRowHeight}
        scale={{ x: widget.options.scale || 'linear', y: 'linear' }}
      >
        <VictoryScatter
          style={{
            data: {
              fill: datum => {
                return datum.fill;
              },
            },
            labels: { fill: 'white', fontSize: 18 },
          }}
          labels={datum => datum.fullValue}
          labelComponent={<VictoryLabel dy={18} />}
          categories={{ y: categories }}
          data={flatData}
        />
        <VictoryAxis
          crossAxis
          tickFormat={t => {
            if (typeof t === 'string') return t;
            return moment(t).format(widget.options.timeFormat || 'DD/MM/YYYY');
          }}
        />
        <VictoryAxis crossAxis dependentAxis />
      </VictoryChart>
    </div>
  );
};
