import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import connect from 'lib/reduxConnect';
import produce from 'immer';
import { Header, Segment, Loader, Modal, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertIcon, getColorFromStatus } from 'app/explorer/utils';

import { FILE_API_URL } from 'config/network';

import Overview from '../components/overview';
import Attachment from '../components/attachment';
import History from '../components/history';
import FormModal from '../components/formModal';

class Detail extends Component {
  state = {
    pdfOpen: false,
    pdfBlobUrl: null,
    loading: false,
    tab: 'request',
    formOpen: false,
    selectedElement: null,
    noPDF: false,
    permissions: [],
  };

  async componentDidMount() {
    await this.props.actions.getRequestDetail(
      this.props.match.params.requestId,
    );
    await this.getPDFData();
  }

  getPDFSrc = () =>
    `${FILE_API_URL}${this.props.customer.directory}figurepdf/${
      this.props.data.thumbnail
    }?token=${this.props.token}`;

  getPhotoSrc = photofilename =>
    `${FILE_API_URL}${
      this.props.customer.directory
    }photos/${photofilename}?token=${this.props.token}`;

  getPDFData = async () => {
    try {
      const pdfSrc = this.getPDFSrc();
      const pdfData = await fetch(pdfSrc);
      const pdfFileRaw = await pdfData.blob();
      const pdfFile = new Blob([pdfFileRaw], { type: 'application/pdf' });
      const pdfBlobUrl = window.URL.createObjectURL(pdfFile);
      this.setState(
        produce(draft => {
          draft.pdfBlobUrl = pdfBlobUrl;
          draft.noPDF = false;
        }),
      );
    } catch (err) {
      this.setState(
        produce(draft => {
          draft.noPDF = true;
        }),
      );
    }
  };

  downloadPDF = () => {
    const link = document.createElement('a');
    link.href = this.state.pdfBlobUrl;
    link.download = 'figure.pdf';
    link.click();
  };

  handleOpen = field => (selectedElement = null) =>
    this.setState(
      produce(draft => {
        draft[field] = true;
        draft.selectedElement = selectedElement;
      }),
    );

  handleClose = field => () =>
    this.setState(
      produce(draft => {
        draft[field] = false;
        draft.selectedElement = null;
      }),
    );

  viewRequest = async requestId => {
    await this.setState(
      produce(draft => {
        draft.loading = true;
      }),
    );
    await this.props.actions.viewRequest(requestId);
    this.props.scrollToTop();
    await this.setState(
      produce(draft => {
        draft.loading = false;
      }),
    );
  };

  changeTab = tab => {
    this.setState(
      produce(draft => {
        draft.tab = tab;
      }),
    );
  };

  renderAttachment(attachment, index) {
    return (
      <Attachment
        key={index}
        requestId={this.props.requestId}
        attachment={attachment}
        getPhotoSrc={this.getPhotoSrc}
        modifyRequests={this.props.actions.modifyRequests}
        viewRequest={this.viewRequest}
        token={this.props.token}
        handleOpen={this.handleOpen('formOpen')}
      />
    );
  }

  renderPdfModal() {
    return (
      <Modal
        open={this.state.pdfOpen}
        onClose={this.handleClose('pdfOpen')}
        closeIcon
        style={{ height: '90%' }}
      >
        <Header
          icon="file pdf"
          content={`Figure ${this.props.data.headerLeft.figure}`}
        />
        <Modal.Content scrolling image className="fullHeight">
          {this.state.pdfBlobUrl ? (
            <iframe
              title="pdf"
              width="100%"
              height="100%"
              src={this.state.pdfBlobUrl}
            />
          ) : (
            <Loader active size="massive" content="Loading PDF..." />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.downloadPDF}
            inverted
            icon="download"
            content="Download PDF"
          />
          <Button color="red" onClick={this.handleClose('pdfOpen')} inverted>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderFormModal() {
    if (!this.state.selectedElement || !this.state.selectedElement.report)
      return null;
    return (
      <FormModal
        selectedElement={this.state.selectedElement}
        formOpen={this.state.formOpen}
        handleClose={this.handleClose('formOpen')}
        getPhotoSrc={this.getPhotoSrc}
      />
    );
  }

  render() {
    if (!this.props.data || this.state.loading)
      return <Loader active size="massive" content="Loading details..." />;
    if (this.props.data === 'error' || this.props.data.message)
      return <Redirect to="/forbidden" />;
    let content;
    switch (this.state.tab) {
      case 'history':
        content = <History history={this.props.data.history} />;
        break;

      default:
        content = this.props.data.attachments.map(
          this.renderAttachment.bind(this),
        );
        break;
    }
    return (
      <Segment.Group raised>
        <Segment inverted color={getColorFromStatus(this.props.data.status)}>
          <Header>
            <FontAwesomeIcon
              icon={convertIcon(this.props.data.icon.replace('icon-', ''))}
            />
            {this.props.data.title}
          </Header>
        </Segment>
        <Overview
          requestId={this.props.requestId}
          data={this.props.data}
          getPDFSrc={this.getPDFSrc}
          modifyRequests={this.props.actions.modifyRequests}
          handleOpenPDFModal={this.handleOpen('pdfOpen')}
          noPDF={this.state.noPDF}
          permissions={this.props.permissions}
        />
        <Segment secondary>
          <Button.Group>
            <Button
              onClick={() => this.changeTab('request')}
              active={this.state.tab === 'request'}
            >
              Request
            </Button>
            <Button
              onClick={() => this.changeTab('history')}
              active={this.state.tab === 'history'}
            >
              History
            </Button>
          </Button.Group>
        </Segment>
        {content}
        {this.renderPdfModal()}
        {this.renderFormModal()}
      </Segment.Group>
    );
  }
}

Detail.propTypes = {
  requestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customer: PropTypes.object,
  scrollToTop: PropTypes.func,
  permissions: PropTypes.array,
};

const mapStateToProps = state => ({
  requestId: state.request.id,
  data: state.request.data,
  customer: state.auth.customer,
  permissions: state.auth.profile.permissions,
});

export default connect(mapStateToProps)(Detail);
