import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal, Button, Input, Dropdown } from 'semantic-ui-react';
import produce from 'immer';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

import {
  getMostRecentReading,
  getTEIList,
  isDateTEI,
} from 'app/rfid/rfidUtils';
import YearMonthForm, { fromMonth, toMonth } from 'app/util/yearMonthForm';

export default class TEIEditModal extends Component {
  state = {
    TEI: {},
    TEIError: {},
    selectedTEI: null,
    moment: fromMonth,
  };

  componentDidUpdate(prevProps) {
    if (this.props.tag && prevProps.tag !== this.props.tag) {
      const data = getMostRecentReading(this.props.tag.readings);
      if (data) {
        const reading = JSON.parse(data.reading);
        this.setState(
          produce(draft => {
            draft.TEI = reading.records;
            draft.TEIError = reading.records;
          }),
        );
      }
    }
  }
  selectTEI = (e, { value }) => {
    this.setState(
      produce(draft => {
        draft.selectedTEI = value;
      }),
    );
  };
  addTEI = (category, tei) => {
    this.setState(
      produce(draft => {
        draft.TEI[category][tei] = '';
        draft.selectedTEI = null;
      }),
    );
  };

  editTEI = (category, tei) => (e, { value }) => {
    this.setState(
      produce(draft => {
        draft.TEI[category][tei] = value;
      }),
    );
  };

  editDateTEI = (category, tei) => value => {
    this.setState(
      produce(draft => {
        draft.TEI[category][tei] = value;
        draft.TEIError[category][tei] = value !== undefined;
      }),
    );
  };

  handleYearMonthChange(month) {
    this.setState(
      produce(draft => {
        draft.month = month;
      }),
    );
  }

  onSave = () => {
    const formattedRecords = {};

    let pno = undefined;
    let exp = undefined;
    let ser = undefined;

    Object.keys(this.state.TEI).map(category => {
      formattedRecords[category] = {};
      const values = this.state.TEI[category];
      Object.keys(values).map(tei => {
        let value = values[tei];
        if (isDateTEI(tei)) value = moment(value).format('YYYYMMDD');
        formattedRecords[category][tei] = value;
        if (!pno && tei === 'PNO') pno = value;
        if (!exp && tei === 'EXP') exp = value;
        if (!ser && tei === 'SER') ser = value;
      });
    });
    this.props.onSave(formattedRecords, pno || '', exp || '', ser || '');
  };

  renderTEI = category => (TEI, index) => {
    const value = this.state.TEI[category][TEI];
    const isValidValue = this.state.TEIError[category][TEI];
    if (isDateTEI(TEI)) {
      return (
        <div style={{ margin: '1em' }} key={index}>
          <DayPickerInput
            classNames={{
              container: 'rfidDatePickerInput',
              overlayWrapper: 'DayPickerInput-OverlayWrapper',
              overlay: 'DayPickerInput-Overlay',
            }}
            value={value}
            onDayChange={this.editDateTEI(category, TEI)}
            format="YYYY-MM-DD"
            placeholder="YYYY-MM-DD"
            formatDate={formatDate}
            parseDate={parseDate}
            component={props => (
              <Input fluid error={!isValidValue} label={TEI} {...props} />
            )}
            dayPickerProps={{
              month: this.state.month,
              fromMonth,
              toMonth,
              captionElement: ({ date, localeUtils }) => (
                <YearMonthForm
                  date={date}
                  localeUtils={localeUtils}
                  onChange={this.handleYearMonthChange.bind(this)}
                />
              ),
            }}
          />
        </div>
      );
    }
    return (
      <Input
        fluid
        key={index}
        label={TEI}
        style={{ margin: '1em' }}
        value={value}
        onChange={this.editTEI(category, TEI)}
      />
    );
  };

  renderCategory(category, index) {
    const teis = this.state.TEI[category];
    return (
      <div key={index}>
        <Header>{category}</Header>
        <div>{Object.keys(teis).map(this.renderTEI(category).bind(this))}</div>
        <div style={{ display: 'flex' }}>
          <Dropdown
            fluid
            selection
            placeholder="Available TEI..."
            options={getTEIList(Object.keys(teis))}
            value={this.state.selectedTEI}
            onChange={this.selectTEI}
          />
          <Button
            fluid
            onClick={() => this.addTEI(category, this.state.selectedTEI)}
          >
            Add TEI
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        open={this.props.tag !== null && this.props.tag !== undefined}
        closeIcon
        onClose={this.props.onClose}
        size="small"
      >
        <Modal.Header>TEI Edition</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {Object.keys(this.state.TEI).map(this.renderCategory.bind(this))}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose} negative>
            Cancel
          </Button>
          <Button
            onClick={() => this.onSave()}
            positive
            labelPosition="right"
            icon="save"
            content="Save"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

TEIEditModal.propTypes = {
  tag: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
