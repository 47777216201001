/* eslint-disable */
import React, { useState } from 'react';
import { Progress } from 'semantic-ui-react';

export const RequestsBySubAssemblies = ({ dataExtraKpi }) => {
  const [data] = useState(dataExtraKpi);
  return (
    data && (
      <div className="kpiContent">
        <div class="card">
          <div class="content">
            <div class="header">
              <h4>{data.extra.title}</h4>
            </div>
            <div class="meta">{data.extra.subtitle}</div>
            <div class="ui divider" />
            <div class="description" style={{ marginTop: '10px' }}>
              <div class="ui statistics" style={{ justifyContent: 'center' }}>
                <div class="statistic">
                  <div class="value">
                    <i class="sitemap icon" /> {data.nbSubAssemblies}
                  </div>
                  <div class="label">Sub Assemblies</div>
                </div>
                <div class="statistic">
                  <div class="value">
                    <i class="table icon" /> {data.totalRequest}
                  </div>
                  <div class="label">Requests</div>
                </div>
                <div class="statistic red">
                  <div class="value">
                    <i class="table icon" /> {data.totalRequestCritical}
                  </div>
                  <div class="label">Criticals requests</div>
                </div>
              </div>
              <div class="ui divider" style={{ marginBottom: '30px' }} />
              <Progress
                percent={data.percentRequest}
                color={data.percentRequestColor}
                label={
                  data.percentRequest + '% of sub assemblies have no request'
                }
              />
              <Progress
                percent={data.percentCriticalRequest}
                color={data.percentCriticalRequestColor}
                label={
                  data.percentCriticalRequest +
                  '% of sub assemblies have no critical request'
                }
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};
