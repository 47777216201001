import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Image, Menu, Grid, Dimmer, Button } from 'semantic-ui-react';
import produce from 'immer';

import { FILE_API_URL } from 'config/network';
import connect from 'lib/reduxConnect';

import Text from 'app/report/components/widgets/text';
import Gallery from 'app/report/components/widgets/photoGallery';
import PieChart from 'app/report/components/widgets/piechart';
import AxisChart from 'app/report/components/widgets/axischart';
import List from 'app/report/components/widgets/list';
import ScatterChart from 'app/report/components/widgets/scatterchart';

// totaly not custom widgets
import RFIDExpiry from 'app/report/components/widgets/rfidexpiry';

import placeholder from 'assets/logo-placeholder.png';

class ReportRenderer extends Component {
  state = {
    print: false,
  };

  componentDidMount() {
    this.props.actions.getReportData(
      this.props.match.params.reportId,
      this.props.match.params.type,
    );
  }

  exportReport = async () => {
    const layout = this.props.layouts[this.props.type];
    const { metadata } = layout;
    const { pdf } = metadata;

    if (pdf === 'print') {
      await this.setState(
        produce(draft => {
          draft.print = true;
        }),
      );
      window.print();
      await this.setState(
        produce(draft => {
          draft.print = false;
        }),
      );
    }
  };

  renderMenu() {
    const layout = this.props.layouts[this.props.type];
    const { metadata } = layout;
    const { title, navbar } = metadata;
    return (
      <Menu fixed="top">
        <Menu.Item href="#metadata" header key="title">
          {title}
        </Menu.Item>
        {navbar.map((item, index) => (
          <Menu.Item href={`#${item}`} key={index}>
            {item}
          </Menu.Item>
        ))}
      </Menu>
    );
  }

  renderMetadata() {
    const layout = this.props.layouts[this.props.type];
    const { metadata } = layout;
    const { background, pdf } = metadata;
    const backgroundImg = `${FILE_API_URL}/${
      this.props.customer.directory
    }icons/report/${background}?token=${this.props.token}`;
    const data =
      (this.props.reportData && this.props.reportData.metadata) || null;
    const title = (data && data[0]) || 'Loading...';
    const loading = !(data && data[0]);
    let coverImg;
    if (data && data[1]) {
      if (data[1].base)
        coverImg = `${FILE_API_URL}/${this.props.customer.directory}${
          data[1].path
        }?token=${this.props.token}`;
      else
        coverImg = `${FILE_API_URL}/${
          this.props.customer.directory
        }photos/${data[1].replace('.jpg', '_tbl.jpg')}?token=${
          this.props.token
        }`;
    } else coverImg = placeholder;
    return (
      <div
        id="metadata"
        style={{
          background: this.state.print
            ? null
            : `url('${backgroundImg}') no-repeat center center fixed`,
          height: this.state.print ? '7em' : '24em',
          ...localStyles.metadataContainer,
        }}
      >
        <div
          style={{
            display: 'flex',
            ...localStyles.innerContainer,
          }}
        >
          <div
            style={{
              width: this.state.print ? '5em' : '20em',
              height: this.state.print ? '5em' : '20em',
              marginRight: '2em',
            }}
          >
            <Image rounded src={coverImg} />
          </div>
          <Header
            as="h1"
            inverted={!this.state.print}
            style={{
              marginTop: 0,
              fontSize: this.state.print ? '1.5em' : '2.5em',
              ...localStyles.lightText,
            }}
          >
            {title}
          </Header>
          {!this.state.print && (
            <div style={localStyles.metadataActions}>
              <Button
                icon="save"
                disabled={loading}
                content={pdf === 'print' ? 'Print' : 'Download PDF'}
                onClick={this.exportReport}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderWidget = (section, rowIndex) => (widget, index) => {
    let widgetComponent = null;
    const layout = this.props.layouts[this.props.type];
    const rows = layout.sections[section].rows;
    const dataIndex =
      rows.reduce(
        (acc, r, rindex) => (rindex < rowIndex ? acc + r.length : acc),
        0,
      ) + index;
    const loaded =
      (this.props.reportData &&
        this.props.reportData[section] &&
        this.props.reportData[section].length > dataIndex) ||
      false;
    const data = loaded ? this.props.reportData[section][dataIndex] : {};
    switch (widget.type) {
      case 'shorttext':
      case 'text':
        widgetComponent = (
          <Text
            loading={!loaded}
            icon={widget.options.icon}
            title={data.title}
            subtitle={data.subtitle}
          />
        );
        break;
      case 'gallery':
        widgetComponent = (
          <Gallery
            loading={!loaded}
            count={widget.options.count}
            data={data}
            token={this.props.token}
            customer={this.props.customer}
          />
        );
        break;
      case 'piechart':
        widgetComponent = (
          <PieChart loading={!loaded} widget={widget} data={data} />
        );
        break;
      case 'axischart':
        widgetComponent = (
          <AxisChart loading={!loaded} widget={widget} data={data} />
        );
        break;
      case 'list':
        widgetComponent = (
          <List
            loading={!loaded}
            widget={widget}
            data={data}
            reportId={this.props.reportId}
            layout={layout}
            getReportSectionData={this.props.actions.getReportSectionData}
            print={this.state.print}
          />
        );
        break;
      case 'scatterchart':
        widgetComponent = (
          <ScatterChart loading={!loaded} data={data} widget={widget} />
        );
        break;
      case 'rfidexpiry':
        widgetComponent = (
          <RFIDExpiry loading={!loaded} data={data} print={this.state.print} />
        );
        break;

      default:
        widgetComponent = null;
        break;
    }
    return (
      <Grid.Column verticalAlign="middle" key={index} width={widget.width}>
        {widget.title && (
          <Header as="h3" style={localStyles.lightText}>
            {widget.title}
          </Header>
        )}
        {widgetComponent}
      </Grid.Column>
    );
  };

  renderRow = section => (row, index) => {
    return (
      <Grid key={index}>{row.map(this.renderWidget(section, index))}</Grid>
    );
  };

  renderSection(sectionName, index) {
    const layout = this.props.layouts[this.props.type];
    const section = layout.sections[sectionName];
    const { order, rows } = section;
    return (
      <div
        key={index}
        style={{
          order,
          paddingTop: '2em',
          paddingBottom: '2em',
          ...localStyles.innerContainer,
        }}
      >
        <Header as="h2" style={localStyles.lightText} id={sectionName}>
          {sectionName}
        </Header>
        {rows.map(this.renderRow(sectionName))}
      </div>
    );
  }

  render() {
    if (
      this.props.type &&
      this.props.layouts &&
      this.props.layouts[this.props.type] === false
    )
      return <div>There was an error loading this report</div>;
    if (
      !this.props.type ||
      !this.props.layouts ||
      !this.props.layouts[this.props.type]
    )
      return <div>Loading...</div>;
    const layout = this.props.layouts[this.props.type];
    if (layout.metadata.pdf !== 'print') {
      if (!this.props.reportData || !this.props.reportData.pdf)
        return <div>Loading...</div>;
      return (
        <iframe
          title="pdf"
          width="100%"
          height="100%"
          src={this.props.reportData.pdf}
        />
      );
    }
    return (
      <div style={localStyles.container}>
        <Dimmer.Dimmable dimmed={this.props.busy !== false}>
          {!this.state.print && this.renderMenu()}
          <div
            style={{
              display: 'flex',
              order: -1,
            }}
          >
            {this.renderMetadata()}
          </div>
          {Object.keys(layout.sections).map(this.renderSection.bind(this))}
          <Dimmer active={this.props.busy !== false}>
            <Header as="h2" inverted>
              {this.props.busy}
            </Header>
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
    );
  }
}

ReportRenderer.propTypes = {
  layouts: PropTypes.object,
  customer: PropTypes.object,
  type: PropTypes.string,
  reportData: PropTypes.object,
  reportId: PropTypes.string,
  busy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const mapStateToProps = state => ({
  layouts: state.report.layouts,
  customer: state.auth.customer,
  type: state.report.type,
  reportData: state.report.data,
  reportId: state.report.reportId,
  busy: state.report.busy,
});

export default connect(mapStateToProps)(ReportRenderer);

const localStyles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2.5em',
  },
  innerContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: 960,
    margin: '0 auto',
  },
  metadataContainer: {
    backgroundSize: 'cover',
    flex: 1,
    padding: '2em',
    color: 'white',
  },
  metadataActions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  lightText: {
    fontWeight: 'lighter',
  },
};
