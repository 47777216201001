import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SizeMe } from 'react-sizeme';

import connect from 'lib/reduxConnect';
import Table from 'app/explorer/components/table';

class StandaloneTable extends Component {
  render() {
    return (
      <div className="explorer">
        <SizeMe monitorHeight>
          {({ size }) => (
            <div style={{ flex: 1 }}>
              <Table
                token={this.props.token}
                modifiedRequestIds={[]}
                modifyRequests={this.props.actions.modifyRequests}
                viewRequest={this.props.actions.viewRequest}
                requestIds={this.props.requestIds}
                height={size.height}
                dirty
                markDirty={() => null}
                setCache={() => null}
              />
            </div>
          )}
        </SizeMe>
      </div>
    );
  }
}

StandaloneTable.propTypes = {
  requestIds: PropTypes.array,
};

const mapStateToProps = state => ({
  requestIds: state.explorer.standaloneRequestIds,
});

export default connect(mapStateToProps)(StandaloneTable);
