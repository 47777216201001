import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  VictoryBar,
  VictoryLine,
  VictoryGroup,
  VictoryLegend,
  VictoryScatter,
} from 'victory';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';
import Chart, { makeVictoryStyle, Tooltip } from 'app/home/components/chart';
import { defaultChartHeight } from 'app/home/homeConstants';

export const getVariation = (data, inverted, unit) => {
  if (data.length < 2)
    return {
      value: 0,
      diff: 0,
      inverted: false,
    };
  const currentValue = data[data.length - 1].y;
  const previousValue = data[data.length - 2].y;
  return {
    value: currentValue,
    hardDiff: currentValue - previousValue,
    diff:
      previousValue === 0
        ? currentValue === 0
          ? 'down'
          : 'up'
        : Math.round(((currentValue - previousValue) / previousValue) * 100),
    inverted,
    unit,
  };
};

export default class TimedKPI extends PureComponent {
  handleAmountChange = dataAmount => {
    this.props.saveKPI(this.props.kpi.i, { dataAmount });
  };

  updateKPI = diff => {
    if (!diff || diff === null) return;
    const interval = { ...this.props.kpi.payload.interval, ...diff };
    this.props.saveKPI(this.props.kpi.i, { interval });
  };

  onSettingsClose = async () => {
    await this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
    this.forceUpdate();
  };

  getHeader() {
    return (
      <KPI.Header title={this.props.title} onClose={this.onSettingsClose}>
        {this.props.extraSettings}
      </KPI.Header>
    );
  }

  buildBars() {
    return this.props.series
      .filter(s => s.type === 'bar')
      .map(s => (
        <VictoryBar
          key={s.name}
          data={s.data}
          style={makeStyle(s.styles.data, s.styles.labels)}
          labels={d => d.y}
        />
      ));
  }

  buildLines() {
    return this.props.series
      .filter(s => s.type === 'line')
      .map(s => (
        <VictoryLine
          key={s.name}
          data={s.data}
          interpolation="catmullRom"
          style={makeStyle(s.styles.data, s.styles.labels)}
          labels={d => d.y}
          labelComponent={<Tooltip flyoutStyle={style.tooltip} />}
        />
      ));
  }

  buildPoints() {
    return this.props.series
      .filter(s => s.type === 'line')
      .map(s => (
        <VictoryScatter
          key={`${s.name}-scatter`}
          data={s.data}
          style={{ data: s.styles.labels }}
          size={5}
        />
      ));
  }

  render() {
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.props.loading}
        error={this.props.error}
        getData={this.props.getData}
        noData={this.props.series.every(serie => serie.data.length === 0)}
        deleteKPI={this.props.deleteKPI}
      >
        {this.getHeader()}
        <KPI.Variation
          value={this.props.variation.value}
          diff={this.props.variation.diff}
          inverted={this.props.variation.inverted}
          unit={this.props.variation.unit}
          hardDiff={this.props.variation.hardDiff}
        />
        <KPI.Data>
          {this.props.svgDefs}
          <Chart
            heightMultiplier={this.props.kpi.h}
            scale={{ x: 'time', y: 'linear' }}
            ticks={this.props.ticks}
            tickFormat={this.props.tickFormat}
            padding={this.props.padding}
          >
            <VictoryLegend
              y={defaultChartHeight / 2 + 100}
              orientation="horizontal"
              style={style.legend}
              events={[
                {
                  target: 'labels',
                  eventHandlers: {
                    onClick: (e, d) => {
                      this.props.saveKPI(this.props.kpi.i, {
                        focus: d.datum.value || d.text.toLowerCase(),
                      });
                    },
                  },
                },
              ]}
              data={this.props.legend}
            />
            <VictoryGroup offset={style.group.data.width} style={style.group}>
              {this.buildBars()}
            </VictoryGroup>
            {this.buildLines()}
          </Chart>
        </KPI.Data>
      </KPI>
    );
  }
}

TimedKPI.propTypes = {
  ...KPIProps,
  title: PropTypes.string,
  getData: PropTypes.func,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      styles: PropTypes.shape({
        data: PropTypes.object,
        labels: PropTypes.object,
      }),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.any,
          y: PropTypes.any,
        }),
      ),
    }),
  ),
  ticks: PropTypes.array,
  tickFormat: PropTypes.func,
  variation: PropTypes.shape({
    value: PropTypes.number,
    diff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inverted: PropTypes.bool,
    unit: PropTypes.string,
  }),
  legend: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      reload: PropTypes.bool,
    }),
  ]),
  extraSettings: PropTypes.node,
  svgDefs: PropTypes.node,
  padding: PropTypes.object,
};

const style = {
  legend: {
    labels: {
      fontSize: 14,
      fill: 'rgba(0,0,0,0.5)',
      fontFamily: 'Open Sans',
    },
  },
  group: {
    data: {
      width: 30,
      fillOpacity: 0.7,
    },
  },
  tooltip: {
    strokeWidth: 0,
    fill: 'white',
    filter: 'url(#dropshadow)',
  },
  label: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
};
const makeStyle = makeVictoryStyle({}, style.label);
