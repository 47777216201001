import React from 'react';
import { createSelector } from 'reselect';
import { Label } from 'semantic-ui-react';

import StackedChart from 'app/util/stackedChart';

const customFieldSelector = state => state.linkedData.customFields;
const itemsSelector = state => state.linkedData.items;
const showUnsetItemsSelector = state => state.linkedData.showUnsetItems;
const currentCustomFieldSelector = state =>
  state.linkedData.selectedCustomField;

export const formattedCustomFieldSelector = createSelector(
  customFieldSelector,
  customFields =>
    (customFields &&
      customFields.map((field, index) => ({
        index,
        title: field.name,
        extra: (
          <Label basic horizontal color={field.existing ? 'green' : 'orange'}>
            {field.existing}
          </Label>
        ),
      }))) ||
    [],
);

const maxRequestCountSelector = createSelector(
  itemsSelector,
  items => {
    if (!items || items.length === 0) return 0;
    return Math.max(...items.map(i => i.requestCount));
  },
);

export const formattedItemSelector = createSelector(
  itemsSelector,
  customFieldSelector,
  currentCustomFieldSelector,
  showUnsetItemsSelector,
  maxRequestCountSelector,
  (items, customFields, currentCF, showUnset, maxRequestCount) =>
    (items &&
      items
        .filter(item => {
          if (!showUnset) return true;
          return !item.values.existingValues.length;
        })
        .map((item, index) => {
          const itemCFSV = item.values;
          let actionValue = 0;
          const filteringValuesMulti = itemCFSV.filteringValues.multi[0];
          if (
            filteringValuesMulti !== undefined &&
            filteringValuesMulti.length > 0
          ) {
            const isActionPresent = itemCFSV.filteringValues.multi[0].find(
              elem => elem.id === item.Action,
            );
            if (isActionPresent && itemCFSV.existingValues !== undefined) {
              const keyValue = `${itemCFSV.filteringValues.base.join('_')}_${
                isActionPresent.value
              }`;
              if (
                itemCFSV !== undefined &&
                itemCFSV.existingValues !== undefined &&
                itemCFSV.existingValues.length > 0
              ) {
                const existingValue = itemCFSV.existingValues.find(
                  elem => elem.key === keyValue,
                );
                actionValue =
                  existingValue !== undefined ? existingValue.value : '';
              }
            }
          }
          let extra;
          if (customFields && currentCF != -1) {
            if (itemCFSV && itemCFSV.existingValues.length)
              extra = (
                <Label basic horizontal color="green">
                  Value : {actionValue}
                </Label>
              );
          }
          const currentRequestCount = item.closed + item.open + item.critical;
          return {
            index,
            title: item.title || item.descriptionShort,
            subtitle: item.subtitle || item.partNumber,
            extra: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {extra}
                <StackedChart
                  {...item}
                  style={{
                    width: '15em',
                    marginLeft: '1em',
                  }}
                  none={maxRequestCount - currentRequestCount}
                  displayLabel
                />
              </div>
            ),
          };
        })) ||
    [],
);
