import { createSelector } from 'reselect';
import moment from 'moment';

import { getMostRecentReading, rfidMoment, isTagWF } from './rfidUtils';

// TODO : when AF decides what shitshow we should do for expire delays
export const expiryLimit = { unit: 'day', amount: 90 };

const aircraftSelector = state => state.rfid.aircraft;
const selectedAircraftSelector = state => state.rfid.selectedAircraftId;
const tagsSelector = state => state.rfid.tags;
const sceneSelector = state => state.rfid.scene;
const selectedEquipmentSelector = state => state.rfid.selectedEquipmentId;
const selectedCategorySelector = state => state.rfid.category;
const tagFilterSelector = state => state.rfid.tagFilter;

const filterTags = (tags, filter) => {
  const now = moment();
  return tags.filter(tag => {
    const reading = getMostRecentReading(tag.readings);
    let date = moment().add(expiryLimit.amount, expiryLimit.unit);
    if (reading && reading.exp !== '') date = rfidMoment(reading.exp);
    switch (filter) {
      case 'editable':
        return isTagWF(tag);
      case 'nc':
        return tag.epc === 'NC';
      case 'willExp':
        return (
          !date.isBefore(now) &&
          date.subtract(expiryLimit.amount, expiryLimit.unit).isBefore(now)
        );
      case 'exp':
        return date.isBefore(now);

      default:
        return true;
    }
  });
};

export const formattedAircraftSelector = createSelector(
  aircraftSelector,
  tagsSelector,
  selectedAircraftSelector,
  selectedCategorySelector,
  selectedEquipmentSelector,
  tagFilterSelector,
  (
    aircraft,
    tags,
    aircraftId,
    selectedCategory,
    selectedEquipmentId,
    tagFilter,
  ) => {
    const formattedAircraft = aircraft.map(a => {
      let aircraftTags = tags[a.id].map(t => {
        const isWF = isTagWF(t);
        return { ...t, isWF };
      });
      if (tagFilter) {
        aircraftTags = filterTags(aircraftTags, tagFilter);
      }

      if (a.id === aircraftId && selectedCategory) {
        aircraftTags = aircraftTags.filter(t =>
          t.description.includes(selectedCategory),
        );
      }
      if (a.id === aircraftId && selectedEquipmentId !== -1) {
        aircraftTags = aircraftTags.filter(
          t => t.LopaEquipmentId === selectedEquipmentId,
        );
        aircraftTags.unshift({
          LopaEquipmentId: -1,
          all: true,
          description: 'All tags',
          readings: [],
        });
      }

      let unassignedCount = 0;
      let expiredCount = 0;
      let willExpireCount = 0;
      const now = moment();
      tags[a.id].map(t => {
        if (t.epc === 'NC') unassignedCount += 1;
        if (t.readings.length > 0) {
          const reading = getMostRecentReading(t.readings);
          const date = reading.exp !== '' ? rfidMoment(reading.exp) : null;
          if (!date || !date.isValid()) return;
          // a simple trick that your parents hate
          let isExpired = false;
          if (date.isBefore(now)) {
            expiredCount += 1;
            isExpired = true;
          }
          if (
            !isExpired &&
            date.subtract(expiryLimit.amount, expiryLimit.unit).isBefore(now)
          )
            willExpireCount += 1;
        }
      });

      return {
        ...a,
        routineCount: parseInt(a.routineCount, 10),
        closedCount: parseInt(a.closedCount, 10),
        unassignedCount,
        expiredCount,
        willExpireCount,
        tags: aircraftTags,
      };
    });
    return formattedAircraft;
  },
);

// J class equipment (business) has center row in two parts
// that are in the same lopa position due to 3d models
// being offset. This selector mitigates 2d render
const jClassOffset = 25;
export const fixedSceneSelector = createSelector(
  sceneSelector,
  formattedAircraftSelector,
  selectedAircraftSelector,
  selectedCategorySelector,
  (scene, aircraft, aircraftId, category) => {
    if (
      !scene ||
      scene.length === 0 ||
      aircraftId === -1 ||
      aircraft.length === 0
    )
      return [];
    const thisAircraft = aircraft.find(a => a.id === aircraftId);
    if (!thisAircraft) return [];
    return scene.map(item => {
      let itemTags = thisAircraft.tags.filter(
        t => t.LopaEquipmentId === item.id,
      );
      if (category) {
        itemTags = itemTags.filter(t => t.description.includes(category));
      }
      let noFilteredTags = false;
      if (itemTags.length === 0) noFilteredTags = true;
      let color;
      const now = moment();
      itemTags.forEach(t => {
        if (t.epc === 'NC') color = 'blue';
        if (t.readings.length > 0) {
          const reading = getMostRecentReading(t.readings);
          const date = reading.exp !== '' ? rfidMoment(reading.exp) : null;
          if (!date || !date.isValid()) return;
          if (date.subtract(expiryLimit.amount, expiryLimit.unit).isBefore(now))
            color = 'orange';
          if (date.isBefore(now)) color = 'red';
        }
      });
      if (item.class === 'J') {
        // yeah this is hardcoded, deal with it
        if (item.position.includes('D'))
          return { ...item, posZ: -jClassOffset, noFilteredTags, color };
        if (item.position.includes('G'))
          return { ...item, posZ: jClassOffset, noFilteredTags, color };
      }
      return { ...item, noFilteredTags, color };
    });
  },
);
