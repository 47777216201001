import React, { Component } from 'react';
import { Button, Card } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'lib/reduxConnect';

class Deployment extends Component {
  // note: profile features are required to display a button to the user
  // - deployment_prod
  // - deployment_rc
  // - deployment_staging
  // - deployment_training
  loadPage = url => {
    window.open(url, '_blank');
  };
  render() {
    const versions = this.props.profile.appVersions;
    return (
      <div className="secondaryContentMargin">
        <Card.Group centered>
          {Object.keys(versions).map(index => (
            <Card key={index}>
              <Card.Header
                textAlign="center"
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  paddingTop: 10,
                  wordWrap: 'break-word',
                }}
              >
                {index}
              </Card.Header>
              <Card.Meta style={{ marginTop: 5 }} textAlign="center">
                <b>{versions[index].version}</b>
                {` / `}
                {moment(versions[index].availabilityDate).format(
                  'Do MMMM YYYY',
                )}
              </Card.Meta>
              <Card.Content textAlign="center">
                <Button
                  content={`Install`}
                  basic
                  className="ui primary button"
                  onClick={() => this.loadPage(versions[index].bitriseLink)}
                />
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </div>
    );
  }
}

Deployment.propTypes = { profile: PropTypes.object };

const mapStateToProps = state => ({
  profile: state.auth.profile,
});

export default connect(
  mapStateToProps,
  null,
  true,
)(Deployment);
