import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Konva is used implicitely like React
// but eslint cares not for your concerns
// eslint-disable-next-line no-unused-vars
import Konva from 'konva';
import { Stage, Layer, Rect, Group, Text, Line, Circle } from 'react-konva';

import { groupBy } from 'app/explorer/utils';

const canvasHeight = 500;
const nudgeSize = 100;
const humanConsiderateTopCanvasMargin = 150;

const hasTagsColor = 'black';
const noTagsColor = '#e8e8e8';

// welcome to hardcodeland
// make yourself comfortable
// you're here forever
export default class AircraftCanvas extends Component {
  render() {
    const areasRaw = groupBy(this.props.scene, 'area');
    const areas = Object.keys(areasRaw).map(area => {
      const positions = areasRaw[area];
      let width = 0;
      let minLeft = 0;
      positions.forEach(pos => {
        if (pos.posX > width) width = pos.posX;
        if (pos.posZ < minLeft) minLeft = pos.posZ;
      });
      return {
        area,
        width: width + nudgeSize,
        minLeft,
        color: hasTagsColor,
        positions,
      };
    });
    const globalMinLeft =
      Math.min(...areas.map(a => a.minLeft)) - nudgeSize / 2;
    let currentGroupX = nudgeSize / 2;
    const totalWidth = areas.reduce((acc, area) => acc + area.width, nudgeSize);
    const totalHeight =
      areas.reduce((acc, area) => {
        if (Math.abs(area.minLeft) * 2 > acc) return Math.abs(area.minLeft) * 2;
        return acc;
      }, 0) + humanConsiderateTopCanvasMargin;
    const scale = canvasHeight / totalHeight;
    // All the preventDefault={false} props are here to avoid blocking the scroll on mobile devices
    // Because of the "drag and drop" feature of Konva, by default the touch events are handled in a custom way which breaks scrolling
    // See this issue for more details https://github.com/konvajs/konva/issues/260#issuecomment-526659484
    return (
      <div className="rfidCanvas" style={{ width: this.props.width }}>
        <Stage
          width={totalWidth * scale}
          height={canvasHeight}
          offsetY={globalMinLeft}
          scale={{ x: scale, y: scale }}
          preventDefault={false}
        >
          <Layer preventDefault={false}>
            <Rect
              y={globalMinLeft}
              width={totalWidth}
              height={totalHeight}
              onClick={() => this.props.selectEquipment(-1)}
              preventDefault={false}
            />
            {areas.map((area, areaIndex) => {
              const x = currentGroupX;
              currentGroupX += area.width;
              return (
                <Group key={areaIndex} x={x} preventDefault={false}>
                  {area.positions.map((item, itemIndex) => (
                    <Group
                      key={itemIndex}
                      x={item.posX}
                      y={item.posZ}
                      preventDefault={false}
                    >
                      {this.props.selectedEquipment === item.id && (
                        <Circle
                          stroke="red"
                          width={item.width * 2}
                          height={item.height * 2}
                          x={item.width / 2}
                          y={item.height / 2}
                        />
                      )}
                      <Rect
                        width={item.width}
                        height={item.height}
                        stroke={
                          item.noFilteredTags
                            ? noTagsColor
                            : item.color || area.color
                        }
                        cornerRadius={2}
                        shadowColor="blue"
                        shadowBlur={
                          this.props.selectedEquipment === item.id ? 10 : 0
                        }
                        onClick={e => this.props.selectEquipment(item.id)}
                        preventDefault={false}
                      />
                      <Text
                        y={-5}
                        text={item.position}
                        fill={
                          item.noFilteredTags
                            ? noTagsColor
                            : item.color || area.color
                        }
                        fontSize={10}
                        rotation={-90}
                        preventDefault={false}
                      />
                    </Group>
                  ))}
                  <Text
                    text={area.area}
                    y={-globalMinLeft + nudgeSize / 4}
                    fill={area.color}
                    fontSize={18}
                    preventDefault={false}
                  />
                  <Line
                    stroke="#d8d8d8"
                    points={[
                      area.width - nudgeSize / 2,
                      globalMinLeft,
                      area.width - nudgeSize / 2,
                      -globalMinLeft + nudgeSize / 2,
                    ]}
                    preventDefault={false}
                  />
                </Group>
              );
            })}
          </Layer>
        </Stage>
      </div>
    );
  }
}

AircraftCanvas.propTypes = {
  width: PropTypes.number,
  scene: PropTypes.array,
  selectEquipment: PropTypes.func,
  selectedEquipment: PropTypes.number,
};
