/* eslint-disable */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

let dataKpi = {
  chart1: {
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      fill: {
        colors: ['#119648', '#db1e1e'],
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        fillSeriesColor: false,
      },
      colors: ['#119648', '#db1e1e'],
      legend: {
        show: false,
      },
      labels: ['Requests corrected', 'Requests not corrected'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  },
  chart2: {
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      fill: {
        colors: ['#119648', '#db1e1e'],
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        fillSeriesColor: false,
      },
      colors: ['#119648', '#db1e1e'],
      legend: {
        show: false,
      },
      labels: [
        'Critical requests Corrected',
        'Critical requests not corrected',
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  },
};

export default class PercentRequestCorrected extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(dataKpi));
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    dataKpi.chart1.series = this.props.dataExtraKpi.chart1.series;
    dataKpi.chart2.series = this.props.dataExtraKpi.chart2.series;
    this.setState(dataKpi);
  };

  render() {
    return (
      <>
        <div className="kpiContent">
          <div class="card">
            <div class="content">
              <div class="header">
                <h4>{this.props.dataExtraKpi.extra.title}</h4>
              </div>
              <div class="meta">{this.props.dataExtraKpi.extra.subtitle}</div>
              <div class="description">
                <div class="ui two column grid">
                  <div
                    class="column"
                    style={{
                      borderRight: '2px solid #545252',
                      paddingTop: '42px',
                    }}
                  >
                    <Chart
                      options={this.state.chart1.options}
                      series={this.state.chart1.series}
                      type="pie"
                      height={200}
                      width={300}
                    />
                    <div class="header" style={{ textAlign: 'center' }}>
                      <h5>{this.props.dataExtraKpi.chart1.description}</h5>
                    </div>
                  </div>
                  <div class="column">
                    <div class="header" style={{ textAlign: 'center' }}>
                      <div class="ui red large label">
                        Only critical requests
                      </div>
                    </div>
                    <Chart
                      options={this.state.chart2.options}
                      series={this.state.chart2.series}
                      type="pie"
                      height={200}
                      width={300}
                    />
                    <div class="header" style={{ textAlign: 'center' }}>
                      <h5>{this.props.dataExtraKpi.chart2.description}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
