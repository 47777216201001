import { push, replace } from 'connected-react-router';

import * as types from 'config/types';
import * as network from 'config/network';
import { API } from 'lib/rest';

import { loadKPIs } from 'app/home/actions/kpi';

const loadUserProfile = (token, userdata, noNavigation) => async (
  dispatch,
  getState,
) => {
  const profile = await API.get(network.ENDPOINTS.auth.profile, token);
  if (profile.message || !profile.features) {
    throw new Error(profile.message);
  }
  dispatch({
    type: types.AUTH_LOGIN,
    payload: {
      user: {
        ...userdata,
        name: `${userdata.firstName} ${userdata.lastName}`,
      },
      token,
      profile,
    },
  });
  dispatch(getCustomer(token, noNavigation));
  dispatch(getMessages(token));
  if (!noNavigation) dispatch(push('/'));
  const currentPath = getState().router.location.pathname;
  if (currentPath === '/dashboard') {
    const kpis = getState().home.kpis;
    dispatch(loadKPIs(kpis));
  }
};

export const verifyCredentials = (
  login,
  password,
  noNavigation,
) => async dispatch => {
  const response = await API.post(network.ENDPOINTS.auth.online, false, {
    login,
    password,
  });
  if (response.message || !response.token) {
    throw new Error(response.message);
  }
  const userdata = response.users[0];
  // maybe keep it for later if we need offline caps
  delete userdata.password;
  await dispatch(loadUserProfile(response.token, userdata, noNavigation));
};

export const verifyToken = (jwt, noNavigation) => async dispatch => {
  const response = await API.get(network.ENDPOINTS.auth.userinfo, jwt, {
    full: true,
  });
  if (response.message) {
    throw new Error(response.message);
  }
  const userdata = response;
  // maybe keep it for later if we need offline caps
  delete userdata.password;
  await dispatch(loadUserProfile(jwt, userdata, noNavigation));
};

export const logout = () => ({
  type: types.AUTH_LOGOUT,
});

export const navigateToLogin = () => dispatch => {
  dispatch(push('/auth/login'));
};

export const getCustomer = (token, noNavigation) => async (
  dispatch,
  getState,
) => {
  try {
    const customer = await API.get(
      network.ENDPOINTS.webreporting.customer,
      token,
    );
    if (customer && customer.directory) {
      const lastCustomer = getState().auth.customer;
      if (lastCustomer && lastCustomer.id !== customer.id) {
        dispatch({
          type: types.AUTH_RESET,
        });
        // in case of reconnect action, force go to
        // dashboard to prevent data problems
        if (noNavigation) {
          dispatch(replace('/'));
        }
      }
      dispatch({
        type: types.AUTH_CUSTOMER,
        payload: { customer },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateUserInformations = ({
  email,
  firstname,
  lastname,
  phone,
  mobile,
  gender,
  title,
  station,
}) => async (dispatch, getState) => {
  try {
    const response = await API.post(
      network.ENDPOINTS.webreporting.useredit,
      getState().auth.token,
      {
        email,
        firstname,
        lastname,
        phone,
        mobile,
        gender,
        title,
        station,
      },
    );
    dispatch({
      type: types.AUTH_UPDATE_USER,
      payload: {
        user: {
          ...getState().auth.user,
          ...response.data.output,
          name: `${response.data.output.firstName} ${
            response.data.output.lastName
          }`,
        },
      },
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getMessages = token => async (dispatch, getState) => {
  try {
    const messages = await API.get(
      network.ENDPOINTS.webreporting.notification,
      token || getState().auth.token,
    );
    if (messages) {
      dispatch({
        type: types.AUTH_MESSAGES,
        payload: { messages },
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const updatePassword = ({
  actualPassword,
  newPassword,
  newPasswordConfirm,
}) => async (dispatch, getState) => {
  try {
    const errors = await validatePasswordModification(
      actualPassword,
      newPassword,
      newPasswordConfirm,
    );
    if (Object.keys(errors).length) {
      return {
        errors,
      };
    }
    // API
    const login = getState().auth.user.login;
    const response = await API.post(
      network.ENDPOINTS.webreporting.updatePassword,
      getState().auth.token,
      {
        actualPassword,
        newPassword,
        newPasswordConfirm,
        login,
      },
    );
    if (response.errors) {
      return {
        errors: response.errors,
      };
    }
    return {
      success: response.success,
    };
  } catch (err) {
    return {
      errors: err.message,
    };
  }
};

const validatePasswordModification = (
  actualPassword,
  newPassword,
  newPasswordConfirm,
) => {
  let errors = {};
  // Actual Password
  if (!actualPassword || actualPassword === '') {
    errors.actualPassword = `Actual password is empty.`;
  }
  // New Password
  if (!newPassword || newPassword === '') {
    errors.newPassword = `New password is empty.`;
  }
  // New password confirmation
  if (!newPasswordConfirm || newPasswordConfirm === '') {
    errors.newPasswordConfirm = `New password confirmation is empty.`;
  } else if (
    newPassword &&
    newPasswordConfirm &&
    newPassword !== newPasswordConfirm
  ) {
    errors.newPasswordConfirm = `New password is different from New password confirmation.`;
  }
  return errors;
};

export const readMessage = index => async (dispatch, getState) => {
  try {
    const token = getState().auth.token;
    const { id } = getState().auth.messages[index];
    const update = await API.post(
      network.ENDPOINTS.webreporting.notification,
      token,
      { id },
    );
    if (update && update.success) {
      dispatch({
        type: types.AUTH_READ_MESSAGE,
        payload: { index },
      });
    }
  } catch (err) {
    console.error(err);
  }
};
