import React from 'react';
import { List, Grid, Button } from 'semantic-ui-react';
import XLSX from 'xlsx';

import StackedChart from 'app/util/stackedChart';

const getColumnLayout = (
  record,
  column,
  reportId,
  layout,
  maxStackedChartTotal,
  getReportSectionData,
  print,
) => {
  switch (column.type) {
    case 'progress': {
      const total =
        parseFloat(record[column.field].closedCount) +
        parseFloat(record[column.field].routineCount) +
        parseFloat(record[column.field].criticalCount);
      return (
        <StackedChart
          displayLabel
          style={{ marginBottom: 0 }}
          closed={record[column.field].closedCount}
          open={record[column.field].routineCount}
          critical={record[column.field].criticalCount}
          none={maxStackedChartTotal - total}
        />
      );
    }
    case 'xlsexport':
      if (print) return null;
      return (
        <Button
          fluid
          primary
          icon="save"
          content={column.text}
          onClick={async () => {
            const data = await getReportSectionData(
              { requests: true },
              reportId,
              layout.metadata.dataAccessor,
              [{ accessor: column.dataAccessor }],
              record[column.field],
            );
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Requests');
            XLSX.writeFile(workbook, 'requests.xlsx');
          }}
        />
      );

    default: {
      const color = record[column.field].color || 'inherit';
      return (
        <List.Content
          style={{
            fontSize: record.header ? '1.5em' : '1em',
            marginBottom: record.header ? '0.5em' : 0,
          }}
        >
          <List.Header
            style={{
              fontWeight: record.header ? 'lighter' : 'inherit',
              color,
            }}
          >
            {record[column.field].title}
          </List.Header>
          <List.Description>{record[column.field].subtitle}</List.Description>
        </List.Content>
      );
    }
  }
};

export default ({
  loading,
  widget,
  data,
  reportId,
  layout,
  getReportSectionData,
  print = false,
}) => {
  if (loading) return <div>Loading...</div>;
  if (!data) return <div>No data available</div>;
  let setWidthColumnCount = 0;
  const divisibleGridColumns =
    12 -
    widget.options.row.reduce((acc, column) => {
      if (column.width) setWidthColumnCount += 1;
      return acc + (column.width || 0);
    }, 0);
  // let's assume we'll only have one stacked chart per row
  // because complexity is getting stupid around here
  const stackedChartColumn = widget.options.row.find(
    column => column.type === 'progress',
  );
  let stackedChartField = stackedChartColumn ? stackedChartColumn.field : null;
  const defaultColumnWidth = Math.round(
    divisibleGridColumns / (widget.options.row.length - setWidthColumnCount),
  );
  let maxStackedChartTotal = 0;
  if (stackedChartField) {
    maxStackedChartTotal = data.reduce((maxTotal, record) => {
      const total =
        parseFloat(record[stackedChartField].closedCount) +
        parseFloat(record[stackedChartField].routineCount) +
        parseFloat(record[stackedChartField].criticalCount);
      if (total > maxTotal) return total;
      return maxTotal;
    }, 0);
  }

  const items = data.map((record, index) => {
    return (
      <List.Item key={index}>
        <List.Content>
          <Grid>
            {widget.options.row.map((column, cindex) => {
              const rowLayout = getColumnLayout(
                record,
                column,
                reportId,
                layout,
                maxStackedChartTotal,
                getReportSectionData,
                print,
              );
              return (
                <Grid.Column
                  verticalAlign="middle"
                  width={column.width || defaultColumnWidth}
                  key={cindex}
                >
                  {rowLayout}
                </Grid.Column>
              );
            })}
          </Grid>
        </List.Content>
      </List.Item>
    );
  });
  return <List>{items}</List>;
};
