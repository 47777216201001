import React, { PureComponent } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Radio, Header, Form, Dropdown } from 'semantic-ui-react';

import { KPIProps } from 'app/home/components/types';
import { svgDefs, linearGradient, dropShadow } from 'lib/svg';

import TimedKPI, { getVariation } from './timedKPI';
import { formatStepLabel } from 'app/util/datepicker';

const moment = extendMoment(Moment);

const getDataArray = (values, focus) => {
  let dataArray;
  switch (focus) {
    case '5-15 days':
      dataArray = values[1];
      break;
    case '16-30 days':
      dataArray = values[2];
      break;
    case '> 30 days':
      dataArray = values[3];
      break;

    default:
      dataArray = values[0];
      break;
  }
  return dataArray;
};

export default class TATByTimeKPI extends PureComponent {
  state = {
    series: [],
    ticks: [],
    tickFormat: 'HH:00',
    loading: true,
    error: false,
    focus: '< 5 days',
    variation: {
      value: 0,
      diff: 0,
      inverted: false,
      unit: 'defects',
    },
  };

  // called on init and updates, prevents desynced focus props
  static getDerivedStateFromProps(props, state) {
    if (props.data && state.focus !== props.kpi.payload.focus) {
      const { specific1, specific2, specific3, specific4 } = props.data;
      if (!specific1) return null;
      return {
        focus: props.kpi.payload.focus,
        variation: getVariation(
          getDataArray(
            [specific1, specific2, specific3, specific4],
            props.kpi.payload.focus,
          ),
          true,
          'requests',
        ),
      };
    }
    return null;
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = async () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data.ticks) {
        const {
          ticks,
          specific1,
          specific2,
          specific3,
          specific4,
        } = this.props.data;
        this.setState({
          series: [
            {
              name: '< 5 days',
              type: 'bar',
              data: specific1,
              styles: { data: style.specific1 },
            },
            {
              name: '5-15 days',
              type: 'bar',
              data: specific2,
              styles: { data: style.specific2 },
            },
            {
              name: '16-30 days',
              type: 'bar',
              data: specific3,
              styles: { data: style.specific3 },
            },
            {
              name: '> 30 days',
              type: 'bar',
              data: specific4,
              styles: { data: style.specific4 },
            },
          ],
          loading: false,
          error: false,
          ticks,
          tickFormat: formatStepLabel(this.props.interval.intervalType),
          variation: getVariation(
            getDataArray(
              [specific1, specific2, specific3, specific4],
              this.state.focus,
            ),
            true,
            'requests',
          ),
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  handleFocusChange = (e, { value }) => {
    this.props.saveKPI(this.props.kpi.i, { focus: value });
  };

  setTarget = async target => {
    await this.props.saveKPI(this.props.kpi.i, { target });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  getLegend = () => [
    {
      name: '< 5 days',
      value: '< 5 days',
      symbol: style.specific1,
      labels: {
        fontWeight: () =>
          this.state.focus === '< 5 days' ? 'bolder' : 'normal',
      },
    },
    {
      name: '5-15 days',
      value: '5-15 days',
      symbol: style.specific2,
      labels: {
        fontWeight: () =>
          this.state.focus === '5-15 days' ? 'bolder' : 'normal',
      },
    },
    {
      name: '16-30 days',
      value: '16-30 days',
      symbol: style.specific3,
      labels: {
        fontWeight: () =>
          this.state.focus === '16-30 days' ? 'bolder' : 'normal',
      },
    },
    {
      name: '> 30 days',
      value: '> 30 days',
      symbol: style.specific4,
      labels: {
        fontWeight: () =>
          this.state.focus === '> 30 days' ? 'bolder' : 'normal',
      },
    },
  ];

  getHeader() {
    return [
      <Header key="aircraftHeader">
        Compared aircraft
        <Header.Subheader>
          Select an aircraft registration in the dropdown to get its TAT over
          time. You can type in the dropdown to filter the list. The number is
          the amount of currently open requests.
        </Header.Subheader>
      </Header>,
      <Form key="aircraftSettings">
        <Form.Field>
          <Dropdown
            placeholder="Selected aircraft"
            fluid
            search
            selection
            options={
              this.props.rawData.aircraft &&
              this.props.rawData.aircraft.map(aircraft => {
                const requestCount =
                  parseFloat(aircraft.routineCount) +
                  parseFloat(aircraft.criticalCount);
                return {
                  key: aircraft.registration,
                  value: aircraft.registration,
                  text: `${aircraft.registration} - ${aircraft.type}`,
                  description: `${requestCount}`,
                };
              })
            }
            value={this.props.kpi.payload.target}
            onChange={(e, { value }) => this.setTarget(value)}
          />
        </Form.Field>
      </Form>,
      <Header key="focusHeader">
        Focused count
        <Header.Subheader>
          The selected count is reflected in the tendency widget
        </Header.Subheader>
      </Header>,
      <Form key="focusSettings">
        <Form.Field>
          <Radio
            toggle
            label="< 5 days"
            name="focusGroup"
            value="< 5 days"
            checked={this.state.focus === '< 5 days'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label="5-15 days"
            name="focusGroup"
            value="5-15 days"
            checked={this.state.focus === '5-15 days'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label="16-30 days"
            name="focusGroup"
            value="16-30 days"
            checked={this.state.focus === '16-30 days'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label="> 30 days"
            name="focusGroup"
            value="> 30 days"
            checked={this.state.focus === '> 30 days'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
      </Form>,
    ];
  }

  render() {
    return (
      <TimedKPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        saveKPI={this.props.saveKPI}
        loadKPI={this.props.loadKPI}
        deleteKPI={this.props.deleteKPI}
        title={`TAT over time ${
          this.props.kpi.payload.target
            ? `for ${this.props.kpi.payload.target}`
            : '(please configure)'
        }`}
        series={this.state.series}
        getData={this.getData}
        ticks={this.state.ticks}
        tickFormat={t => moment(t).format(this.state.tickFormat)}
        variation={this.state.variation}
        legend={this.getLegend()}
        loading={this.state.loading}
        error={this.state.error}
        extraSettings={this.getHeader()}
        svgDefs={defs}
        padding={{ top: 10, left: 40, right: 40, bottom: 60 }}
      />
    );
  }
}

TATByTimeKPI.propTypes = {
  ...KPIProps,
};

const style = {
  specific1: {
    fill: 'green',
  },
  specific2: {
    fill: 'yellow',
  },
  specific3: {
    fill: 'orange',
  },
  specific4: {
    fill: 'red',
  },
  average: {
    fill: 'url(#avgGrad)',
  },
  total: {
    stroke: '#56ab2f',
    strokeWidth: 2,
  },
  totalTooltip: {
    fill: '#56ab2f',
  },
};

const defs = svgDefs(
  linearGradient('avgGrad', [
    { offset: '0%', color: '#bc4e9c' },
    { offset: '100%', color: '#f80759' },
  ]),
  linearGradient('specficGrad', [
    { offset: '0%', color: '#f12711' },
    { offset: '100%', color: '#f5af19' },
  ]),
  dropShadow('dropshadow'),
);
