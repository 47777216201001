import React from 'react';
import moment from 'moment';
import { Icon, Button } from 'semantic-ui-react';

import {
  getMostRecentReading,
  rfidMoment,
  getRealAFPartNumber,
} from 'app/rfid/rfidUtils';
import { expiryLimit } from 'app/rfid/rfidSelectors';

export const TagRow = ({ index, style, data }) => {
  const tag = data.tags[index];
  const reading = getMostRecentReading(tag.readings);
  let pno = 'NC';
  let ser = 'NC';
  let exp;
  if (reading) {
    ser = reading.ser;
    if (tag.epc.toLowerCase().startsWith('1d')) {
      pno = getRealAFPartNumber(reading.pno);
    } else {
      pno = reading.pno;
    }
    if (reading.exp !== '') exp = rfidMoment(reading.exp);
  }
  let formattedExp;
  if (exp) formattedExp = exp.format('MMM Do Y');
  const all = tag.all;
  const selected = tag.LopaEquipmentId === data.selectedEquipment;
  const unassigned = pno === 'NC';
  const expired = exp && exp.clone().isBefore(moment());
  const willExpire =
    exp &&
    exp
      .clone()
      .subtract(expiryLimit.amount, expiryLimit.unit)
      .isBefore(moment());
  const background = all
    ? '#e8e8e8'
    : selected
    ? 'white'
    : expired
    ? '#FFA2A2'
    : willExpire
    ? 'orange'
    : unassigned
    ? '#A3CCEC'
    : '#e8e8e8';
  return (
    <div style={style}>
      <div
        className="rfidTag"
        style={{
          background,
        }}
        role="button"
        tabIndex={index}
        onClick={() => data.selectEquipment(tag.LopaEquipmentId)}
        title={
          expired
            ? 'This tag is expired'
            : willExpire
            ? 'This tag will expire soon'
            : ''
        }
      >
        {all && (
          <div style={styles.tagColumn}>
            <div style={styles.tagValue}>Show all tags</div>
          </div>
        )}
        {!all && (
          <React.Fragment>
            {selected && (expired || willExpire) && (
              <div style={{ width: 25, height: 25 }}>
                <Icon
                  circular
                  inverted
                  color={expired ? 'red' : 'orange'}
                  name="warning"
                />
              </div>
            )}
            <div style={styles.tagColumn}>
              <div style={styles.tagTitle}>PN</div>
              <div style={styles.tagValue}>{pno}</div>
            </div>
            <div style={styles.tagColumn}>
              <div style={styles.tagTitle}>SN</div>
              <div style={styles.tagValue}>{ser}</div>
            </div>
            <div style={styles.tagColumn}>
              <div style={styles.tagTitle}>EXP</div>
              <div style={styles.tagValue}>{formattedExp || 'NC'}</div>
            </div>
            {tag.isWF && (
              <div style={{ width: 25, height: 25 }}>
                <Button
                  size="tiny"
                  icon="edit"
                  color="red"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    data.setEditedTag(tag);
                  }}
                  circular
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const styles = {
  tagColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  tagValue: {
    fontWeigth: 'lighter',
    fontSize: '1.25em',
  },
  tagTitle: {
    color: 'gray',
  },
};
