import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Segment, Message, Form, Button } from 'semantic-ui-react';
import produce from 'immer';

import connect from 'lib/reduxConnect';

import HistoryModal from 'app/documents/components/historyModalComponent';

/**
 * NOTE: Feature("widget", "documentList", "document") is required to access to this feature
 */

class Documents extends Component {
  state = {
    documents: [],
    updatedDocuments: {},
    showHistoryModal: false,
    selectedDocumentId: null,
    historyModalTitle: '',
    message: {
      success: {
        open: false,
        text: '',
      },
      error: {
        open: false,
        text: '',
      },
    },
  };

  componentDidMount() {
    this.loadDocuments();
  }

  loadDocuments = async () => {
    await this.props.actions.loadDocuments();
    this.setState({ documents: this.props.documents });
  };

  loadHistory = async (documentId, title) => {
    await this.props.actions.loadHistory(documentId);
    this.showHistoryModal(documentId, title);
  };

  handleChange = (e, documentId) => {
    const name = e.target.name;
    const value = e.target.value;

    // 1.1 Save the updated documents to prepare the payload
    // ---

    const updatedDocuments = produce(this.state.updatedDocuments, draft => {
      if (!draft[documentId]) {
        draft[documentId] = {};
      }
      draft[documentId][name] = value;
    });

    // 1.2 Update the documents in local state
    // ---

    const documents = produce(this.state.documents, draft => {
      return draft.map(document => {
        if (document.id === documentId) {
          return { ...document, [name]: value };
        }
        return document;
      });
    });

    // 1.3 Push edits to the local state
    // ---

    this.setState({ updatedDocuments, documents });
  };

  uploadChanges = async () => {
    const apiResponse = await this.props.actions.requestRevisions(
      this.state.updatedDocuments,
    );
    if (apiResponse.success) {
      this.setState({
        updatedDocuments: {},
        message: {
          error: { open: false, text: '' },
          success: {
            open: true,
            text: 'New requested revisions have been sent successfully',
          },
        },
      });
      this.loadDocuments();
    } else {
      this.setState({
        message: {
          error: {
            open: true,
            text: 'An error occured, please try again later',
          },
          success: {
            open: false,
            text: '',
          },
        },
      });
    }
  };

  closeSynchroModal = () => {
    this.setState({ showHistoryModal: false });
  };

  showHistoryModal = (documentId, title) => {
    this.setState({
      showHistoryModal: true,
      selectedDocumentId: documentId,
      historyModalTitle: title,
    });
  };

  renderDocument = (document, revisionColor, updatedDocumentsFromState) => {
    return (
      <Segment key={document.id} padded className="document">
        <Header>
          {document.type} {document.manufacturer} {document.ata}
        </Header>
        <div className="family">{document.familyName}</div>
        <div className="meta">
          <span className="revision" style={{ backgroundColor: revisionColor }}>
            Current revision: {document.revision}
          </span>
        </div>
        <div>
          <Form>
            <Form.Field>
              <label>Revision target</label>
              <Form.Input
                type="text"
                name="revisionTarget"
                placeholder="Revision target"
                value={document.revisionTarget || ''}
                onChange={e => this.handleChange(e, document.id)}
              />
            </Form.Field>
            <Form.Field>
              <label>Comment</label>
              <Form.TextArea
                placeholder="Add a comment about this document"
                rows="2"
                name="comment"
                value={document.comment || ''}
                onChange={e => this.handleChange(e, document.id)}
              />
            </Form.Field>
            <Form.Field>
              {updatedDocumentsFromState[document.id] && (
                <Button
                  onClick={() => this.uploadChanges()}
                  size="small"
                  color="grey"
                  type="button"
                >
                  send
                </Button>
              )}
            </Form.Field>
            <Form.Field>
              <div className="show-history">
                <span
                  tabIndex={0}
                  role="button"
                  onClick={() =>
                    this.loadHistory(
                      document.id,
                      `${document.type} ${document.manufacturer} ${
                        document.ata
                      }`,
                    )
                  }
                >
                  show history
                </span>
              </div>
            </Form.Field>
          </Form>
        </div>
      </Segment>
    );
  };

  renderDocuments() {
    let documents;
    if (this.state.documents) {
      documents = this.state.documents.map(document => {
        let revisionColor = '#5ec35e';
        if (
          document.revisionTarget &&
          parseInt(document.revision, 10) !==
            parseInt(document.revisionTarget, 10)
        ) {
          revisionColor = 'orange';
        }
        return this.renderDocument(
          document,
          revisionColor,
          this.state.updatedDocuments,
        );
      });
    }

    return (
      <div>
        <HistoryModal
          modalTitle={this.state.historyModalTitle}
          history={this.props.history}
          isOpen={this.state.showHistoryModal}
          closeModalCallback={this.closeSynchroModal}
        />
        {this.renderSuccessMessage()}
        {this.renderErrorMessage()}
        <Segment.Group>{documents}</Segment.Group>
      </div>
    );
  }

  renderSuccessMessage = () => {
    if (this.state.message.success.open) {
      return (
        <Message floating positive>
          <Message.Header>{this.state.message.success.text}</Message.Header>
        </Message>
      );
    }
  };

  renderErrorMessage = () => {
    if (this.state.message.error.open) {
      return (
        <Message floating negative>
          <Message.Header>{this.state.message.error.text}</Message.Header>
        </Message>
      );
    }
  };

  render() {
    return (
      <div className="scrollableContainer slimContentMargin">
        {this.renderDocuments()}
      </div>
    );
  }
}

Documents.propTypes = {
  documents: PropTypes.array,
  history: PropTypes.array,
  authUserFeatures: PropTypes.array,
};

const mapStateToProps = state => ({
  documents: state.documents.documents,
  history: state.documents.history,
  authUserFeatures: state.auth.profile.features,
});

export default connect(mapStateToProps)(Documents);
