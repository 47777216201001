import React from 'react';

export const svgDefs = (...data) => (
  <svg width="0">
    <defs>{data}</defs>
  </svg>
);

export const linearGradient = (
  id,
  stops,
  { x1 = '0%', x2 = '0%', y1 = '0%', y2 = '100%' } = {},
) => {
  const stopElements = stops.map((stop, i) => (
    <stop key={i} offset={stop.offset} stopColor={stop.color} />
  ));
  return (
    <linearGradient key={id} id={id} x1={x1} y1={y1} x2={x2} y2={y2}>
      {stopElements}
    </linearGradient>
  );
};

export const dropShadow = (
  id,
  {
    height = '130%',
    dx = 2,
    dy = 2,
    slope = '0.2',
    type = 'linear',
    blur = '3',
  } = {},
) => (
  <filter key={id} id={id} height={height}>
    <feGaussianBlur in="SourceAlpha" stdDeviation={blur} />
    <feOffset dx={dx} dy={dy} result="offsetblur" />
    <feComponentTransfer>
      <feFuncA type={type} slope={slope} />
    </feComponentTransfer>
    <feMerge>
      <feMergeNode />
      <feMergeNode in="SourceGraphic" />
    </feMerge>
  </filter>
);
