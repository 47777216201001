import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import connect from 'lib/reduxConnect';

import ReportCollection from 'app/report/components/reportCollection';
import ReportListActions from './reportListActions';

class ReportList extends Component {
  static getActions() {
    return <ReportListActions />;
  }

  componentDidMount() {
    this.props.actions.getReportList();
  }
  renderCollections() {
    // check if we're on the summary or in a specific report list
    let summary = true;
    if (this.props.router.location.pathname.replace('/reports', '').length > 0)
      summary = false;
    return Object.keys(this.props.lists).reduce(
      (collections, currentType, index) => {
        if (
          !summary &&
          !this.props.router.location.pathname.includes(currentType)
        )
          return collections;
        const collection = this.props.lists[currentType];
        if (collection.list.length > 0)
          return [
            ...collections,
            <ReportCollection
              key={index}
              collection={collection}
              type={currentType}
              customer={this.props.customer}
              token={this.props.token}
              search={this.props.search}
              listLayout={this.props.listLayout}
              navigate={this.props.actions.navigate}
              fullscreen={!summary}
            />,
          ];
        return collections;
      },
      [],
    );
  }

  render() {
    if (!this.props.lists)
      return <Loader active size="massive" content="Loading reports..." />;
    const collections = this.renderCollections();
    return (
      <div className="contentMargin scrollableContainer">
        {(collections.length > 0 && collections) || 'No report available'}
      </div>
    );
  }
}

ReportList.propTypes = {
  lists: PropTypes.object,
  customer: PropTypes.object,
  search: PropTypes.string,
  listLayout: PropTypes.string,
};

const mapStateToProps = state => ({
  lists: state.report.lists,
  customer: state.auth.customer,
  search: state.ui.search,
  listLayout: state.report.listLayout,
});

export default connect(
  mapStateToProps,
  null,
  true,
)(ReportList);
