import React, { useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { FILE_API_URL } from 'config/network';
import placeholder from 'assets/logo-placeholder.png';

export default ({ loading, count, data, token, customer }) => {
  if (loading) return <div>Loading...</div>;
  if (!data) return <div>No data available</div>;
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lighboxOpen, setLightboxOpen] = useState(false);
  const images = [];
  const imageSrc = [];
  for (let index = 0; index < count; index += 1) {
    const src =
      (data[index] &&
        data[index] !== 'NO PHOTO' &&
        `${FILE_API_URL}/${customer.directory}photos/${data[1].replace(
          '.jpg',
          '_tbl.jpg',
        )}?token=${token}`) ||
      placeholder;
    imageSrc.push(src);
    images.push(
      <Grid.Column key={index}>
        <button
          onClick={() => {
            setLightboxIndex(index);
            setLightboxOpen(true);
          }}
          style={{
            border: 0,
            background: 'transparent',
            margin: 0,
            padding: 0,
            cursor: 'pointer',
          }}
        >
          <Image fluid bordered rounded src={src} />
        </button>
      </Grid.Column>,
    );
  }
  return (
    <Grid columns="3">
      {images}
      {lighboxOpen && (
        <Lightbox
          mainSrc={imageSrc[lightboxIndex]}
          nextSrc={imageSrc[(lightboxIndex + 1) % imageSrc.length]}
          prevSrc={
            imageSrc[(lightboxIndex + imageSrc.length - 1) % imageSrc.length]
          }
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setLightboxIndex(
              (lightboxIndex + imageSrc.length - 1) % imageSrc.length,
            )
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % images.length)
          }
        />
      )}
    </Grid>
  );
};
