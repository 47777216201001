import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Icon, Loader } from 'semantic-ui-react';
import produce from 'immer';

import connect from 'lib/reduxConnect';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import PieChart from 'app/creation/components/pieChart';

const stepOptions = {
  Aircraft: {
    getData: token => API.post(ENDPOINTS.webreporting.aircraft, token),
    name: 'aircrafts',
    title: element => element.registration,
    subtitle: element => `${element.type} - MSN ${element.msn}`,
    select: (element, actions) => {
      actions.setAircraft(element.id);
    },
    isValid: () => true,
    values: () => undefined,
    sort: (a, b) => 0,
  },
  CMM: {
    getData: (token, values) =>
      API.post(ENDPOINTS.webreporting.documents, token, values),
    name: 'documents',
    invalidNavigationPath: '/creations',
    title: element => element.documentText,
    subtitle: element => element.familyName,
    select: (element, actions) => {
      actions.setCMM(element.documentId, element.LopaId);
    },
    isValid: (creation, navigate) => {
      if (!creation.aircraftId) {
        return false;
      }
      return true;
    },
    values: creation => ({ aircraftId: creation.aircraftId }),
    sort: (a, b) => {
      if (a.familyName > b.familyName) return 1;
      if (a.familyName < b.familyName) return -1;
      return 0;
    },
  },
};

class CreationList extends Component {
  state = {
    elements: [],
    error: false,
    loading: true,
  };

  componentDidMount() {
    if (!this.props.mode) {
      throw new Error('No list mode');
    }
    if (!stepOptions[this.props.mode].isValid(this.props.creation)) {
      return this.props.actions.navigate(
        stepOptions[this.props.mode].invalidNavigationPath,
      );
    }
    this.props.actions.searchFilter('');
    this.getData();
  }

  getData = async () => {
    try {
      const response = await stepOptions[this.props.mode].getData(
        this.props.token,
        stepOptions[this.props.mode].values(this.props.creation),
      );
      if (response instanceof Array) {
        await this.setState(
          produce(draft => {
            draft.elements = response.sort(stepOptions[this.props.mode].sort);
            draft.loading = false;
          }),
        );
      } else {
        await this.setState(
          produce(draft => {
            draft.error = true;
            draft.loading = false;
          }),
        );
      }
    } catch (err) {
      await this.setState(
        produce(draft => {
          draft.error = true;
          draft.loading = false;
        }),
      );
    }
  };

  renderElement(element, index) {
    return (
      <List.Item
        key={index}
        onClick={() =>
          stepOptions[this.props.mode].select(element, this.props.actions)
        }
      >
        <List.Content floated="left">
          <PieChart element={element} />
        </List.Content>
        <List.Content floated="right" className="aircraftSelect">
          <Icon name="chevron right" />
        </List.Content>
        <List.Content className="aircraftContent">
          <List.Header>
            {stepOptions[this.props.mode].title(element)}
          </List.Header>
          <List.Description>
            {stepOptions[this.props.mode].subtitle(element)}
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }

  render() {
    if (!this.props.mode) return null;
    if (this.state.loading)
      return (
        <Loader
          active
          size="massive"
          content={`Loading ${stepOptions[this.props.mode].name}`}
        />
      );
    return (
      <div className="scrollableContainer">
        {this.state.error && (
          <h1
            style={{
              color: 'red',
              fontWeight: 'lighter',
              textAlign: 'center',
            }}
          >
            Could not get data, please reload your browser page
          </h1>
        )}
        <List relaxed divided size="huge" selection verticalAlign="middle">
          {this.state.elements.map(this.renderElement.bind(this))}
        </List>
      </div>
    );
  }
}

CreationList.propTypes = {
  search: PropTypes.string,
  creation: PropTypes.object,
  mode: PropTypes.string,
};

const mapStateToProps = state => ({
  search: state.ui.search,
  creation: state.creation,
});

export default connect(mapStateToProps)(CreationList);
