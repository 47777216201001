import React, { Component } from 'react';
import { Card, Loader, Pagination } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ExplorerCard from './explorerCard';

const maxItemsPerPage = 50;

const dateFormatForGroup = group => {
  switch (group) {
    case 'week':
      return 'YYYY [week] WW';

    default:
      return 'MMMM YYYY';
  }
};

export default class ExplorerList extends Component {
  state = {
    error: false,
    activePage: 1,
    data: [],
  };

  componentDidMount() {
    const data = this.groupData();
    this.setState({ activePage: 1, data });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.data !== prevProps.data ||
      this.props.dateGrouping !== prevProps.dateGrouping
    ) {
      const data = this.groupData();
      this.setState({ activePage: 1, data });
    }
  }

  groupData = () => {
    if (!this.props.filter) return [];
    let data = this.props.data;
    if (this.props.filter.name === 'Date') {
      if (this.props.dateGrouping !== 'day') {
        data = [];
        this.props.data.forEach(entry => {
          const date = moment(entry.title);
          const formattedDate = date.format(
            dateFormatForGroup(this.props.dateGrouping),
          );
          const existingGroup = data.find(
            group => group.title === formattedDate,
          );
          if (existingGroup) {
            existingGroup.valuearray = `${existingGroup.valuearray},${
              entry.title
            }`;
            existingGroup.requestCount += entry.requestCount;
            existingGroup.closedCount += parseFloat(entry.closedCount);
            existingGroup.routineCount += parseFloat(entry.routineCount);
            existingGroup.criticalCount += parseFloat(entry.criticalCount);
          } else {
            data.push({
              ...entry,
              title: formattedDate,
              valuearray: entry.title,
              closedCount: parseFloat(entry.closedCount),
              routineCount: parseFloat(entry.routineCount),
              criticalCount: parseFloat(entry.criticalCount),
            });
          }
        });
      }
    }
    return data;
  };

  handlePaginationChange = (e, { activePage }) => this.setState({ activePage });

  renderCards() {
    const maxRequestCount = this.state.data.reduce(
      (count, d) => (d.requestCount > count ? d.requestCount : count),
      0,
    );
    const renderedData = [];
    const cardBuffer = Math.min(
      this.state.data.length - maxItemsPerPage * (this.state.activePage - 1),
      maxItemsPerPage,
    );
    for (let index = 0; index < cardBuffer; index++) {
      renderedData.push(
        this.state.data[index + maxItemsPerPage * (this.state.activePage - 1)],
      );
    }
    return renderedData.map((d, i) => {
      const selected =
        this.props.selectedData.includes(d) ||
        this.props.selectedData.some(
          data =>
            (d.value && data.value === d.value) ||
            (d.valuearray && data.valuearray === d.valuearray) ||
            data.title === d.title,
        );
      return (
        <ExplorerCard
          selected={selected}
          key={i}
          data={d}
          addToFilters={this.props.addToFilters}
          maxRequestCount={maxRequestCount}
          customer={this.props.customer}
          filter={this.props.filter}
          token={this.props.token}
        />
      );
    });
  }

  render() {
    if (this.props.loading)
      return <Loader active size="massive" content="Loading explorer" />;
    if (!this.props.filter || this.state.data.length === 0)
      return (
        <div className="explorerListContainer" id="explorerList">
          <h2 className="title">
            No available data, please select
            {!this.props.filter ? ' a' : ' another'} filter
          </h2>
        </div>
      );
    return (
      <div className="explorerListContainer" id="explorerList">
        <h2 className="title">
          {`${this.props.filter.name}`}
          <small>{`${this.state.data.length}  available`}</small>
        </h2>
        {this.state.data.length > maxItemsPerPage ? (
          <div className="pagination">
            <Pagination
              activePage={this.state.activePage}
              onPageChange={this.handlePaginationChange}
              totalPages={Math.ceil(this.state.data.length / maxItemsPerPage)}
            />
          </div>
        ) : null}
        <Card.Group centered>{this.renderCards()}</Card.Group>
        {this.state.data.length > maxItemsPerPage ? (
          <div className="paginationBottom">
            <Pagination
              activePage={this.state.activePage}
              onPageChange={this.handlePaginationChange}
              totalPages={Math.ceil(this.state.data.length / maxItemsPerPage)}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ExplorerList.propTypes = {
  data: PropTypes.array,
  selectedData: PropTypes.array,
  filter: PropTypes.object,
  loading: PropTypes.bool,
  addToFilters: PropTypes.func,
  customer: PropTypes.object,
  token: PropTypes.string,
  forceUpdate: PropTypes.func,
  isDoingTutorial: PropTypes.bool,
  dateGrouping: PropTypes.string,
};
