import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  devices: [],
  devicePagination: 1,
  synchros: [],
  synchroPagination: 1,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.DEVICES_LOAD_DEVICES:
        draft.devices = action.payload.devices;
        draft.devicePagination = action.payload.nextPage;
        break;
      case types.DEVICES_LOAD_SYNCHROS:
        draft.synchros = action.payload.synchros;
        draft.synchroPagination = action.payload.nextPage;
        break;
      case types.DEVICES_DELETE:
        draft.devices = draft.devices.filter(
          device => device.id !== action.payload.deviceId,
        );
        break;
    }
  });
