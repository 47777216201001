import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

const optionsPerimeter = [
  { key: 'dp', text: 'All data', value: 'all' },
  { key: 'dp-0', text: '24 hours', value: 'h-24' },
  { key: 'dp-1', text: '48 hours', value: 'h-48' },
  { key: 'dp-2', text: '7 days', value: 'd-7' },
  { key: 'dp-3', text: '14 days', value: 'd-14' },
  { key: 'dp-4', text: 'month', value: 'm-1' },
  { key: 'dp-5', text: '2 months', value: 'm-2' },
  { key: 'dp-6', text: '3 months', value: 'm-3' },
  { key: 'dp-7', text: '6 months', value: 'm-6' },
  { key: 'dp-8', text: '12 months', value: 'm-12' },
  { key: 'dp-9', text: '24 months', value: 'm-24' },
];

let optionsGroupBy = [];

const MaintenanceActionsComponent = ({ chartConfig, actions, token }) => {
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    getFilters();
  }, []);

  const periodeChange = value => {
    actions.setChartConfig({ ...chartConfig, periode: value });
    actions.setFirstCall(true);
  };

  const groupByChange = value => {
    actions.setChartConfig({ ...chartConfig, groupBy: value });
    actions.setFirstCall(true);
  };

  const getFilters = async () => {
    const response = await API.get(ENDPOINTS.webreporting.filters, token);
    optionsGroupBy = [];
    response.data.forEach(row => {
      if (row.type === 'CustomField') return;
      let value = row.type;
      if (row.type === 'Interop') {
        value = `${row.type}|${row.id}`;
      }
      optionsGroupBy.push({
        key: value,
        text: row.name,
        value: value.toLowerCase(),
      });
      if (row.name === 'Date') {
        optionsGroupBy.push({
          key: 'month',
          text: 'Month',
          value: 'month',
        });
      }
    });
    setFilters(optionsGroupBy);
  };

  return (
    <div className="pageActions">
      <div>Group By</div>
      <Dropdown
        selection
        value={chartConfig.groupBy}
        onChange={(e, { value }) => {
          groupByChange(value);
        }}
        options={filters}
      />
      <div>Data from the last</div>
      <Dropdown
        selection
        value={chartConfig.periode}
        onChange={(e, { value }) => {
          periodeChange(value);
        }}
        options={optionsPerimeter}
      />
    </div>
  );
};

const mapStateToProps = function(state) {
  return {
    firstCall: state.maintenance.firstCall,
    chartConfig: state.maintenance.chartConfig,
    filters: state.explorer.filters,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(MaintenanceActionsComponent);
