import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Input, Image, Label, Button } from 'semantic-ui-react';
import { FixedSizeList } from 'react-window';
import { debounce } from 'debounce';
import { SizeMe } from 'react-sizeme';
import {
  getIconFromDescription,
  getMostRecentReading,
  makeXLSX,
} from 'app/rfid/rfidUtils';

import Canvas from './aircraftCanvas';
import { TagRow } from './tagRow';

const itemSize = 60;

export default class AircraftDetail extends Component {
  state = { search: '', tags: [] };

  componentDidMount() {
    this.applySearch();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.aircraft !== this.props.aircraft) {
      this.applySearch();
    }
  }

  onSearch = (e, { value }) => {
    this.setState({ search: value });
    this.applySearch();
  };

  applySearch = debounce(() => {
    let tags = this.props.aircraft.tags;
    if (this.state.search !== '') {
      const search = this.state.search.toLowerCase();
      tags = tags.filter(t => {
        const reading = getMostRecentReading(t.readings);
        if (reading)
          return (
            reading.pno.toLowerCase().includes(search) ||
            reading.ser.toLowerCase().includes(search)
          );
        return search === 'nc';
      });
    }
    this.setState({ tags });
  }, 500);

  renderCategory(category, index) {
    const tags = this.props.rawTags.filter(
      tag => tag.descriptionDoc === category,
    );
    return (
      <div
        key={index}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '1em',
          position: 'relative',
          padding: '0.25em',
          borderRadius: '0.25em',
          background:
            this.props.selectedCategory === category
              ? '#d8d8d8'
              : 'transparent',
        }}
        onClick={() => this.props.selectCategory(category)}
        role="button"
        tabIndex={0}
      >
        <Label color="red" floating size="tiny">
          {category ? tags.length : this.props.rawTags.length}
        </Label>
        <Image src={getIconFromDescription(category)} />
        <div
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '0.75em',
          }}
        >
          {category || 'All'}
        </div>
      </div>
    );
  }

  renderLopa() {
    return (
      <SizeMe>
        {({ size }) => (
          <Canvas
            width={size.width}
            scene={this.props.scene}
            selectEquipment={this.props.selectEquipment}
            selectedEquipment={this.props.selectedEquipment}
          />
        )}
      </SizeMe>
    );
  }

  renderTagFilterButton = (icon, filter, color) => (
    <Button
      onClick={() => this.props.setTagFilter(filter)}
      active={this.props.tagFilter === filter}
      basic={this.props.tagFilter === filter}
      icon={icon}
      color={color}
    />
  );

  renderTags() {
    return (
      <div
        style={{
          minWidth: 360,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Input
            size="small"
            icon="search"
            placeholder="Search PN or SN..."
            value={this.state.search}
            onChange={this.onSearch}
          />
          <div
            style={{
              width: '100%',
              marginTop: '0.25em',
              marginBottom: '0.25em',
              display: 'flex',
            }}
          >
            <Button.Group size="small" fluid>
              {this.renderTagFilterButton('list', null)}
              {this.renderTagFilterButton('edit', 'editable')}
              {this.renderTagFilterButton('question', 'nc', 'blue')}
              {this.renderTagFilterButton('warning', 'willExp', 'orange')}
              {this.renderTagFilterButton('warning', 'exp', 'red')}
            </Button.Group>
            <Button
              fluid
              style={{ marginLeft: '0.25em' }}
              content="Excel (.xlsx)"
              icon="save"
              primary
              onClick={() => makeXLSX(this.props.aircraft.tags)}
            />
          </div>
        </div>
        <FixedSizeList
          width="100%"
          height={430}
          itemCount={this.state.tags.length}
          itemSize={itemSize}
          itemData={{
            selectEquipment: this.props.selectEquipment,
            selectedEquipment: this.props.selectedEquipment,
            selectedCategory: this.props.selectedCategory,
            setEditedTag: this.props.setEditedTag,
            tags: this.state.tags,
          }}
        >
          {TagRow}
        </FixedSizeList>
      </div>
    );
  }

  render() {
    if (!this.props.scene)
      return (
        <div style={styles.container}>
          <Loader active inline="centered" content="Loading data" />
        </div>
      );
    return (
      <div style={styles.container}>
        <div
          style={{
            width: '7em',
            paddingRight: '2em',
            paddingTop: '1em',
            maxHeight: 500,
            overflowY: 'auto',
            minWidth: 100,
          }}
        >
          {this.renderCategory(null, -1)}
          {this.props.categories.map(this.renderCategory.bind(this))}
        </div>
        <div
          className="rfidCanvasViewPort"
          style={{
            flex: 2,
            marginRight: '1em',
            overflowX: 'auto',
          }}
        >
          {this.renderLopa()}
        </div>
        <div style={{ flex: 1 }}>{this.renderTags()}</div>
      </div>
    );
  }
}

AircraftDetail.propTypes = {
  aircraft: PropTypes.object,
  rawTags: PropTypes.array,
  scene: PropTypes.array,
  categories: PropTypes.array,
  selectEquipment: PropTypes.func,
  selectedEquipment: PropTypes.number,
  selectCategory: PropTypes.func,
  selectedCategory: PropTypes.string,
  tagFilter: PropTypes.string,
  setTagFilter: PropTypes.func,
  setEditedTag: PropTypes.func,
};

const styles = {
  container: { flex: 1, padding: '1em', paddingTop: 0, display: 'flex' },
};
