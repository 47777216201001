import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  reportId: null,
  type: null,
  layouts: {},
  data: {},
  busy: false,
  lists: {},
  listLayout: 'grid',
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.REPORT_SET_CURRENT:
        draft.reportId = action.payload.reportId;
        draft.type = action.payload.type;
        break;
      case types.REPORT_SET_LAYOUT:
        if (!draft.layouts) draft.layouts = {};
        draft.layouts[action.payload.type] = action.payload.layout;
        break;
      case types.REPORT_SET_DATA:
        if (!draft.data) draft.data = {};
        draft.data[action.payload.section] = action.payload.data;
        break;
      case types.REPORT_IS_BUSY:
        draft.busy = action.payload.busy;
        break;
      case types.REPORT_SET_LISTS:
        draft.lists = action.payload.lists;
        break;
      case types.REPORT_SET_LIST_LAYOUT:
        draft.listLayout = action.payload.listLayout;
        break;
    }
  });
