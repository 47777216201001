import React from 'react';
import { Icon, Header } from 'semantic-ui-react';

export default ({ loading, icon, title, subtitle, as }) => {
  return (
    <Header as={as || 'h3'}>
      {icon && <Icon name={icon} />}
      <Header.Content>
        {loading ? 'Loading...' : title || 'No data available'}
        {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
      </Header.Content>
    </Header>
  );
};
