import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  sortCards: 'desc',
  currentBreadcrumb: -1,
  modifiedRequestIds: [],
  filters: [],
  selectedData: [],
  breadcrumbs: [],
  showTutorial: true,
  suggestion: null,
  showTable: false,
  tableDirty: false,
  requests: [],
  availableColumns: [],
  displayedColumns: [],
  currentFilter: null,
  dateGrouping: 'day',
  standaloneRequestIds: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.EXPLORER_CARD_SORT:
        draft.sortCards = action.payload.value;
        break;
      case types.EXPLORER_SET_FILTERS:
        draft.filters = action.payload.filters;
        draft.selectedData = action.payload.selectedData;
        break;
      case types.EXPLORER_SET_MODIFIED_REQUESTS:
        draft.modifiedRequestIds = action.payload.requestIds;
        break;
      case types.EXPLORER_SET_BREADCRUMBS:
        draft.breadcrumbs = action.payload.breadcrumbs;
        break;
      case types.EXPLORER_SET_CURRENT_BREADCRUMB:
        draft.currentBreadcrumb = action.payload.currentBreadcrumb;
        break;
      case types.EXPLORER_TOGGLE_TUTORIAL:
        draft.showTutorial = !draft.showTutorial;
        break;
      case types.EXPLORER_SET_SUGGESTION:
        draft.suggestion = action.payload.suggestion;
        break;
      case types.EXPLORER_SHOW_TABLE:
        draft.showTable = true;
        break;
      case types.EXPLORER_HIDE_TABLE:
        draft.showTable = false;
        break;
      case types.EXPLORER_MARK_DIRTY:
        draft.tableDirty = action.payload.dirty;
        break;
      case types.EXPLORER_SET_TABLE_DATA:
        draft.requests = action.payload.requests;
        draft.availableColumns = action.payload.availableColumns;
        draft.displayedColumns = action.payload.displayedColumns;
        break;
      case types.EXPLORER_SET_SELECTED_FILTER:
        draft.currentFilter = action.payload.currentFilter;
        break;
      case types.EXPLORER_SET_DATE_GROUPING:
        draft.dateGrouping = action.payload.dateGrouping;
        break;
      case types.EXPLORER_SET_STANDALONE_REQUESTS:
        draft.standaloneRequestIds = action.payload.standaloneRequestIds;
        break;
    }
  });
