import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Header,
  Segment,
  Confirm,
  Form,
  Pagination,
  Loader,
} from 'semantic-ui-react';
import { debounce } from 'debounce';
import connect from 'lib/reduxConnect';

import SynchroModal from 'app/devices/components/synchroModalComponent';

/**
 * NOTE: Feature("widget", "deviceList", "device") is required to access to this feature
 */

const footerSize = 40;
const maxItemsPerPage = 7;

class Devices extends Component {
  state = {
    devices: [],
    devicesFiltered: [],
    synchronisationModalTitle: '',
    showSynchronisationsModal: false,
    showNextPageSynchroButton: true,
    showNextPageDeviceButton: true,
    selectedDeviceId: false,
    showDeletionConfirmBox: false,
    selectedDeviceForDeletion: '',
    searchValue: '',
    currentPage: 1,
    totalPages: 0,
    loading: true,
  };

  componentDidMount() {
    this.loadDevices();
  }

  searchChange = e => {
    this.setState({ searchValue: e.target.value, currentPage: 1 });
    this.applyFilter();
  };

  // Device synchronisations
  // ===

  showSynchroModal = async (deviceId, title) => {
    this.setState({
      showSynchronisationsModal: true,
      selectedDeviceId: deviceId,
      synchronisationModalTitle: title,
    });

    const synchroCount = await this.props.actions.getSynchro(deviceId, 1);
    let showNextPageSynchroButton = true;

    if (synchroCount < 25) {
      showNextPageSynchroButton = false;
    }

    this.setState({
      showNextPageSynchroButton,
    });
  };

  closeSynchroModal = () => {
    this.setState({ showSynchronisationsModal: false });
  };

  loadNextSynchro = async () => {
    const synchroCount = await this.props.actions.getSynchro(
      this.state.selectedDeviceId,
    );

    if (synchroCount < 25) {
      this.setState({
        showNextPageSynchroButton: false,
      });
    }
  };

  // Device list
  // ===

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
    this.applyFilter();
  };

  loadDevices = () => {
    this.props.actions.getDevices(1);
    this.applyFilter();
    this.setState({ loading: false });
  };

  applyFilter = debounce(() => {
    const search = this.state.searchValue.toLowerCase();
    let devices = this.props.devices;
    let devicesFiltered = [];
    if (this.state.searchValue && this.state.searchValue.length) {
      devices = devices.filter(device => {
        return (
          device.name.toLowerCase().includes(search) ||
          device.userAgent.toLowerCase().includes(search) ||
          (device.synchronisations &&
            device.synchronisations.latestBy &&
            device.synchronisations.latestBy.toLowerCase().includes(search))
        );
      });
    }
    let start = (this.state.currentPage - 1) * maxItemsPerPage;
    let end = start + maxItemsPerPage;
    this.setState({ totalPages: Math.ceil(devices.length / maxItemsPerPage) });
    devicesFiltered = devices.slice(start, end);
    this.setState({ devicesFiltered: devicesFiltered });
  }, 500);

  loadNextDevices = async () => {
    const deviceCount = await this.props.actions.getDevices();

    if (deviceCount < 25) {
      this.setState({
        showNextPageDeviceButton: false,
      });
    }
  };

  renderDevices() {
    let devices;
    const devicesFromState = this.state.devicesFiltered;
    if (devicesFromState) {
      devices = devicesFromState.map(device => (
        <Segment key={device.id} className="device">
          <Header>{decodeURI(device.name)}</Header>
          <div className="deviceActions">
            <Button
              onClick={() => this.showDeletionConfirmationBox(device.id)}
              negative
              icon="remove"
            />
          </div>
          <div className="deviceMeta">
            <div>
              <b>Status:</b> {device.status}
            </div>
            <div>
              <b>Last synchro:</b> {device.synchronisations.latestDate}
              &nbsp;by {device.synchronisations.latestBy}
            </div>
            <div>
              <b>Synchronisations count:</b>{' '}
              <span
                onClick={() => this.showSynchroModal(device.id, device.name)}
                tabIndex={0}
                role="button"
                style={{ borderBottom: 'dotted 1px #333', cursor: 'pointer' }}
              >
                {device.synchronisations.total}
              </span>
            </div>
            <div>
              <b>User agent:</b> {device.userAgent}
            </div>
            <div>
              <b>flush:</b> {device.flush}
            </div>
          </div>
        </Segment>
      ));
    }

    return (
      <div>
        <SynchroModal
          modalTitle={this.state.synchronisationModalTitle}
          synchrosFromState={this.props.synchros}
          isOpen={this.state.showSynchronisationsModal}
          showNextPageButton={this.state.showNextPageSynchroButton}
          closeModalCallback={this.closeSynchroModal}
          loadNextPageCallback={this.loadNextSynchro}
        />
        <Segment.Group>{devices}</Segment.Group>
        <div style={{ textAlign: 'center' }}>
          <div
            style={{
              height: footerSize,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Pagination
              activePage={this.state.currentPage}
              totalPages={this.state.totalPages}
              pointing
              secondary
              onPageChange={this.handlePaginationChange}
            />
          </div>
        </div>
      </div>
    );
  }

  // Device deletion
  // ===

  showDeletionConfirmationBox = deviceId => {
    this.setState({
      showDeletionConfirmBox: true,
      selectedDeviceForDeletion: deviceId,
    });
  };

  hideDeletionConfirmationBox = () => {
    this.setState({
      showDeletionConfirmBox: false,
      selectedDeviceForDeletion: '',
    });
  };

  deleteADevice = () => {
    this.props.actions.deleteDevice(this.state.selectedDeviceForDeletion);
    this.hideDeletionConfirmationBox();
    this.loadDevices();
  };

  // Render the container
  // ===

  render() {
    return (
      <div className="scrollableContainer">
        <Confirm
          content={'Delete this device ?'}
          open={this.state.showDeletionConfirmBox}
          onCancel={this.hideDeletionConfirmationBox}
          onConfirm={this.deleteADevice}
        />
        <Form style={{ paddingLeft: 30 }}>
          <Form.Group widths="equal" inline>
            <Form.Input
              label="Search a device"
              placeholder="Start typing to search for a device"
              fluid
              value={this.state.searchValue}
              onChange={e => {
                this.searchChange(e);
              }}
            />
          </Form.Group>
        </Form>
        {this.state.loading && (
          <div
            style={{
              flex: 1,
              position: 'relative',
              height: '100%',
              top: '200px',
            }}
          >
            <Loader active size="massive" content="Loading devices" />
          </div>
        )}
        <div className="secondaryContentMargin">{this.renderDevices()}</div>
      </div>
    );
  }
}

Devices.propTypes = {
  devices: PropTypes.array,
  synchros: PropTypes.array,
  authUserFeatures: PropTypes.array,
};

const mapStateToProps = state => ({
  devices: state.devices.devices,
  synchros: state.devices.synchros,
  authUserFeatures: state.auth.profile.features,
});

export default connect(mapStateToProps)(Devices);
