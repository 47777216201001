import React, { PureComponent } from 'react';
import moment from 'moment';
import { Radio, Header, Form, Dropdown } from 'semantic-ui-react';

import { KPIProps } from 'app/home/components/types';
import { svgDefs, linearGradient, dropShadow } from 'lib/svg';

import TimedKPI, { getVariation } from './timedKPI';
import { formatStepLabel } from 'app/util/datepicker';

const getDataArray = (specific, average, total, focus) => {
  let dataArray;
  switch (focus) {
    case 'average':
      dataArray = average;
      break;

    default:
      dataArray = specific;
      break;
  }
  return dataArray;
};

export default class DefectiveSubassyOverTimeKPI extends PureComponent {
  state = {
    specific: [],
    average: [],
    series: [],
    ticks: [],
    tickFormat: 'HH:00',
    loading: true,
    error: false,
    focus: 'specific',
    variation: {
      value: 0,
      diff: 0,
      inverted: false,
      unit: 'defects',
    },
  };

  // called on init and updates, prevents desynced focus props
  static getDerivedStateFromProps(props, state) {
    if (state.focus !== props.kpi.payload.focus)
      return {
        focus: props.kpi.payload.focus,
        variation: getVariation(
          getDataArray(state.specific, state.average, props.kpi.payload.focus),
          true,
          'defects',
        ),
      };
    return null;
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  handleFocusChange = (e, { value }) => {
    this.props.saveKPI(this.props.kpi.i, { focus: value });
  };

  getData = async () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data.ticks) {
        const { ticks, specific, average } = this.props.data;
        this.setState({
          specific,
          average,
          series: [
            {
              name: 'specific',
              type: 'bar',
              data: specific,
              styles: { data: style.specific },
            },
            {
              name: 'average',
              type: 'bar',
              data: average,
              styles: { data: style.average },
            },
          ],
          loading: false,
          error: false,
          variation: getVariation(
            getDataArray(specific, average, this.state.focus),
            true,
            'defects',
          ),
          ticks,
          tickFormat: formatStepLabel(this.props.interval.intervalType),
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  setTarget = async target => {
    await this.props.saveKPI(this.props.kpi.i, { target });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  getLegend = () => [
    {
      name: this.props.kpi.payload.target || 'No aircraft set',
      value: 'specific',
      symbol: style.specific,
      labels: {
        fontWeight: () =>
          this.state.focus === 'specific' ? 'bolder' : 'normal',
      },
    },
    {
      name: 'Fleet average',
      value: 'average',
      symbol: style.average,
      labels: {
        fontWeight: () =>
          this.state.focus === 'average' ? 'bolder' : 'normal',
      },
    },
  ];

  getHeader() {
    return [
      <Header key="aircraftHeader">
        Compared aircraft
        <Header.Subheader>
          Select an aircraft registration in the dropdown to compare it to its
          type average. You can type in the dropdown to filter the list. The
          number is the amount of currently open requests.
        </Header.Subheader>
      </Header>,
      <Form key="aircraftSettings">
        <Form.Field>
          <Dropdown
            placeholder="Selected aircraft"
            fluid
            search
            selection
            options={
              this.props.rawData.aircraft &&
              this.props.rawData.aircraft.map(aircraft => {
                const requestCount =
                  parseFloat(aircraft.routineCount) +
                  parseFloat(aircraft.criticalCount);
                return {
                  key: aircraft.registration,
                  value: aircraft.registration,
                  text: `${aircraft.registration} - ${aircraft.type}`,
                  description: `${requestCount}`,
                };
              })
            }
            value={this.props.kpi.payload.target}
            onChange={(e, { value }) => this.setTarget(value)}
          />
        </Form.Field>
      </Form>,
      <Header key="focusHeader">
        Focused count
        <Header.Subheader>
          The selected count is reflected in the tendency widget
        </Header.Subheader>
      </Header>,
      <Form key="focusSettings">
        <Form.Field>
          <Radio
            toggle
            label={
              this.props.kpi.payload.target
                ? this.props.kpi.payload.target
                : 'No aircraft set'
            }
            name="focusGroup"
            value="specific"
            checked={this.state.focus === 'specific'}
            onChange={this.handleFocusChange.bind(this)}
            disabled={!this.props.kpi.payload.target}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label="Fleet average"
            name="focusGroup"
            value="average"
            checked={this.state.focus === 'average'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
      </Form>,
    ];
  }

  render() {
    return (
      <TimedKPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        saveKPI={this.props.saveKPI}
        loadKPI={this.props.loadKPI}
        deleteKPI={this.props.deleteKPI}
        title={`Defects over time ${
          this.props.kpi.payload.target
            ? `for ${this.props.kpi.payload.target}`
            : '(please configure)'
        }`}
        series={this.state.series}
        getData={this.getData}
        ticks={this.state.ticks}
        tickFormat={t => moment(t).format(this.state.tickFormat)}
        variation={this.state.variation}
        legend={this.getLegend()}
        loading={this.state.loading}
        error={this.state.error}
        extraSettings={this.getHeader()}
        svgDefs={defs}
      />
    );
  }
}

DefectiveSubassyOverTimeKPI.propTypes = {
  ...KPIProps,
};

const style = {
  specific: {
    fill: 'url(#specficGrad)',
  },
  average: {
    fill: 'url(#avgGrad)',
  },
  total: {
    stroke: '#56ab2f',
    strokeWidth: 2,
  },
  totalTooltip: {
    fill: '#56ab2f',
  },
};

const defs = svgDefs(
  linearGradient('avgGrad', [
    { offset: '0%', color: '#bc4e9c' },
    { offset: '100%', color: '#f80759' },
  ]),
  linearGradient('specficGrad', [
    { offset: '0%', color: '#f12711' },
    { offset: '100%', color: '#f5af19' },
  ]),
  dropShadow('dropshadow'),
);
