import { push, goBack } from 'connected-react-router';
import * as types from 'config/types';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

export const minimizeMainMenu = () => ({
  type: types.UI_MINIMIZE,
});

export const navigate = path => dispatch => {
  dispatch(push(path));
};

export const navBack = () => dispatch => {
  dispatch(goBack());
};

export const searchFilter = (search, isGlobalSearch) => async (
  dispatch,
  getState,
) => {
  if (isGlobalSearch && search.length >= 3) {
    const token = getState().auth.token;
    const results = await API.post(ENDPOINTS.webreporting.search, token, {
      search,
    });
    dispatch({
      type: types.UI_SET_SEARCH_RESULTS,
      payload: { results },
    });
  } else {
    dispatch({
      type: types.UI_SET_SEARCH_RESULTS,
      payload: { results: {} },
    });
  }
  dispatch({
    type: types.UI_SEARCH,
    payload: { search },
  });
};
