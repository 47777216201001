import React from 'react';
import {
  VictoryChart,
  VictoryLegend,
  VictoryBar,
  VictoryLine,
  VictoryGroup,
  VictoryAxis,
} from 'victory';
import moment from 'moment';

import { Tooltip } from 'app/home/components/chart';
import {
  maxRowWidth,
  maxRowColumns,
  barWidth,
  maxTimeRecords,
} from 'app/report/reportUtils';

export default ({ loading, widget, data }) => {
  if (loading) return <div>Loading...</div>;
  if (!data) return <div>No data available</div>;
  const groupBarSeries = [];
  const independentBarSeries = [];
  const lineSeries = [];
  const tickValues = [];
  const legend = widget.options.data.dynamic
    ? data.legend
    : widget.options.data;
  const chartData = widget.options.data.dynamic ? data.values : data;
  legend.map((d, index) => {
    const rawData = chartData[index];
    let formattedData;
    switch (widget.options.scale) {
      case 'time': {
        const limitedData = [...rawData];
        if (rawData.length > maxTimeRecords)
          limitedData.splice(0, rawData.length - maxTimeRecords);
        formattedData = limitedData.map(record => {
          const date = moment(record.x);
          if (!tickValues.includes(date)) tickValues.push(date);
          return {
            ...record,
            x: date,
          };
        });
        break;
      }

      default:
        formattedData = rawData.map((record, rindex) => {
          if (!tickValues.includes(d.ticks[rindex].name))
            tickValues.push(d.ticks[rindex].name);
          return {
            x: d.ticks[rindex].name,
            y: record,
          };
        });
        break;
    }
    switch (d.type) {
      case 'bar':
        groupBarSeries.push(
          <VictoryBar
            key={index}
            data={formattedData}
            labels={d => d.y}
            style={{ data: { fill: d.color } }}
            barWidth={barWidth}
            horizontal={widget.options.horizontal || false}
          />,
        );
        break;
      case 'independentBar':
        independentBarSeries.push(
          <VictoryBar
            key={index}
            data={formattedData}
            labels={d => d.y}
            style={{
              data: {
                fill: ({ x }) => {
                  return d.ticks.find(t => t.name === x).color;
                },
              },
            }}
            barWidth={barWidth}
            horizontal={widget.options.horizontal || false}
          />,
        );
        break;

      default:
        lineSeries.push(
          <VictoryLine
            interpolation="catmullRom"
            key={index}
            data={formattedData}
            labels={d => d.y}
            style={{ data: { stroke: d.color } }}
            labelComponent={<Tooltip style={{ fill: d.color }} />}
          />,
        );
        break;
    }
  });
  const legendData = legend.map(d => ({
    name: d.name,
    symbol: { fill: d.color },
  }));
  const width = (widget.width * maxRowWidth) / maxRowColumns;
  return (
    <div>
      <VictoryChart
        width={width}
        height={300}
        domainPadding={{ x: barWidth * 2, y: 20 }}
        singleQuadrantDomainPadding={{ x: false }}
        padding={{
          top: 10,
          left: widget.options.horizontal ? 75 : 10,
          right: widget.options.horizontal ? 50 : 10,
          bottom: widget.options.horizontal ? 10 : 50,
        }}
        scale={{ x: widget.options.scale || 'linear', y: 'linear' }}
      >
        <VictoryGroup offset={barWidth}>{groupBarSeries}</VictoryGroup>
        {independentBarSeries}
        {lineSeries}
        <VictoryAxis
          crossAxis={false}
          tickValues={tickValues}
          tickFormat={t => {
            if (typeof t === 'string') return t;
            return moment(t).format(widget.options.timeFormat || 'DD/MM/YYYY');
          }}
        />
      </VictoryChart>
      {legendData.length > 1 && (
        <VictoryLegend
          width={width}
          height={30}
          orientation="horizontal"
          data={legendData}
        />
      )}
    </div>
  );
};
