import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  aircraft: [],
  tags: {},
  selectedAircraftId: -1,
  scene: null,
  equipmentCategories: [],
  selectedEquipmentId: -1,
  category: null,
  tagFilter: null,
  editedTag: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.RFID_SET_DATA:
        draft.aircraft = action.payload.aircraft;
        draft.tags = action.payload.tags;
        break;
      case types.RFID_SET_SELECTED_AIRCRAFT:
        draft.selectedAircraftId = action.payload.aircraftId;
        draft.scene = null;
        draft.selectedEquipmentId = -1;
        draft.category = null;
        break;
      case types.RFID_SET_SCENE:
        draft.scene = action.payload.scene;
        draft.equipmentCategories = action.payload.equipmentCategories;
        break;
      case types.RFID_SET_SELECTED_EQUIPMENT:
        draft.selectedEquipmentId = action.payload.selectedEquipmentId;
        break;
      case types.RFID_SET_SELECTED_CATEGORY:
        if (state.category === action.payload.category) draft.category = null;
        else draft.category = action.payload.category;
        break;
      case types.RFID_SET_TAG_FILTER:
        draft.tagFilter = action.payload.tagFilter;
        break;
      case types.RFID_SET_EDITED_TAG:
        draft.editedTag = action.payload.editedTag;
        break;
    }
  });
