import React, { PureComponent } from 'react';
import { Icon, Header, List } from 'semantic-ui-react';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';
import {
  getMetricFilterFromPayload,
  getMetricFilterListFromCustomer,
} from 'app/home/homeConstants';

export default class MetricKPI extends PureComponent {
  state = {
    loading: true,
    error: false,
    data: { count: 0, variation: 0 },
  };

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = async () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data.count != undefined) {
        this.setState({
          loading: false,
          data: this.props.data,
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  onSettingsClose = async () => {
    await this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
    this.forceUpdate();
  };

  setMetricFilter = async filter => {
    await this.props.saveKPI(this.props.kpi.i, { filter });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  renderFilterList() {
    const filters = getMetricFilterListFromCustomer(this.props.customer);
    return (
      <List relaxed divided selection>
        {filters.map((filter, index) => (
          <List.Item
            key={index}
            onClick={() => this.setMetricFilter(filter.type)}
          >
            <List.Content floated="right">
              <Icon name="chevron right" />
            </List.Content>
            <List.Content>
              <List.Header>{filter.title}</List.Header>
            </List.Content>
          </List.Item>
        ))}
      </List>
    );
  }

  render() {
    const variationPrefix = this.state.data.variation > 0 ? '+' : '';
    const variationColor = this.state.data.variation > 0 ? 'red' : 'green';
    const variationArrow = this.state.data.variation > 0 ? 'up' : 'down';
    const filter = getMetricFilterFromPayload(
      this.props.kpi.payload,
      this.props.customer,
    );
    if (!filter) return null;
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.state.loading}
        error={this.state.error}
        getData={this.getData}
        deleteKPI={this.props.deleteKPI}
      >
        <KPI.Header title={filter.title} onClose={this.onSettingsClose}>
          <Header>
            Metric
            <Header.Subheader>
              Choose which metric you wish to display in this block
            </Header.Subheader>
          </Header>
          {this.renderFilterList()}
        </KPI.Header>
        <KPI.Data>
          <div style={style.container}>
            <div style={{ ...style.variation, color: variationColor }}>
              <Icon name={`arrow ${variationArrow}`} />
              {`${variationPrefix}${this.state.data.variation}%`}
            </div>
            <div style={style.count}>{this.state.data.count}</div>
            <div style={style.legend}>{filter.unit}</div>
          </div>
        </KPI.Data>
      </KPI>
    );
  }
}

MetricKPI.propTypes = {
  ...KPIProps,
};

const style = {
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  count: {
    fontSize: '12em',
    fontWeight: 'lighter',
    height: '1em',
    marginBottom: '-0.5em',
    color: '#ED213A',
  },
  legend: {
    fontSize: '2em',
    fontWeight: 'lighter',
    color: 'rgba(0,0,0,0.5)',
    marginBottom: '-1.5em',
  },
  variation: {
    position: 'absolute',
    right: '4em',
    top: '1em',
    fontSize: '1em',
    fontWeight: 700,
    color: 'rgba(0,0,0,0.5)',
  },
};
