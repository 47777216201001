import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Form, List, Button } from 'semantic-ui-react';
import produce from 'immer';
import moment from 'moment';
import {
  hasPermissionToCreate,
  hasPermissionToDelete,
  hasPermissionToEdit,
  hasPermissionToRead,
} from 'lib/permissions';

import { randomNonce } from '../utils';

import { formatWrDate } from 'app/util/dates';

export default class ModifyComments extends Component {
  state = {
    addCommentText: '',
    editedCommentIds: [],
    editedCommentTexts: {},
  };

  editComment = (commentId, text) => {
    this.setState(
      produce(draft => {
        draft.editedCommentIds.push(commentId);
        draft.editedCommentTexts[commentId] = text;
      }),
    );
  };

  updateCommentText = commentId => (e, { value }) => {
    this.setState(
      produce(draft => {
        draft.editedCommentTexts[commentId] = value;
      }),
    );
  };

  updateAddCommentText = (e, { value }) => {
    this.setState(
      produce(draft => {
        draft.addCommentText = value;
      }),
    );
  };

  createComment = async () => {
    const changeset = {
      Comment: [],
    };
    await Promise.all(
      this.props.data.requests.map(async (request, index) => {
        changeset.Comment.push({
          action: 'create',
          data: {
            id: -index,
            date: moment().toISOString(),
            nonce: await randomNonce(),
            text: this.state.addCommentText,
            RequestId: request.id,
            UserId: this.props.userId,
          },
        });
      }),
    );
    this.props.applyChangeset(changeset);
  };

  updateComment = commentId => {
    const changeset = {
      Comment: [
        {
          action: 'update',
          data: {
            id: commentId,
            text: this.state.editedCommentTexts[commentId],
          },
        },
      ],
    };
    this.props.applyChangeset(changeset);
    this.cancelComment(commentId);
  };

  deleteComment = commentId => {
    const changeset = {
      Comment: [
        {
          action: 'delete',
          data: {
            id: commentId,
          },
        },
      ],
    };
    this.props.applyChangeset(changeset);
  };

  cancelComment = commentId => {
    this.setState(
      produce(draft => {
        const index = this.state.editedCommentIds.indexOf(commentId);
        draft.editedCommentIds.splice(index, 1);
      }),
    );
  };

  render() {
    const hasComments = this.props.data.attachments.comments.length > 0;
    return (
      <Segment id="Comment" padded color="olive">
        <Header as="h2">Comments</Header>

        {hasPermissionToRead(this.props.permissions, 'Comment') && (
          <Fragment>
            {hasComments && (
              <List verticalAlign="middle" divided relaxed="very">
                {this.props.data.attachments.comments.map(comment => (
                  <List.Item key={comment.id}>
                    {this.state.editedCommentIds.includes(comment.id) ? (
                      <List.Content>
                        <Form>
                          <Form.TextArea
                            label="Update comment text"
                            placeholder="Comment"
                            value={this.state.editedCommentTexts[comment.id]}
                            onChange={this.updateCommentText(comment.id)}
                          />
                          <Button
                            content="Update comment"
                            primary
                            basic
                            icon="save"
                            onClick={() => this.updateComment(comment.id)}
                          />
                          <Button
                            content="Cancel"
                            basic
                            onClick={() => this.cancelComment(comment.id)}
                          />
                        </Form>
                      </List.Content>
                    ) : (
                      [
                        <List.Content floated="right" key="actions">
                          <Button
                            circular
                            basic
                            icon="edit"
                            disabled={
                              !hasPermissionToEdit(
                                this.props.permissions,
                                'Comment',
                              )
                            }
                            onClick={() =>
                              this.editComment(comment.id, comment.text)
                            }
                          />
                          <Button
                            basic
                            circular
                            color="red"
                            icon="remove"
                            disabled={
                              !hasPermissionToDelete(
                                this.props.permissions,
                                'Comment',
                              )
                            }
                            onClick={() => this.deleteComment(comment.id)}
                          />
                        </List.Content>,
                        <List.Content key="content">
                          <List.Header>{comment.text}</List.Header>
                          <List.Description>
                            {formatWrDate(comment.date)}
                          </List.Description>
                        </List.Content>,
                      ]
                    )}
                  </List.Item>
                ))}
              </List>
            )}
            <Form>
              <Form.TextArea
                label="New comment text"
                placeholder="Comment"
                value={this.state.addCommentText}
                onChange={this.updateAddCommentText}
              />
              <Form.Button
                basic
                icon="add"
                primary
                content="Add comment"
                disabled={
                  !hasPermissionToCreate(this.props.permissions, 'Comment') ||
                  this.state.addCommentText === ''
                }
                onClick={() => this.createComment()}
              />
            </Form>
          </Fragment>
        )}
      </Segment>
    );
  }
}

ModifyComments.propTypes = {
  data: PropTypes.object,
  applyChangeset: PropTypes.func,
  userId: PropTypes.number,
  permissions: PropTypes.array,
};
