import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';

export default ({ value, updateValue }) => {
  return (
    <Form>
      <TextArea
        placeholder="Value..."
        value={value || ''}
        onChange={(e, d) => updateValue(d.value)}
      />
    </Form>
  );
};
