import React from 'react';
import { Form, Input, Button } from 'semantic-ui-react';

export const ensureNumber = (value, isFloat) => {
  if (isFloat) return parseFloat(value);
  return parseInt(value, 10);
};

export const step = float => (float ? 0.1 : 1);

export default ({ value, updateValue, float }) => {
  return (
    <Form>
      <Input
        placeholder="Value..."
        value={value || ''}
        onChange={(e, d) => {
          let formattedValue = ensureNumber(d.value, float);
          if (isNaN(formattedValue)) formattedValue = value;
          return updateValue(formattedValue);
        }}
        action
      >
        <input />
        <Button
          icon="minus"
          onClick={() => {
            let formattedValue = ensureNumber(value, float);
            formattedValue -= step(float);
            if (formattedValue < step(float)) formattedValue = step(float);
            updateValue(formattedValue);
          }}
        />
        <Button
          icon="add"
          onClick={() => {
            const formattedValue = ensureNumber(value, float) + step(float);
            updateValue(formattedValue);
          }}
        />
      </Input>
    </Form>
  );
};
