import React from 'react';
import { Header, Modal, List } from 'semantic-ui-react';
import { formatWrDate } from 'app/util/dates';

const ModifyFormListModal = ({
  open,
  forms,
  attachExisting,
  onClose,
  addNew,
  customerInterop,
  requestInterops,
}) => (
  <Modal open={open} onClose={onClose} closeIcon>
    <Header icon="unlock" content={`Add ${customerInterop.description}`} />
    <Modal.Content scrolling>
      <List selection relaxed>
        <List.Item onClick={() => addNew()}>
          <List.Icon name="add" />
          <List.Content>
            <List.Header>Add new {customerInterop.description}</List.Header>
          </List.Content>
        </List.Item>
      </List>
      {Object.keys(forms).map((value, index) => {
        const valueData = forms[value];
        const hasEmptyRequests = valueData.requests.some(
          requestId =>
            requestInterops.find(
              rI => rI.RequestId.toString() === requestId.toString(),
            ) === undefined,
        );
        if (hasEmptyRequests)
          return (
            <div key={index}>
              <Header>Form for requests on {value}</Header>
              <List selection relaxed>
                {valueData.list.map((form, formIndex) => (
                  <List.Item
                    key={formIndex}
                    onClick={() => attachExisting(form, valueData.requests)}
                  >
                    <List.Icon name="file alternate outline" />
                    <List.Content>
                      <List.Header>{form.name}</List.Header>
                      <List.Description>
                        {formatWrDate(form.date)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </div>
          );
        return null;
      })}
    </Modal.Content>
  </Modal>
);

export default ModifyFormListModal;
