import crypto from 'crypto-browserify';

const iconReplacement = {
  water: 'tint',
  'right-hand': 'hand-point-right',
  switch: 'retweet',
  'down-big': 'arrow-down',
  target: 'bullseye',
  brush: 'paint-brush',
  pin: 'map-pin',
  troubleshoot: 'toolbox',
  open: 'folder open',
  closed: 'thumbs up',
  deleted: 'trash',
  locked: 'lock',
};

export const convertIcon = icon => {
  if (!iconReplacement[icon]) return icon;
  return iconReplacement[icon];
};

export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const randomNonce = async () =>
  new Promise(resolve => {
    crypto.randomBytes(30, (err, buffer) => resolve(buffer.toString('hex')));
  });

export const getColorFromStatus = status => {
  switch (status) {
    case 'closed':
      return 'green';
    case 'critical':
      return 'red';

    default:
      return 'orange';
  }
};

export const isStringNotEmpty = value =>
  value && value !== '' && value.replace(/\s/g, '').length > 0;

export const dedupe = a => [...new Set(a)];

export const groupBy = (items, field) =>
  items.reduce((finalResult, item) => {
    if (item) {
      let previousArray = finalResult[item[field]];
      if (previousArray === undefined) {
        previousArray = [];
      }
      return {
        ...finalResult,
        [item[field]]: [...previousArray, item],
      };
    }
    return finalResult;
  }, {});
