import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Header, Dimmer } from 'semantic-ui-react';
import moment from 'moment';

import connect from 'lib/reduxConnect';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import Aircraft from './components/aircraft';
import TEIEditModal from './components/teiEditModal';
import { formattedAircraftSelector, fixedSceneSelector } from './rfidSelectors';
import { randomNonce } from 'app/explorer/utils';

class RFIDContainer extends Component {
  state = {
    sortColumn: '',
    sortDir: 'down',
    changing: false,
    error: false,
  };

  componentDidMount() {
    this.props.actions.getRFIDData();
  }

  changeSort = sortColumn => {
    if (this.state.sortColumn === sortColumn)
      this.setState({ sortDir: this.state.sortDir === 'up' ? 'down' : 'up' });
    else this.setState({ sortColumn, sortDir: 'down' });
  };

  sortAircraft = () => {
    if (this.state.sortColumn === '') return this.props.aircraft;
    const modifier = this.state.sortDir === 'up' ? 1 : -1;
    return this.props.aircraft.sort((a, b) => {
      switch (this.state.sortColumn) {
        case 'Registration':
          if (a.registration > b.registration) return modifier * 1;
          if (a.registration < b.registration) return modifier * -1;
          return 0;
        case 'Aircraft type':
          if (a.type > b.type) return modifier * 1;
          if (a.type < b.type) return modifier * -1;
          return 0;
        case 'Open Requests':
          if (a.routineCount > b.routineCount) return modifier * 1;
          if (a.routineCount < b.routineCount) return modifier * -1;
          return 0;
        case 'Closed Requests':
          if (a.closedCount > b.closedCount) return modifier * 1;
          if (a.closedCount < b.closedCount) return modifier * -1;
          return 0;
        case 'Unassigned':
          if (a.unassignedCount > b.unassignedCount) return modifier * 1;
          if (a.unassignedCount < b.unassignedCount) return modifier * -1;
          return 0;
        case 'Expired':
          if (a.expiredCount > b.expiredCount) return modifier * 1;
          if (a.expiredCount < b.expiredCount) return modifier * -1;
          return 0;
        case 'Will expire':
          if (a.willExpireCount > b.willExpireCount) return modifier * 1;
          if (a.willExpireCount < b.willExpireCount) return modifier * -1;
          return 0;

        default:
          return 0;
      }
    });
  };

  onCloseEditedTag = () => {
    this.props.actions.setRFIDEditedTag(null);
  };

  onSaveEditedTag = async (records, pno, exp, ser) => {
    await this.setState({ changing: true });
    const changeset = { TagReading: [] };
    const date = moment().toISOString();
    changeset.TagReading.push({
      action: 'create',
      data: {
        id: -1,
        RfidTagId: this.props.editedTag.id,
        date,
        reading: JSON.stringify({
          date,
          records,
        }),
        rawMemory: '',
        pno,
        exp,
        ser,
        nonce: await randomNonce(),
        UserId: this.props.user.id,
      },
    });
    try {
      const syncId = await API.get(ENDPOINTS.sync.syncId, this.props.token, {
        device: 'webreporting',
      });
      if (syncId.syncid) {
        await API.post(ENDPOINTS.sync.userdata, this.props.token, {
          device: 'webreporting',
          syncid: syncId.syncid,
          json: { changeset },
        });
        await this.props.actions.getRFIDData();
        await this.setState({ changing: false, error: false });
      } else {
        await this.setState({ changing: false, error: true });
        return null;
      }
    } catch (err) {
      await this.setState({ changing: false, error: true });
    }
    this.onCloseEditedTag();
  };

  onSelectAircraft = aircraftId => {
    if (aircraftId === this.props.selectedAircraftId) {
      this.props.actions.resetRFIDAircraft();
    } else {
      this.props.actions.selectRFIDAircraft(aircraftId);
    }
  };

  renderAircraft(aircraft, index) {
    return (
      <Aircraft
        key={index}
        aircraft={aircraft}
        selectAircraft={this.onSelectAircraft}
        show={this.props.selectedAircraftId === aircraft.id}
        scene={this.props.scene}
        categories={this.props.categories}
        selectEquipment={this.props.actions.selectRFIDEquipment}
        selectedEquipment={this.props.selectedEquipment}
        selectCategory={this.props.actions.selectRFIDCategory}
        selectedCategory={this.props.selectedCategory}
        rawTags={this.props.rawTags[aircraft.id]}
        tagFilter={this.props.tagFilter}
        setTagFilter={this.props.actions.setRFIDTagFilter}
        setEditedTag={this.props.actions.setRFIDEditedTag}
      />
    );
  }

  renderColumnHeader(label) {
    return (
      <div
        style={{
          flex: 1,
          cursor: 'pointer',
          color: label === this.state.sortColumn ? '#df405d' : 'inherit',
        }}
        tabIndex={0}
        role="button"
        onClick={() => this.changeSort(label)}
      >
        {label}{' '}
        <Icon
          name={`sort${
            label === this.state.sortColumn ? ` ${this.state.sortDir}` : ''
          }`}
        />
      </div>
    );
  }

  render() {
    let sortedAircraft = this.sortAircraft();
    return (
      <div className="contentMargin scrollableContainer">
        {this.state.error && (
          <h1 style={{ color: 'red' }}>
            There was an error saving data, please try agin
          </h1>
        )}
        <div
          style={{
            flex: 1,
            padding: '1em',
            fontWeight: 'bold',
            display: 'flex',
          }}
        >
          {this.renderColumnHeader('Registration')}
          {this.renderColumnHeader('Aircraft type')}
          <div style={{ flex: 2, display: 'flex' }}>
            {this.renderColumnHeader('Open Requests')}
            {this.renderColumnHeader('Closed Requests')}
            {this.renderColumnHeader('Unassigned')}
            {this.renderColumnHeader('Expired')}
            {this.renderColumnHeader('Will expire')}
          </div>
        </div>
        {sortedAircraft.map(this.renderAircraft.bind(this))}
        <TEIEditModal
          tag={this.props.editedTag}
          onClose={this.onCloseEditedTag}
          onSave={this.onSaveEditedTag}
        />

        <Dimmer active={this.state.changing} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" loading color="pink" />
            Saving data
            <Header.Subheader>Please wait</Header.Subheader>
          </Header>
        </Dimmer>
      </div>
    );
  }
}
RFIDContainer.propTypes = {
  aircraft: PropTypes.array,
  selectedAircraftId: PropTypes.number,
  scene: PropTypes.array,
  categories: PropTypes.array,
  selectedEquipment: PropTypes.number,
  selectedCategory: PropTypes.string,
  rawTags: PropTypes.object,
  tagFilter: PropTypes.string,
  editedTag: PropTypes.object,
};
const mapStateToProps = state => ({
  aircraft: formattedAircraftSelector(state),
  selectedAircraftId: state.rfid.selectedAircraftId,
  scene: fixedSceneSelector(state),
  categories: state.rfid.equipmentCategories,
  selectedEquipment: state.rfid.selectedEquipmentId,
  selectedCategory: state.rfid.category,
  rawTags: state.rfid.tags,
  tagFilter: state.rfid.tagFilter,
  editedTag: state.rfid.editedTag,
});

export default connect(mapStateToProps)(RFIDContainer);
