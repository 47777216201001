import * as types from 'config/types';
import * as network from 'config/network';
import { API } from 'lib/rest';

export const getDevices = (page = false) => async (dispatch, getState) => {
  try {
    let currentPage = 1;
    let output;

    // 1.1 If the 'page' parameter is not defined, get it from state
    // ---

    if (!page) {
      if (getState().devices.devicePagination)
        currentPage = getState().devices.devicePagination;
    } else {
      currentPage = page;
    }

    // 1.2.1 Get the devices from the API
    // ---

    let devices = await API.get(
      network.ENDPOINTS.devices.devices,
      getState().auth.token,
      { page: currentPage },
    );

    // 1.2.2 Save before concat for output
    // ---

    if (devices) output = devices.length;

    // 1.3 If it's not the first page, concat the synchros with the previous one
    // ---

    if (currentPage > 1) {
      devices = getState().devices.devices.concat(devices);
    }

    // 1.4 Dispatch the action
    // ---

    dispatch({
      type: types.DEVICES_LOAD_DEVICES,
      payload: {
        devices,
        nextPage: currentPage + 1,
      },
    });

    return output;
  } catch (err) {
    console.error(err);
  }
};

export const getSynchro = (deviceId, page = false) => async (
  dispatch,
  getState,
) => {
  try {
    let currentPage = 1;
    let output;

    // 1.1 If the 'page' parameter is not defined, get it from state
    // ---

    if (!page) {
      if (getState().devices.synchroPagination)
        currentPage = getState().devices.synchroPagination;
    } else {
      currentPage = page;
    }

    // 1.2.1 Get the device synchros from the API
    // ---

    let synchros = await API.get(
      network.ENDPOINTS.devices.synchros,
      getState().auth.token,
      { page: currentPage, deviceId },
    );

    // 1.2.2 Save before concat for output
    // ---

    if (synchros) output = synchros.length;

    // 1.3 If it's not the first page, concat the synchros with the previous one
    // ---

    if (currentPage > 1) {
      synchros = getState().devices.synchros.concat(synchros);
    }

    // 1.4 Dispatch the action
    // ---

    dispatch({
      type: types.DEVICES_LOAD_SYNCHROS,
      payload: {
        synchros,
        nextPage: currentPage + 1,
      },
    });

    return output;
  } catch (err) {
    console.error(err);
  }
};

export const deleteDevice = deviceId => async (dispatch, getState) => {
  try {
    const deleteDevice = await API.post(
      network.ENDPOINTS.devices.delete,
      getState().auth.token,
      { deviceId },
    );

    if (deleteDevice.success) {
      dispatch({
        type: types.DEVICES_DELETE,
        payload: {
          deviceId,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};
