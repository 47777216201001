import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Card, Loader } from 'semantic-ui-react';
import produce from 'immer';
import connect from 'lib/reduxConnect';

import PieChart from 'app/creation/components/pieChart';
// import Lopa3dView from '../components/lopa3dView';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';
import { getAvatarURL } from 'app/util/avatar';

class Figures extends Component {
  state = {
    figures: null,
    view: null,
    subAssemblies: null,
    filter: null,
    error: false,
    loading: true,
  };

  componentDidMount() {
    if (
      !this.props.aircraftId ||
      !this.props.documentId ||
      !this.props.lopaId
    ) {
      return this.props.actions.navigate('/creations');
    }
    this.props.actions.searchFilter('');
    this.getData();
  }

  getData = async () => {
    try {
      const { aircraftId, documentId, lopaId } = this.props;
      const response = await API.post(
        ENDPOINTS.webreporting.figures,
        this.props.token,
        {
          aircraftId,
          documentId,
          lopaId,
        },
      );
      if (response && response.figures) {
        const subAssemblies = [];
        response.figures.forEach(figure => {
          if (!subAssemblies.includes(figure.subAssemblyName))
            subAssemblies.push(figure.subAssemblyName);
        });
        await this.setState(
          produce(draft => {
            draft.figures = response.figures;
            draft.subAssemblies = subAssemblies;
            draft.view = response.view;
            draft.loading = false;
          }),
        );
      } else {
        await this.setState(
          produce(draft => {
            draft.error = true;
            draft.loading = false;
          }),
        );
      }
    } catch (err) {
      await this.setState(
        produce(draft => {
          draft.error = true;
          draft.loading = false;
        }),
      );
    }
  };

  selectSubAssy = subAssy => {
    this.setState(
      produce(draft => {
        draft.filter = subAssy;
      }),
    );
  };

  renderSubAssyBtn(subAssy, index) {
    return (
      <Button
        key={index}
        size="small"
        basic={this.state.filter !== subAssy}
        className="subAssyBtn"
        onClick={() => this.selectSubAssy(subAssy)}
      >
        {subAssy || 'All'}
      </Button>
    );
  }

  renderFigure(figure, index) {
    if (this.state.filter && this.state.filter !== figure.subAssemblyName)
      return null;
    const avatar = getAvatarURL(
      figure.pdfFile.replace('.pdf', '.jpg'),
      {
        customer: true,
        path: 'figurejpeg/',
      },
      this.props.customer,
      this.props.token,
    );
    return (
      <Card key={index} link>
        {avatar && <Image src={avatar} className="cardImage" />}
        <Card.Content>
          <Card.Header style={{ wordWrap: 'break-word' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PieChart
                element={figure}
                radius={50}
                style={{ marginRight: 10 }}
              />
              <div style={{ flex: 1 }}>{figure.description}</div>
            </div>
          </Card.Header>
        </Card.Content>
      </Card>
    );
  }

  render() {
    if (this.state.loading)
      return <Loader active size="massive" content="Loading figures" />;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ paddingBottom: '0.5em' }}>
          {this.renderSubAssyBtn(null, 0)}
          {this.state.subAssemblies.map(this.renderSubAssyBtn.bind(this))}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              flex: 2,
              overflowX: 'hidden',
              overflowY: 'auto',
              padding: '0.2em',
            }}
          >
            {this.state.error && (
              <h1
                style={{
                  color: 'red',
                  fontWeight: 'lighter',
                  textAlign: 'center',
                }}
              >
                Could not get data, please reload your browser page
              </h1>
            )}
            <Card.Group centered>
              {this.state.figures.map(this.renderFigure.bind(this))}
            </Card.Group>
          </div>
        </div>
      </div>
    );
  }
}
//<Lopa3dView data={this.state.view} token={this.props.token} />

Figures.propTypes = {
  search: PropTypes.string,
  customer: PropTypes.object,
  aircraftId: PropTypes.number,
  documentId: PropTypes.number,
  lopaId: PropTypes.number,
};

const mapStateToProps = state => ({
  customer: state.auth.customer,
  search: state.ui.search,
  aircraftId: state.creation.aircraftId,
  documentId: state.creation.documentId,
  lopaId: state.creation.lopaId,
});

export default connect(mapStateToProps)(Figures);
