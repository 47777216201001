import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  customFields: [],
  items: [],
  cfsv: {},
  selectedCustomField: -1,
  selectedItem: -1,
  loading: true,
  currentItemPage: 1,
  totalItemPages: 1,
  itemsLoading: true,
  itemSearch: '',
  showUnsetItems: false,
  itemOrder: 'desc',
};

// very simple "cache" to prevent browser from blowing up
const maxCFSV = 50;
const cfsvKeyCacheOrder = [];

const getElement = (id, array) =>
  (id !== undefined && id !== -1 && array[id]) || null;

// keep cache limits
const handleCFSVCache = draft => {
  let currentIndex = -1;
  const selectedCustomField = getElement(
    draft.selectedCustomField,
    draft.customFields,
  );
  const selectedItem = getElement(draft.selectedItem, draft.items);
  const { tei } = selectedCustomField;
  if (selectedItem) {
    const { id } = selectedItem;
    currentIndex = `${id}_${tei}`;
  }
  while (Object.keys(draft.cfsv).length > maxCFSV) {
    const index = cfsvKeyCacheOrder[0];
    if (index != currentIndex) {
      delete draft.cfsv[index];
    } else {
      cfsvKeyCacheOrder.push(index);
    }
    cfsvKeyCacheOrder.splice(0, 1);
  }
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LINKED_DATA_SET_DATA:
        draft.customFields = action.payload.customFields;
        draft.loading = false;
        break;
      case types.LINKED_DATA_SELECT_CF:
        draft.selectedCustomField = action.payload.selectedCustomField;
        draft.itemsLoading = true;
        draft.currentItemPage = 1;
        draft.selectedItem = -1;
        break;
      case types.LINKED_DATA_SELECT_ITEM:
        draft.selectedItem = action.payload.selectedItem;
        break;
      case types.LINKED_DATA_SET_CFSV:
        draft.cfsv[action.payload.index] = action.payload.cfsv;
        cfsvKeyCacheOrder.push(action.payload.index);
        handleCFSVCache(draft);
        break;
      case types.LINKED_DATA_SET_CFSV_BATCH:
        draft.cfsv = { ...state.cfsv, ...action.payload.cfsv };
        cfsvKeyCacheOrder.push(...Object.keys(action.payload.cfsv));
        handleCFSVCache(draft);
        break;
      case types.LINKED_DATA_SET_ITEMS:
        draft.items = action.payload.items;
        draft.totalItemPages = action.payload.pages;
        draft.itemsLoading = false;
        break;
      case types.LINKED_DATA_SET_ITEM_PAGE:
        draft.currentItemPage = action.payload.page;
        draft.itemsLoading = true;
        break;
      case types.LINKED_DATA_SET_ITEM_LOADING:
        draft.itemsLoading = true;
        break;
      case types.LINKED_DATA_SEARCH_ITEM:
        draft.itemSearch = action.payload.search;
        break;
      case types.LINKED_DATA_TOGGLE_UNSET_ITEM:
        draft.showUnsetItems = !state.showUnsetItems;
        break;
      case types.LINKED_DATA_SET_ITEM_ORDER:
        draft.itemOrder = action.payload.order;
        break;
    }
  });
