// Shamelessly taken from SAM360 RN
export const hasPermissionToRead = (profilePermissions, entity, pk = '') =>
  checkPermission(profilePermissions, entity, '', pk, 'read');
export const hasPermissionToCreate = (profilePermissions, entity, pk = '') =>
  checkPermission(profilePermissions, entity, '', pk, 'create');
export const hasPermissionToDelete = (profilePermissions, entity, pk = '') =>
  checkPermission(profilePermissions, entity, '', pk, 'delete');
export const hasPermissionToEdit = (
  profilePermissions,
  entity,
  field,
  pk = '',
) => checkPermission(profilePermissions, entity, field, pk, 'edit');

export const checkPermission = (
  profilePermissions,
  entity,
  field,
  pk,
  action,
) => {
  const permissions = getPermissions(profilePermissions, entity, field, pk);

  return permissions[action];
};

export const getPermissions = (profilePermissions, entity, field, pk) => {
  // If no specific permission is set, allow everything
  const authorizeAll = { read: true, create: true, edit: true, delete: true };
  // Build the where clause to find the relevant permissions
  let where = { dbtable: entity };
  // These entities have permissions depending on a foreign key
  if (entity === 'CustomField' || entity === 'CustomerInterop') {
    where = { ...where, pk };
  }
  let matchingPermissions = [];
  if (entity === 'RequestInterop') {
    matchingPermissions = profilePermissions.filter(
      row => parseInt(row.pk, 10) === pk,
    );
  } else {
    matchingPermissions = profilePermissions.filter(row =>
      Object.keys(where).every(key => where[key] === row[key]),
    );
  }
  if (matchingPermissions.length === 0) {
    return authorizeAll;
  }

  const specificPermissions = matchingPermissions.filter(
    perm => perm.field === field,
  );
  if (specificPermissions.length > 0) {
    return {
      read: parseInt(specificPermissions[0].read, 10) === 1,
      create: parseInt(specificPermissions[0].create, 10) === 1,
      edit: parseInt(specificPermissions[0].edit, 10) === 1,
      delete: parseInt(specificPermissions[0].delete, 10) === 1,
    };
  } else {
    // There is no specific permission, try to find a generic permission for the entity
    const entityPermissions = matchingPermissions.filter(
      perm => perm.field === null,
    );
    if (entityPermissions.length > 0) {
      return {
        read: parseInt(entityPermissions[0].read, 10) === 1,
        create: parseInt(entityPermissions[0].create, 10) === 1,
        edit: parseInt(entityPermissions[0].edit, 10) === 1,
        delete: parseInt(entityPermissions[0].delete, 10) === 1,
      };
    }
  }
  return authorizeAll;
};
