import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import connect from 'lib/reduxConnect';
import { verifyFeature } from 'config/navigation';

import Devices from 'app/devices/containers/devicesContainer';
import Documents from 'app/documents/containers/documentsContainer';
import LinkedData from 'app/linkedData/linkedDataContainer';
import UserPermission from 'app/admin/containers/UserPermissionContainer';
import AdminHome from 'app/admin/containers/adminHome';

// SQL - don't forget profile features too
// INSERT INTO `Feature` (`type`, `name`, `applicationSection`)
// VALUES
// 	('widget', 'editUsers', 'admin'),
// 	('widget', 'deviceList', 'admin'),
// 	('widget', 'documentList', 'admin'),
// 	('widget', 'admin', 'admin'),
// 	('widget', 'linkedData', 'admin');

class AdminContainer extends Component {
  static getPathName = location => {
    if (location.pathname.includes('users')) return 'User permissions';
    if (location.pathname.includes('devices')) return 'Devices';
    if (location.pathname.includes('documents')) return 'Documents';
    if (location.pathname.includes('linked')) return 'Linked Data';
    return null;
  };
  static getActions(location, actions) {
    if (location.pathname !== '/admin') {
      return (
        <div className="pageActions">
          <Button
            className="pageAction"
            secondary
            onClick={() => actions.navigate('/admin')}
          >
            <Icon name="left chevron" />
            Back to administration home
          </Button>
        </div>
      );
    }
    return <Icon color="grey" name="paper plane outline" size="big" />;
  }

  render() {
    const prefix = this.props.match.url;
    return (
      <div className="scrollableContainer">
        <Route path={prefix} exact component={AdminHome} />
        {verifyFeature('editUsers', this.props.authUserFeatures) && (
          <Route path={`${prefix}/users`} component={UserPermission} />
        )}

        {verifyFeature('deviceList', this.props.authUserFeatures) && (
          <Route path={`${prefix}/devices`} component={Devices} />
        )}

        {verifyFeature('documentList', this.props.authUserFeatures) && (
          <Route path={`${prefix}/documents`} component={Documents} />
        )}

        {verifyFeature('linkedData', this.props.authUserFeatures) && (
          <Route path={`${prefix}/linked`} component={LinkedData} />
        )}
      </div>
    );
  }
}

AdminContainer.propTypes = {
  authUserFeatures: PropTypes.array,
};

const mapStateToProps = state => ({
  authUserFeatures: state.auth.profile.features,
});

export default connect(mapStateToProps)(AdminContainer);
