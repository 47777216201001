import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'semantic-ui-react';

import { URI } from 'config/network';

import ibeLogo from 'assets/logo-iberia.png';

export default class IbeLoginButton extends Component {
  loginWithIbe = () => {
    window.location.href = `${URI.auth.ibe}?udid=webreporting`;
  };

  render() {
    return (
      <Form.Button
        onClick={this.loginWithIbe}
        primary
        style={{
          background: 'red',
        }}
      >
        <div
          style={{
            width: 300,
            height: 80,
          }}
        >
          <Image
            src={ibeLogo}
            style={{
              margin: '0 auto',
              filter: 'brightness(0) invert(1)',
            }}
          />
        </div>
      </Form.Button>
    );
  }
}

IbeLoginButton.propTypes = {
  open: PropTypes.bool,
};
