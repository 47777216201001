import React, { Component } from 'react';
import { Input, Dropdown, Label } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
// This gift from the gods fixes the feature-but-actually-a-bug of iOS safari not implementing correctly the 100vh size (addressbar size messes things up)
import Div100vh from 'react-div-100vh';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';

import connect from 'lib/reduxConnect';
import Menu from 'app/menu/menuContainer';
import LoginModal from 'app/auth/components/loginModal';
import SearchSuggestions from 'app/util/searchSuggestions';
import {
  menuOptions,
  makeRouter,
  getHeaderInfo,
  verifyAccess,
} from './navigation';
import { API } from 'lib/rest';
import * as network from 'config/network';

class AISRouter extends Component {
  state = {
    search: '',
    tooSlim: false,
    showLogin: false,
    urlUserManual: '',
  };
  tokenCheckInterval;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    if (window.innerWidth < 1024) this.setState({ tooSlim: true });
    this.checkUserToken();
    this.tokenCheckInterval = setInterval(() => {
      this.checkUserToken();
    }, '60000');

    this.getUserManualUrl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) this.checkUserToken();
    if (
      this.props.search !== prevProps.search &&
      this.state.search !== this.props.search
    )
      this.setState({ search: this.props.search });
  }

  componentWillUnmount() {
    clearInterval(this.tokenCheckInterval);
  }

  checkUserToken = async () => {
    if (!this.props.token) return;
    try {
      const response = await API.get(
        network.ENDPOINTS.webreporting.check,
        this.props.token,
      );
      if ((!response || !response.success) && !this.state.showLogin)
        this.setState({ showLogin: true });
      if (response && response.success && this.state.showLogin)
        this.setState({ showLogin: false });
    } catch (err) {
      this.setState({ showLogin: true });
      if (process.env.NODE_ENV !== 'production')
        console.debug(`Token check ERROR : ${err.message}`);
    }
  };

  getUserManualUrl = async () => {
    if (!this.props.token) return;
    try {
      const response = await API.get(
        network.ENDPOINTS.webreporting.userManual,
        this.props.token,
      );
      this.setState({ urlUserManual: response.downloadLink });
    } catch (err) {
      console.debug(`get User Manual ERROR : ${err.message}`);
    }
  };

  handleResize = () => {
    if (window.innerWidth < 1024) this.setState({ tooSlim: true });
    else if (this.state.tooSlim) this.setState({ tooSlim: false });
  };

  logout = () => {
    this.props.actions.logout();
  };
  profile = () => {
    this.props.actions.navigate('/auth/profile');
  };
  messages = () => {
    this.props.actions.navigate('/auth/messages');
  };
  deployment = () => {
    this.props.actions.navigate('/deployment');
  };
  status = () => {
    this.props.actions.navigate('/status');
  };
  userManual = async () => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.href = this.state.urlUserManual;
    link.download = 'WRv2UserManualv1.0.pdf';
    link.click();
  };

  searchValue = (e, { value }) => {
    this.setState({ search: value });
    this.searchAction(value);
  };

  searchAction = debounce(value => {
    const headerInfo = getHeaderInfo(this.props.router.location.pathname);
    this.props.actions.searchFilter(
      value,
      !headerInfo.options || !headerInfo.options.searchLabel,
    );
  }, 500);

  render() {
    if (this.state.tooSlim)
      return (
        <div
          style={{
            fontSize: '1.5em',
            fontWeight: 'lighter',
            textAlign: 'center',
            padding: '2em',
          }}
        >
          Cannot display this application at this resolution, please open in a
          larger window
        </div>
      );

    if (
      !verifyAccess(
        this.props.router.location.pathname,
        (this.props.profile && this.props.profile.features) || {},
      )
    )
      return <Redirect to="/forbidden" />;

    const uiDisplayStyle = this.props.user === null ? { display: 'none' } : {};
    const username = this.props.user == null ? '' : this.props.user.name;
    const router = makeRouter();
    const headerInfo = getHeaderInfo(this.props.router.location.pathname);
    const headerActions =
      headerInfo.error || !headerInfo.component.getActions
        ? null
        : headerInfo.component.getActions(
            this.props.router.location,
            this.props.actions,
          );
    const pathName =
      headerInfo.error || !headerInfo.component.getPathName
        ? null
        : headerInfo.component.getPathName(this.props.router.location);

    if (headerInfo.options && headerInfo.options.fullscreen) {
      return (
        <Div100vh>
          <div className="mainContainer">{router}</div>
        </Div100vh>
      );
    }

    const messageCount =
      (this.props.messages &&
        this.props.messages.filter(m => m.hasBeenRead === 0).length) ||
      0;

    let searchBar = ``;
    if (headerInfo.options && headerInfo.options.searchLabel) {
      searchBar = (
        <Input
          fluid
          icon="search"
          iconPosition="left"
          placeholder={headerInfo.options && headerInfo.options.searchLabel}
          size="big"
          transparent
          inverted
          value={this.state.search || ''}
          onChange={this.searchValue}
          disabled={this.props.showTable}
        />
      );
    } else {
      searchBar = <div fluid size="big" transparent inverted />;
    }

    return (
      <Div100vh>
        <div className="mainContainer">
          <Menu style={uiDisplayStyle} options={menuOptions} />
          <div className="contentContainer">
            <div className="searchBar" style={uiDisplayStyle}>
              {searchBar}
              <div
                style={{
                  position: 'relative',
                }}
              >
                <Dropdown
                  closeOnBlur
                  pointing
                  text={username}
                  direction="left"
                  icon={null}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="user"
                      text="Profile"
                      onClick={this.profile}
                    />
                    <Dropdown.Item
                      icon={messageCount ? null : 'mail'}
                      content={
                        <span>
                          {(messageCount && (
                            <Label
                              color="red"
                              size="tiny"
                              style={{ marginRight: '0.5em' }}
                            >
                              {messageCount}
                            </Label>
                          )) ||
                            null}
                          Notifications
                        </span>
                      }
                      onClick={this.messages}
                    />
                    {this.props.profile &&
                    this.props.profile.appVersions &&
                    Object.keys(this.props.profile.appVersions).length > 0 ? (
                      <Dropdown.Item
                        icon="setting"
                        text="Deployment"
                        onClick={this.deployment}
                      />
                    ) : null}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      icon="book"
                      text="User manual"
                      onClick={this.userManual}
                    />
                    <Dropdown.Item
                      icon="server"
                      text="API status"
                      onClick={this.status}
                    />
                    <Dropdown.Item
                      icon="sign-out"
                      text="Logout"
                      onClick={this.logout}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                {(messageCount && (
                  <Label className="notificationLabel" color="red">
                    {messageCount}
                  </Label>
                )) ||
                  null}
              </div>
              <SearchSuggestions />
            </div>
            <div className="content">
              <div className="pageHeader" style={uiDisplayStyle}>
                <div className="pageTitle">
                  {headerInfo.name}
                  <small>{pathName === null ? '' : `> ${pathName}`}</small>
                </div>
                {headerActions}
              </div>
              {router}
            </div>
          </div>
          <LoginModal open={this.state.showLogin} />
        </div>
      </Div100vh>
    );
  }
}

AISRouter.propTypes = {
  search: PropTypes.string,
  profile: PropTypes.object,
  messages: PropTypes.array,
  showTable: PropTypes.bool,
};

const mapStateToProps = state => ({
  search: state.ui.search,
  profile: state.auth.profile,
  messages: state.auth.messages,
  showTable: state.explorer.showTable,
});

export default connect(
  mapStateToProps,
  null,
  true,
)(AISRouter);
