import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class NewKpisLatestPhotosModal extends Component {
  state = {
    index: 0,
  };

  componentDidMount() {
    this.setIndex();
  }
  componentDidUpdate(prevProps) {
    if (this.props.idxPhoto !== prevProps.idxPhoto) this.setIndex();
  }

  setIndex = () => {
    this.setState({ index: this.props.idxPhoto || 0 });
  };

  nextPhoto = () => {
    if (this.state.index < this.props.photos.length - 1) {
      this.setState({ index: this.state.index + 1 });
    }
  };

  previousPhoto = () => {
    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 });
    }
  };

  render() {
    if (this.props.photos.length <= 0) return <div> </div>;
    return (
      <Modal
        className={'newUserForm'}
        onClose={() => this.props.closeModal()}
        open={this.props.isOpen}
        size="large"
        closeIcon
      >
        <Modal.Header>{this.props.modalTitle}</Modal.Header>
        <Modal.Content>
          <div class="ui card" style={{ width: '100%' }}>
            <div class="content">
              <div class="right floated meta">
                <button
                  class={`ui labeled icon button  ${
                    this.state.index > 0 ? '' : 'disabled'
                  }`}
                  onClick={() => this.previousPhoto()}
                >
                  <i class="left arrow icon" />
                  Previous
                </button>
                <button class="ui button">
                  {this.state.index + 1} / {this.props.photos.length}
                </button>
                <button
                  class={`ui right labeled icon button ${
                    this.state.index < this.props.photos.length - 1
                      ? ''
                      : 'disabled'
                  }`}
                  onClick={() => this.nextPhoto()}
                >
                  <i class="right arrow icon" />
                  Next
                </button>
              </div>
              <div class="ui label" style={{ 'font-size': '1.3rem' }}>
                <i class="calendar icon"> </i>
                {this.props.photos[this.state.index].dateRequest}
              </div>
              <div class="ui label" style={{ 'font-size': '1.3rem' }}>
                <i class="plane icon"> </i>
                {this.props.photos[this.state.index].aircraft}
              </div>
              <div class="ui label" style={{ 'font-size': '1.3rem' }}>
                <i class="sitemap icon"> </i>
                {this.props.photos[this.state.index].location}
              </div>
            </div>
            <div class="image">
              <img src={this.props.photos[this.state.index].url} alt="" />
            </div>
            <div class="content">
              <div class="ui label">
                <i class="calendar icon"> </i>
                {this.props.photos[this.state.index].dateRequest}
              </div>
              <div class="ui label">
                <i class="plane icon"> </i>
                {this.props.photos[this.state.index].aircraft}
              </div>
              <div class="ui label">
                <i class="sitemap icon"> </i>
                {this.props.photos[this.state.index].location}
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

NewKpisLatestPhotosModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  closeModal: PropTypes.func,
  photos: PropTypes.array,
  idxPhoto: PropTypes.number,
};

// dateRequest : '2023-03-18 01:59:46',
//           aircraft : "F-GZCN",
//           location : "01D-H",
