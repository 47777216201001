import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import connect from 'lib/reduxConnect';

import { GridLayoutItemType } from './components/types';
import KPIGrid from './components/kpiGrid';
import Timeline, { TimelineOptions } from './containers/timeline';
import KPIOptions from './containers/KPIOptions';

class Home extends Component {
  static getActions(location, actions) {
    if (location.pathname.includes('timeline')) {
      return <TimelineOptions />;
    }
    return <KPIOptions />;
  }

  static getPathName = location => {
    if (location.pathname.includes('timeline')) return 'Timeline';
    return 'Overview';
  };

  componentDidMount() {
    if (!this.props.dataLoaded) this.getData();
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.editKPILayout &&
      this.props.editKPILayout !== prevProps.editKPILayout
    )
      this.getData();
  }

  getData = () => {
    this.props.actions.loadKPIs(this.props.kpis);
  };

  render() {
    const prefix = this.props.match.url;
    const kpis = this.props.kpis.filter(
      kpi =>
        kpi.i !== `maintenanceActions` &&
        kpi.i !== `requestCount` &&
        kpi.i !== `defectiveSubassyOverTime` &&
        kpi.i !== `latestrequest`,
    );
    return (
      <div className="contentMargin scrollableContainer">
        <Route path={`${prefix}/timeline`} render={() => <Timeline />} />
        <Route
          exact
          path={prefix}
          render={() => (
            <KPIGrid
              kpis={kpis || []}
              kpiData={this.props.kpiData || {}}
              isDraggable={this.props.editKPILayout || false}
              saveKPILayout={this.props.actions.saveKPILayout}
              saveKPI={this.props.actions.saveKPI}
              loadKPI={this.props.actions.getKPIData}
              deleteKPI={this.props.actions.deleteKPI}
              addKPI={this.props.actions.addKPI}
              navigate={this.props.actions.navigate}
              token={this.props.token}
              customer={this.props.customer}
              rawData={this.props.rawData}
              interval={this.props.interval}
            />
          )}
        />
      </div>
    );
  }
}

Home.propTypes = {
  kpis: PropTypes.arrayOf(GridLayoutItemType),
  kpiData: PropTypes.object,
  editKPILayout: PropTypes.bool,
  dataLoaded: PropTypes.bool,
  customer: PropTypes.object,
  rawData: PropTypes.object,
  interval: PropTypes.object,
};

const mapStateToProps = state => ({
  kpis: state.home.kpis,
  kpiData: state.home.kpiData,
  editKPILayout: state.home.editKPILayout,
  dataLoaded: state.home.dataLoaded,
  customer: state.auth.customer,
  rawData: state.home.rawData,
  interval: state.home.kpiInterval,
});

export default connect(mapStateToProps)(Home);
