import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  profiles: [],
  titles: [],
  users: [],
  userPagination: 1,
  showUserPagination: true,
  userSynchros: [],
  synchroPagination: 1,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.ADMIN_LOAD_USERS:
        draft.users = action.payload.users;
        draft.userPagination = action.payload.nextPage;
        break;
      case types.ADMIN_LOAD_PROFILES:
        draft.profiles = action.payload.profiles;
        break;
      case types.ADMIN_USERS_SHOW_PAGINATION:
        draft.showUserPagination = action.payload.show;
        break;
      case types.ADMIN_LOAD_USER_SYNCHROS:
        draft.userSynchros = action.payload.synchros;
        draft.synchroPagination = action.payload.nextPage;
        break;
      case types.ADMIN_LOAD_TITLES:
        draft.titles = action.payload.titles;
        break;
    }
  });
