import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message, Modal } from 'semantic-ui-react';
import Login from 'app/auth/containers/authLogin';

export default class LoginModal extends Component {
  render() {
    return (
      <Modal
        open={this.props.open}
        dimmer="blurring"
        closeOnDimmerClick={false}
        closeOnEscape={false}
        basic
        size="small"
      >
        <Modal.Content scrolling>
          <Message
            error
            icon="warning sign"
            content="Your session has expired"
          />
          <Login noNavigation />
        </Modal.Content>
      </Modal>
    );
  }
}

LoginModal.propTypes = {
  open: PropTypes.bool,
};
