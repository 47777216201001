import * as types from 'config/types';

export * from 'app/home/actions/kpi';

export const editKPILayout = () => ({
  type: types.HOME_EDIT_KPI,
});

export const saveKPILayout = kpis => ({
  type: types.HOME_SAVE_KPI_LAYOUT,
  payload: { kpis },
});

export const saveKPI = (id, config) => ({
  type: types.HOME_SAVE_KPI,
  payload: { id, config },
});

export const addKPI = kpi => ({
  type: types.HOME_ADD_KPI,
  payload: { kpi },
});

export const toggleTimelineSyncs = kpi => ({
  type: types.HOME_TIMELINE_SYNC,
});
