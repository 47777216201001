import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from 'app/home/homeContainer';
import Explorer from 'app/explorer/explorerContainer';
import Maintenance from 'app/maintenance/maintenanceContainer';
import Creation from 'app/creation/creationContainer';
import ReportRenderer from 'app/report/reportContainer';
import ReportList from 'app/report/containers/reportList';
import Auth from 'app/auth/authContainer';
import Deployment from 'app/deployment/containers/deploymentContainer';
import Status from 'app/status/statusContainer';
import Detail from 'app/detail/detailContainer';
import Admin from 'app/admin/adminContainer';
import RFID from 'app/rfid/rfidContainer';

import NotFound from 'app/util/notFound';
import Forbidden from 'app/util/forbidden';

export const menuOptions = [
  {
    name: 'Dashboard',
    src: '/dashboard',
    image: 'menuIcon chart pie icon',
    applicationSection: 'dashboard',
  },
  {
    name: 'Data Explorer',
    src: '/explorer',
    image: 'menuIcon database icon',
    applicationSection: 'requestedQuantityBy',
  },
  {
    name: 'Maintenance',
    src: '/maintenance',
    image: 'menuIcon chart bar icon',
    applicationSection: 'Maintenance',
  },
  {
    name: 'Reports',
    src: '/reports',
    image: 'menuIcon file alternate icon',
    applicationSection: 'reports',
  },
  // {
  //   name: 'Creations',
  //   src: '/creations',
  //   image: creationImg,
  //   applicationSection: 'createRequest',
  // },
  {
    name: 'RFID',
    src: '/rfid',
    image: 'menuIcon rss icon',
    applicationSection: 'rfidActivity',
  },
  {
    name: 'Administration',
    src: '/admin',
    image: 'menuIcon cogs icon',
    applicationSection: 'admin',
  },
];

const makeNavInfo = (name, path, component, options = { exact: false }) => ({
  name,
  path,
  component,
  options,
});
const navigationInfo = [
  makeNavInfo('Dashboard', '/dashboard', Home),
  makeNavInfo('Data explorer', '/explorer', Explorer, {
    searchLabel: 'Search in current page',
  }),
  makeNavInfo('Maintenance', '/maintenance', Maintenance, {
    searchLabel: 'Search in current page',
  }),
  makeNavInfo('Reports', '/reports', ReportList, {
    searchLabel: 'Search in current page',
  }),
  makeNavInfo('Report', '/report', ReportRenderer, { fullscreen: true }),
  makeNavInfo('Creations', '/creations', Creation),
  makeNavInfo('RFID', '/rfid', RFID),
  makeNavInfo('Account', '/auth', Auth),
  makeNavInfo('Api status', '/status', Status),
  makeNavInfo('Deployment', '/deployment', Deployment),
  makeNavInfo('Request detail', '/detail', Detail),
  makeNavInfo('Administration', '/admin', Admin),
];

// refer to https://reacttraining.com/react-router/web/api/Switch
export const makeRouter = () => {
  const routes = navigationInfo.map(nav => (
    <Route
      key={nav.path}
      exact={nav.options.exact}
      path={nav.path}
      component={nav.component}
    />
  ));
  routes.push(
    <Route
      key="root"
      exact
      path="/"
      render={() => <Redirect to="/dashboard" />}
    />,
    <Route key="401" exact path="/forbidden" component={Forbidden} />,
    <Route key="404" component={NotFound} />,
  );
  return <Switch>{routes}</Switch>;
};

export const getHeaderInfo = path =>
  navigationInfo.find(nav => path.includes(nav.path)) ||
  (path.includes('forbidden') && {
    name: 'Forbidden',
    error: true,
  }) || {
    name: 'Page not found',
    error: true,
  };

const allowedPaths = ['auth', 'status', 'forbidden'];

export const verifyAccess = (pathname, profileFeatures) => {
  if (pathname === '/' || allowedPaths.some(path => pathname.includes(path)))
    return true;
  const section = menuOptions.find(option => pathname.includes(option.src));
  if (!section) return true;
  if (
    profileFeatures &&
    !profileFeatures.some(
      feature => feature.name === section.applicationSection,
    )
  )
    return false;
  return true;
};

export const verifyFeature = (featureName, profileFeatures) => {
  if (
    profileFeatures &&
    profileFeatures.some(feature => feature.name === featureName)
  ) {
    return true;
  }
  return false;
};
