import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GridLayoutItemType } from './types';
import { Icon, Statistic, Popup, Loader, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import findByType from 'lib/findByType';

// definitions for subcomponents
const KPIHeader = () => null;
const Variation = () => null;
const Data = () => null;
const Count = () => null;

class KPI extends Component {
  onDelete = () => {
    this.props.deleteKPI(this.props.kpi.i);
  };

  renderHeader() {
    const { children } = this.props;
    const header = findByType(children, KPIHeader);
    if (!header) {
      return <div className="headerData">KPI</div>;
    }
    if (
      header.props.children === undefined ||
      header.props.children.length === 0
    )
      return <div className="headerData">{header.props.title || 'KPI'}</div>;
    return (
      <div className="headerData">
        {header.props.title || 'KPI'}
        {!this.props.isDraggable && (
          <Popup
            trigger={<Icon link name="settings" />}
            hideOnScroll={false}
            content={
              <div
                className="popupMenu kpiHeaderOverflowFix"
                style={header.props.style}
              >
                {header.props.children}
              </div>
            }
            on="click"
            position="bottom right"
            onClose={header.props.onClose}
          />
        )}
      </div>
    );
  }
  renderVariation() {
    const { children } = this.props;
    const variation = findByType(children, Variation);
    if (
      !variation ||
      variation.props.value === undefined ||
      variation.props.diff === undefined
    ) {
      return null;
    }
    let diffPrefix = '';
    let variationDirection = 'down';
    let variationColor = variation.props.inverted === true ? 'green' : 'red';
    if (variation.props.diff >= 0 || variation.props.diff === 'up') {
      diffPrefix = '+';
      variationDirection = 'up';
      variationColor = variation.props.inverted === true ? 'red' : 'green';
    }
    let showVariationAmout = true;
    if (typeof variation.props.diff !== 'number') showVariationAmout = false;

    return (
      <div key="variation" className="kpiVariation">
        <Statistic size="small" value={variation.props.value} color="pink" />
        <span className="kpiCountUnit">{variation.props.unit || ''}</span>
        <Statistic size="tiny" color={variationColor}>
          <Statistic.Value>
            {variation.props.hardDiff !== 0 ? (
              <Icon
                color={variationColor}
                name={`arrow ${variationDirection}`}
              />
            ) : (
              <FontAwesomeIcon color="gray" icon="equals" />
            )}
            {(showVariationAmout &&
              ` ${diffPrefix}${variation.props.diff.toString()}%`) ||
              null}
          </Statistic.Value>
        </Statistic>
      </div>
    );
  }

  renderCount() {
    const { children } = this.props;
    const count = findByType(children, Count);
    if (!count || count.props.value === undefined) {
      return null;
    }

    return (
      <div key="variation" className="kpiVariation">
        <Statistic size="small" value={count.props.value} color="pink" />
        <span className="kpiCountUnit">{count.props.unit || ''}</span>
      </div>
    );
  }

  renderData() {
    const { children } = this.props;
    const content = findByType(children, Data);
    if (!content || !content.props.children) {
      return null;
    }

    return (
      <div key="content" className="kpiContent">
        {content.props.children}
      </div>
    );
  }

  renderContent() {
    if (this.props.isDraggable)
      return (
        <div className="kpiError">
          <Icon name="move" color="blue" size="huge" />
          <Button
            className="kpiDelete"
            circular
            icon="trash alternate outline"
            color="red"
            content="Remove from dashboard"
            onClick={() => this.onDelete()}
          />
        </div>
      );
    if (
      this.props.error === true ||
      this.props.error == null ||
      typeof this.props.error === 'object'
    )
      return (
        <div className="kpiError">
          <Icon name="warning sign" color="red" size="big" />
          <div className="kpiErrorMessage">
            {this.props.error.message || 'Could not get data'}
          </div>
          {this.props.error.reload && (
            <Button primary onClick={() => this.props.getData()}>
              Reload
            </Button>
          )}
        </div>
      );

    if (this.props.loading) {
      return <Loader active size="massive" />;
    }
    if (this.props.noData) {
      return (
        <div className="kpiError" style={{ color: 'gray' }}>
          <Icon name="calendar times outline" size="big" />
          <div className="kpiErrorMessage">
            No data available for this configuration
          </div>
        </div>
      );
    }

    return [this.renderVariation(), this.renderCount(), this.renderData()];
  }

  render() {
    return (
      <div
        className={`kpiContainer  ${this.props.extraClasses} ${
          this.props.isDraggable ? 'kpiContainerEdited' : ''
        }`}
        tabIndex={0}
        role="button"
        onClick={() =>
          (!this.props.isDraggable &&
            this.props.onClick &&
            this.props.onClick(this.props.kpi)) ||
          null
        }
        style={{ cursor: (this.props.onClick && 'pointer') || 'inherit' }}
      >
        <div className="kpiHeader">
          {this.props.isDraggable ? <Icon name="move" color="blue" /> : null}
          {this.renderHeader()}
        </div>
        {this.renderContent()}
      </div>
    );
  }
}
KPI.propTypes = {
  kpi: GridLayoutItemType,
  children: PropTypes.node.isRequired,
  isDraggable: PropTypes.bool,
  loading: PropTypes.bool,
  noData: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      reload: PropTypes.bool,
    }),
  ]),
  getData: PropTypes.func,
  extraClasses: PropTypes.string,
  deleteKPI: PropTypes.func,
  onClick: PropTypes.func,
};

KPI.Header = KPIHeader;
KPI.Variation = Variation;
KPI.Count = Count;
KPI.Data = Data;

export default KPI;
