import React, { PureComponent } from 'react';
import { List, Icon, Header, Form, Dropdown, Button } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';
import { PieChart } from 'app/home/components/chart';

const targets = [
  {
    title: 'Fleet',
    type: 'all',
  },
  {
    title: 'Aircraft',
    type: 'registration',
  },
];

class RFIDTagAssign extends PureComponent {
  state = {
    loading: true,
    error: false,
    data: [],
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data instanceof Array) {
        this.setState({
          loading: false,
          data: this.props.data,
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  onSettingsClose = async () => {
    await this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
    this.forceUpdate();
  };

  setTargetType = async targetType => {
    await this.props.saveKPI(this.props.kpi.i, { targetType });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  setTarget = async target => {
    await this.props.saveKPI(this.props.kpi.i, { target });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  getHeader() {
    const currentTarget = targets.find(
      target => target.type === this.props.kpi.payload.targetType,
    );
    const title =
      (currentTarget &&
        currentTarget.type !== 'all' &&
        (this.props.kpi.payload.target || 'Please configure')) ||
      'Fleet';
    return (
      <KPI.Header
        title={`RFID Tags Assignation - ${title}`}
        onClose={this.onSettingsClose}
      >
        <List relaxed divided selection>
          <Header>
            RFID Tags Assignation
            <Header.Subheader>
              Choose which kind of data you wish to display in this block
            </Header.Subheader>
          </Header>
          {targets.map((filter, index) => (
            <List.Item
              key={index}
              onClick={() => this.setTargetType(filter.type)}
            >
              <List.Content floated="right">
                <Icon name="chevron right" />
              </List.Content>
              <List.Content>
                <List.Header>{filter.title}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
        {currentTarget && currentTarget.type === 'registration' && (
          <>
            <Header key="aircraftHeader">
              Compared aircraft
              <Header.Subheader>
                Select an aircraft registration in the dropdown to get its tag
                assignation data.
              </Header.Subheader>
            </Header>
            <Form key="aircraftSettings">
              <Form.Field>
                <Dropdown
                  placeholder="Selected aircraft"
                  fluid
                  search
                  selection
                  options={
                    this.props.rawData.aircraft &&
                    this.props.rawData.aircraft.map(aircraft => {
                      const requestCount =
                        parseFloat(aircraft.routineCount) +
                        parseFloat(aircraft.criticalCount);
                      return {
                        key: aircraft.registration,
                        value: aircraft.registration,
                        text: `${aircraft.registration} - ${aircraft.type}`,
                        description: `${requestCount}`,
                      };
                    })
                  }
                  value={this.props.kpi.payload.target}
                  onChange={(e, { value }) => this.setTarget(value)}
                />
              </Form.Field>
            </Form>
          </>
        )}
      </KPI.Header>
    );
  }

  render() {
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.state.loading}
        error={this.state.error}
        getData={this.getData}
        noData={this.state.data.length === 0}
        deleteKPI={this.props.deleteKPI}
      >
        {this.getHeader()}
        <KPI.Count
          value={this.state.data.reduce((acc, d) => acc + d.y, 0)}
          unit={'total tags'}
        />
        <KPI.Data>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              height: '100%',
            }}
          >
            <div style={{ flex: 1 }}>
              <PieChart data={this.state.data} />
            </div>
            <Button
              size="mini"
              primary
              onClick={() => this.props.actions.navigate('/rfid')}
            >
              Go to RFID Manager
            </Button>
          </div>
        </KPI.Data>
      </KPI>
    );
  }
}

RFIDTagAssign.propTypes = {
  ...KPIProps,
};

export default connect()(RFIDTagAssign);
