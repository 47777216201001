import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import ExplorerMain from './containers/explorer';
import Modify from './containers/modify';
import ExplorerActions from './containers/ExplorerActions';
import ModifyRequestsActions from './containers/ModifyRequestsActions';
import StandaloneTable from './containers/standaloneTable';

import connect from 'lib/reduxConnect';

class Explorer extends Component {
  static getActions(location, actions) {
    if (location.pathname.includes('modify')) return <ModifyRequestsActions />;
    if (location.pathname.includes('table')) return null;
    return (
      <ExplorerActions
        onChange={actions.setCardSort}
        onBreadcrumbChange={actions.setCurrentBreadcrumb}
        deleteBreadcrumb={actions.deleteBreadcrumb}
      />
    );
  }
  static getPathName = location => {
    if (location.pathname.includes('modify')) return 'Modify request';
    if (location.pathname.includes('table')) return 'Request table';
    return null;
  };

  render() {
    const prefix = this.props.match.url;
    return (
      <div style={{ display: 'flex', flex: 1, overflowY: 'hidden' }}>
        <Route exact path={`${prefix}/modify`} render={() => <Modify />} />
        <Route
          path={`${prefix}/modify/:requestId([0-9,]+)`}
          render={() => <Modify urlId />}
        />
        <Route path={`${prefix}/table`} render={() => <StandaloneTable />} />
        <Route exact path={prefix} render={() => <ExplorerMain />} />
      </div>
    );
  }
}

export default connect()(Explorer);
