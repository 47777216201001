import React from 'react';
import { VictoryPie } from 'victory';

const pieChartRadius = 60;

export default ({ element, radius, style }) => {
  const criticalCount = parseInt(element.criticalCount, 10);
  const routineCount = parseInt(element.routineCount, 10);
  const data = [
    { x: 'critical', y: criticalCount },
    { x: 'routine', y: routineCount },
  ];
  if (criticalCount === 0 && routineCount === 0)
    data.push({
      x: 'nothing',
      y: 1,
    });
  return (
    <div className="aisPieChart" style={style}>
      <svg width={radius || pieChartRadius} height={radius || pieChartRadius}>
        <VictoryPie
          standalone={false}
          width={radius || pieChartRadius}
          height={radius || pieChartRadius}
          innerRadius={(radius || pieChartRadius) / 3.0}
          padding={0}
          colorScale={['red', 'orange', 'lightgray']}
          data={data}
        />
      </svg>
      <div className="aisPieChartLabel">
        <div>{criticalCount + routineCount}</div>
      </div>
    </div>
  );
};
