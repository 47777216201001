import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { formatWrDate } from 'app/util/dates';

export default class SynchroModal extends Component {
  render() {
    let synchros = [];
    if (this.props.synchrosFromState) {
      synchros = this.props.synchrosFromState.map((synchro, index) => (
        <div
          key={index}
          style={{
            backgroundColor: '#eee',
            color: '#666',
            padding: 10,
            margin: 10,
            fontSize: 14,
          }}
        >
          <div>
            <b>revision</b>: {synchro.RevisionId}
          </div>
          <div>
            <b>by</b>: {synchro.user}
          </div>
          <div>
            <b>date</b>: {formatWrDate(synchro.date)}
          </div>
        </div>
      ));

      return (
        <Modal
          onClose={() => this.props.closeModalCallback()}
          open={this.props.isOpen}
          closeIcon
          size="tiny"
        >
          <Modal.Header>{this.props.modalTitle}</Modal.Header>
          <Modal.Content>
            <Modal.Description>{synchros}</Modal.Description>
            {this.props.showNextPageButton && (
              <Modal.Description style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => this.props.loadNextPageCallback()}
                  color="grey"
                >
                  load next
                </Button>
              </Modal.Description>
            )}
          </Modal.Content>
        </Modal>
      );
    }
  }
}

SynchroModal.propTypes = {
  modalTitle: PropTypes.string,
  synchrosFromState: PropTypes.array,
  isOpen: PropTypes.bool,
  showNextPageButton: PropTypes.bool,
  closeModalCallback: PropTypes.func,
  loadNextPageCallback: PropTypes.func,
};
