import * as types from 'config/types';
import * as network from 'config/network';
import { API } from 'lib/rest';

export const loadDocuments = () => async (dispatch, getState) => {
  try {
    const documents = await API.get(
      network.ENDPOINTS.documents.documentList,
      getState().auth.token,
    );

    dispatch({
      type: types.DOCUMENTS_LOAD_DOCUMENTS,
      payload: {
        documents: documents.data,
      },
    });

    return documents.data;
  } catch (err) {
    console.error(err);
  }
};

export const loadHistory = documentId => async (dispatch, getState) => {
  try {
    const history = await API.get(
      network.ENDPOINTS.documents.history,
      getState().auth.token,
      { documentId },
    );

    dispatch({
      type: types.DOCUMENTS_LOAD_HISTORY,
      payload: {
        history: history.data,
      },
    });

    return history.data;
  } catch (err) {
    console.error(err);
  }
};

export const requestRevisions = payload => async (dispatch, getState) => {
  try {
    const payloadAsArray = Object.keys(payload).map(objectKey => {
      const document = payload[objectKey];
      return {
        id: objectKey,
        revisionTarget: document.revisionTarget,
        comment: document.comment,
      };
    });

    return await API.post(
      network.ENDPOINTS.documents.requestRevisions,
      getState().auth.token,
      { payload: payloadAsArray },
    );
  } catch (err) {
    console.error(err);
  }
};
