import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';
import IncrementInput from 'app/util/incrementInput';

const options = [
  { key: 'dp-0', text: 'Hour(s)', value: 'hour' },
  { key: 'dp-1', text: 'Day(s)', value: 'day' },
  { key: 'dp-2', text: 'Week(s)', value: 'week' },
  { key: 'dp-3', text: 'Month(s)', value: 'month' },
  { key: 'dp-4', text: 'Years(s)', value: 'year' },
];

const optionsPerimeter = [
  { key: 'dp-0', text: '24 hours', value: 'h-24' },
  { key: 'dp-1', text: '48 hours', value: 'h-48' },
  { key: 'dp-2', text: '7 days', value: 'd-7' },
  { key: 'dp-3', text: '14 days', value: 'd-14' },
  { key: 'dp-4', text: 'month', value: 'm-1' },
  { key: 'dp-5', text: '2 months', value: 'm-2' },
  { key: 'dp-6', text: '3 months', value: 'm-3' },
  { key: 'dp-7', text: '6 months', value: 'm-6' },
  { key: 'dp-8', text: '12 months', value: 'm-12' },
];

const showOptionsDate = false;
class KPIOptions extends Component {
  handleChange = field => (e, { value }) => {
    this.props.actions.updateInterval({ [field]: value });
  };
  handleAmountChange = intervalAmount => {
    this.props.actions.updateInterval({ intervalAmount });
  };

  render() {
    // Dirty fix, wait for Laurent Valentin to prettify this with real default value
    let interval = this.props.interval;
    if (interval.intervalType === 'month') {
      interval = {
        pickerType: 0,
        intervalType: 'm-12',
        intervalAmount: 6,
      };
    }
    return (
      <div className="pageActions">
        <>
          <div>Data from the last </div>
          <Form>
            <Form.Group inline>
              <Form.Dropdown
                fluid
                selection
                options={optionsPerimeter}
                value={interval.intervalType || 'm-12'}
                onChange={this.handleChange('intervalType')}
              />
            </Form.Group>
          </Form>
        </>

        {showOptionsDate && (
          <>
            <Form>
              <Form.Group inline>
                <Form.Dropdown
                  fluid
                  selection
                  options={optionsPerimeter}
                  value={this.props.interval.intervalType}
                  onChange={this.handleChange('intervalType')}
                />
                <Form.Field>
                  <IncrementInput
                    value={parseInt(this.props.interval.intervalAmount, 10)}
                    min={1}
                    max={52}
                    onChange={this.handleAmountChange}
                  />
                </Form.Field>
                <Form.Dropdown
                  fluid
                  selection
                  options={options}
                  value={this.props.interval.intervalType}
                  onChange={this.handleChange('intervalType')}
                />
              </Form.Group>
            </Form>
          </>
        )}
        <Button
          content={this.props.editKPILayout ? 'Save changes' : 'Layout'}
          icon={this.props.editKPILayout ? 'save' : 'edit'}
          basic={!this.props.editKPILayout}
          primary={this.props.editKPILayout}
          onClick={() => this.props.actions.editKPILayout()}
        />
      </div>
    );
  }
}
KPIOptions.propTypes = {
  interval: PropTypes.object,
  editKPILayout: PropTypes.bool,
};

const mapStateToProps = state => ({
  interval: state.home.kpiInterval,
  editKPILayout: state.home.editKPILayout,
});

export default connect(mapStateToProps)(KPIOptions);
