import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Grid, Image, List, Label } from 'semantic-ui-react';

import connect from 'lib/reduxConnect';

import configImg from 'assets/icons/timeline/config.png';
import defaultImg from 'assets/icons/timeline/default.png';

const getIcon = column => {
  switch (column) {
    case 'Aircraft':
      return configImg;

    default:
      return defaultImg;
  }
};
const getRequestLabelColor = suggestion => {
  if (suggestion.status === 'closed') return 'green';
  if (suggestion.notificationFlag === '1') return 'red';
  return 'orange';
};

class SearchSuggestions extends Component {
  navigateResult = suggestion => {
    if (suggestion.resultType === 'Request') {
      this.props.actions.viewRequest(suggestion.id);
      this.props.actions.searchFilter('');
      return;
    }
    this.props.actions.setExplorerSuggestion(suggestion);
  };

  renderSuggestion(suggestion, index) {
    let extraContent;
    if (suggestion.resultType === 'Request') {
      extraContent = (
        <Label
          size="mini"
          circular
          empty
          color={getRequestLabelColor(suggestion)}
        />
      );
    }
    return (
      <List.Item key={index} onClick={() => this.navigateResult(suggestion)}>
        <List.Icon name="chevron right" verticalAlign="middle" />
        <List.Content>
          <List.Header as="a">
            {extraContent} {suggestion.title}
          </List.Header>
          <List.Description as="a">{suggestion.subtitle}</List.Description>
        </List.Content>
      </List.Item>
    );
  }

  renderColumn(column) {
    if (!this.props.searchResults[column]) return null;
    const results = this.props.searchResults[column];
    let content;
    if (results.length === 0) {
      content = (
        <p
          style={{
            fontWeight: 'lighter',
            fontSize: '2em',
            textAlign: 'center',
          }}
        >
          No results
        </p>
      );
    } else {
      content = (
        <List divided selection>
          {results.map(this.renderSuggestion.bind(this))}
        </List>
      );
    }
    return (
      <Grid.Column>
        <Header>
          <Image size="mini" circular src={getIcon(column)} /> {column}
        </Header>
        {content}
      </Grid.Column>
    );
  }

  render() {
    if (
      !this.props.searchResults ||
      Object.keys(this.props.searchResults).length === 0
    )
      return null;
    return (
      <div className="searchBarSuggestions">
        <Grid className="result" columns={3} stretched divided>
          {this.renderColumn('Aircraft')}
          {this.renderColumn('Item')}
          {this.renderColumn('Request')}
        </Grid>
      </div>
    );
  }
}

SearchSuggestions.propTypes = {
  searchResults: PropTypes.object,
};

const mapStateToProps = state => ({
  searchResults: state.ui.searchResults,
});

export default connect(mapStateToProps)(SearchSuggestions);
