import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Tab } from 'semantic-ui-react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import IncrementInput from 'app/util/incrementInput';

const options = [
  { key: 'dp-0', text: 'Hour(s)', value: 'hour' },
  { key: 'dp-1', text: 'Day(s)', value: 'day' },
  { key: 'dp-2', text: 'Week(s)', value: 'week' },
  { key: 'dp-3', text: 'Month(s)', value: 'month' },
  { key: 'dp-4', text: 'Years(s)', value: 'year' },
];

export const buildDates = (interval, subtract = 0) => {
  const today = new Date();
  // latest
  if (interval.pickerType === 0) {
    let startDate;
    let endDate;
    endDate = moment(today)
      .startOf(interval.intervalType)
      .subtract(subtract, interval.intervalType)
      .toDate();
    startDate = moment(endDate)
      .subtract(interval.intervalAmount, interval.intervalType)
      .startOf(interval.intervalType)
      .subtract(subtract, interval.intervalType)
      .toDate();
    return [startDate, endDate];
  }
  // range
  if (interval.pickerType === 1) {
    const range = interval.range || {
      from: today,
      to: moment(today)
        .endOf('day')
        .toDate(),
    };
    return [range.from, range.to];
  }
  return null;
};

const formatRange = range => {
  if (!range) {
    const today = new Date();
    return {
      from: today,
      to: moment(today)
        .endOf('day')
        .toDate(),
    };
  }
  if (typeof range.from === 'object' && typeof range.to === 'object')
    return range;
  return { from: moment(range.from).toDate(), to: moment(range.to).toDate() };
};

export const formatStepLabel = intervalType => {
  switch (intervalType) {
    case 'hour':
      return 'HH:mm';
    case 'day':
      return 'Do MMM';
    case 'week':
      return 'Do MMM';
    case 'month':
      return 'MMMM YY';

    default:
      return 'YYYY';
  }
};

export const formatArbitraryDaysInterval = days => {
  if (days <= 1) return 'hour';
  if (days <= 2) return 'day';
  if (days <= 30) return 'week';
  if (days <= 365) return 'month';
  return 'year';
};

export const getTimeframeFromIntervalType = intervalType => {
  switch (intervalType) {
    case 'hour':
      return '1h';
    case 'day':
      return '1d';
    case 'week':
      return '1w';
    case 'month':
      return '1M';

    default:
      return '1y';
  }
};

export default class Datepicker extends Component {
  state = {
    today: new Date(),
  };

  static getDerivedStateFromProps(props, state) {
    return {
      today: moment(new Date())
        .set('year', props.interval.currentYear || new Date().getFullYear())
        .toDate(),
    };
  }

  handleChange = field => (e, { value }) => {
    this.props.updateKPI({ [field]: value });
  };
  handleAmountChange = intervalAmount => {
    this.props.updateKPI({ intervalAmount });
  };
  handleTabChange = (e, { activeIndex }) =>
    this.props.updateKPI({ pickerType: activeIndex });

  handleYearChange = (e, { value }) => {
    this.props.updateKPI({ currentYear: value });
    this.setState({
      today: moment(this.state.today)
        .set('year', value)
        .toDate(),
    });
  };
  handleDayClick = day => {
    const range = DateUtils.addDayToRange(
      day,
      formatRange(this.props.interval.range),
    );
    if (range.from !== null && range.to !== null) {
      const from = moment(range.from);
      const to = moment(range.to);
      const days = Math.abs(moment.duration(from.diff(to)).asDays());
      this.props.updateKPI({
        range,
        intervalType: formatArbitraryDaysInterval(days),
      });
    }
  };

  getLastTenYears = () => {
    const years = [];
    const today = new Date();
    for (let index = 0; index < 10; index++) {
      const year = moment(today)
        .subtract(index, 'year')
        .get('year');
      years.push({ key: year, text: year, value: year });
    }
    return years;
  };

  renderLatest() {
    return (
      <Form>
        <Form.Field>
          <p>Data from the last : </p>
        </Form.Field>
        <Form.Group>
          <Form.Field width={8}>
            <IncrementInput
              value={parseInt(this.props.interval.intervalAmount, 10)}
              min={1}
              max={10}
              onChange={this.handleAmountChange}
            />
          </Form.Field>
          <Form.Dropdown
            fluid
            selection
            width={8}
            options={options}
            value={this.props.interval.intervalType}
            onChange={this.handleChange('intervalType')}
          />
        </Form.Group>
      </Form>
    );
  }

  renderSpecific() {
    return (
      <Form>
        <Form.Dropdown
          label="Start year"
          fluid
          selection
          options={this.getLastTenYears()}
          value={this.state.today.getFullYear()}
          onChange={this.handleYearChange}
        />
        <Form.Field>
          <div
            style={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '0.92307692em',
              fontWeight: 700,
            }}
          >
            Range
          </div>
          <div
            style={{
              color: 'rgba(0,0,0,.6)',
              fontWeight: 400,
              fontSize: '1rem',
            }}
          >
            Double tap on a day to set it as start
          </div>
          <DayPicker
            showOutsideDays
            month={this.state.today}
            modifiers={{
              selected:
                formatRange(this.props.interval.range) || this.state.today,
            }}
            onDayClick={this.handleDayClick}
          />
        </Form.Field>
      </Form>
    );
  }

  renderPanes() {
    return [
      { menuItem: 'Latest data', render: this.renderLatest.bind(this) },
      { menuItem: 'Specific dates', render: this.renderSpecific.bind(this) },
    ];
  }

  render() {
    return (
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={this.renderPanes()}
        activeIndex={this.props.interval.pickerType}
        onTabChange={this.handleTabChange}
      />
    );
  }
}

Datepicker.propTypes = {
  updateKPI: PropTypes.func,
  interval: PropTypes.shape({
    currentYear: PropTypes.number,
    pickerType: PropTypes.number.isRequired,
    intervalType: PropTypes.string,
    intervalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    range: PropTypes.shape({
      from: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number,
      ]),
      to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number,
      ]),
    }),
  }).isRequired,
};
