import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'semantic-ui-react';

export default class IncrementInput extends Component {
  onClick = modifier => () => {
    if (
      this.props.value + modifier >= this.props.min &&
      this.props.value + modifier <= this.props.max
    )
      this.props.onChange(this.props.value + modifier);
  };

  render() {
    return (
      <Input value={this.props.value} action>
        <input style={{ width: '4em' }} />
        <Button
          icon="minus"
          size="mini"
          disabled={this.props.value === this.props.min}
          onClick={this.onClick(-this.props.step)}
        />
        <Button
          icon="add"
          size="mini"
          disabled={this.props.value === this.props.max}
          onClick={this.onClick(this.props.step)}
        />
      </Input>
    );
  }
}

IncrementInput.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

IncrementInput.defaultProps = {
  step: 1,
  min: 0,
  max: 10,
};
