// "we're selling a generic product for all our customers"
// the company, before accepting to do 110% custom work
import React, { useState } from 'react';
import { Table, Button, Input, Header } from 'semantic-ui-react';
import moment from 'moment';
import { makeXLSX, getRealAFPartNumber } from 'app/rfid/rfidUtils';

export default ({ loading, data, print }) => {
  if (loading) return <div>Loading...</div>;
  if (!data) return <div>No data available</div>;
  const [dayCount, setDayCount] = useState(30);
  const [customDayCount, setCustomDayCount] = useState(120);
  const [selectedPN, setSelectedPN] = useState('');
  const pnList = Object.keys(data);
  let expiredTags = [];
  pnList.forEach((pn, index) => {
    data[pn].forEach(tag => {
      if (!tag.momentExp) return;
      const tagExp = moment(tag.momentExp);
      if (
        moment()
          .add(dayCount, 'days')
          .isAfter(tagExp)
      ) {
        expiredTags.push({ ...tag, groupingPN: pn });
      }
    }, 0);
  });
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, marginBottom: '1em', alignSelf: 'center' }}>
        {!print && (
          <Button.Group>
            <Button active={dayCount === 30} onClick={() => setDayCount(30)}>
              30 days
            </Button>
            <Button active={dayCount === 60} onClick={() => setDayCount(60)}>
              60 days
            </Button>
            <Button active={dayCount === 90} onClick={() => setDayCount(90)}>
              90 days
            </Button>
            <Button
              active={dayCount === customDayCount}
              onClick={() => setDayCount(customDayCount)}
            >
              <Input
                type="numeric"
                value={customDayCount}
                size="mini"
                onChange={(e, { value }) => {
                  setCustomDayCount(value);
                  setDayCount(value);
                }}
                style={{ width: '5em' }}
              />{' '}
              days
            </Button>
            <Button
              style={{ marginLeft: '0.25em' }}
              content="Export tags to Excel (.xlsx)"
              icon="save"
              primary
              onClick={() => makeXLSX(expiredTags)}
            />
          </Button.Group>
        )}
        {print && <Header>Over {dayCount} days</Header>}
      </div>
      <div style={{ flex: 1 }}>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Equipment P/N</Table.HeaderCell>
              <Table.HeaderCell>Expired quantity</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!print && (
              <Table.Row
                onClick={() => setSelectedPN('')}
                style={{ cursor: 'pointer' }}
                active={selectedPN === ''}
              >
                <Table.Cell>All</Table.Cell>
                <Table.Cell>{expiredTags.length}</Table.Cell>
              </Table.Row>
            )}
            {pnList.map((pn, index) => {
              const pnTags = expiredTags.filter(tag => tag.reading.pno === pn);
              return (
                <Table.Row
                  key={index}
                  onClick={() => setSelectedPN(pn)}
                  style={{ cursor: 'pointer' }}
                  active={!print && selectedPN === pn}
                >
                  <Table.Cell>{pn}</Table.Cell>
                  <Table.Cell>{pnTags.length}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      <div style={{ flex: 1 }}>
        {expiredTags
          .filter(tag => {
            if (print || selectedPN === '') return true;
            return selectedPN === tag.groupingPN;
          })
          .map((tag, index) => {
            const exp = moment(tag.momentExp);
            let ser = 'N/C';
            let pno = 'N/C';
            if (tag.reading) {
              ser = tag.reading.ser;
              if (tag.epc.toLowerCase().startsWith('1d')) {
                pno = getRealAFPartNumber(tag.reading.pno);
              } else {
                pno = tag.reading.pno;
              }
            }
            return (
              <div
                key={index}
                style={{ display: 'flex', margin: '0.5em', padding: '0.5em' }}
              >
                <div style={styles.tagColumn}>
                  <div style={styles.tagTitle}>Aircraft</div>
                  <div style={styles.tagValue}>{tag.aircraft}</div>
                </div>
                <div style={styles.tagColumn}>
                  <div style={styles.tagTitle}>Position</div>
                  <div style={styles.tagValue}>{tag.position}</div>
                </div>
                <div style={styles.tagColumn}>
                  <div style={styles.tagTitle}>P/N</div>
                  <div style={styles.tagValue}>{pno}</div>
                </div>
                <div style={styles.tagColumn}>
                  <div style={styles.tagTitle}>S/N</div>
                  <div style={styles.tagValue}>{ser}</div>
                </div>
                <div style={styles.tagColumn}>
                  <div style={styles.tagTitle}>EXP</div>
                  <div style={styles.tagValue}>
                    {(exp && exp.format('MMM Do Y')) || 'NC'}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
const styles = {
  tagColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  tagValue: {
    fontWeigth: 'lighter',
    fontSize: '1.25em',
  },
  tagTitle: {
    color: 'gray',
  },
};
