import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// relative to src/
// check docs/relative-imports.md for more info
import 'semantic/dist/semantic.min.css';
import 'index.css';
import Router from 'config/router';
import { store, history } from 'config/store';
import { unregister } from 'registerServiceWorker';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
unregister();
