import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import * as actions from 'config/actions';

export default (
  mapStateToProps = null,
  mapDispatchToProps = null,
  refreshOnNav = false,
) => Comp => {
  const wrapper = class Wrapper extends Component {
    static getActions(location, actions) {
      return Comp.getActions ? (
        Comp.getActions(location, actions)
      ) : (
        <Icon color="grey" name="paper plane outline" size="big" />
      );
    }
    static getPathName(location) {
      return Comp.getPathName ? Comp.getPathName(location) : null;
    }

    render() {
      if (
        this.props.location &&
        !this.props.location.pathname.includes('/auth/login') &&
        this.props.user === null
      ) {
        if (process.env.NODE_ENV !== 'production')
          console.debug('Not connected, redirecting to login');
        return <Redirect to="/auth/login" />;
      }
      if (process.env.NODE_ENV !== 'production')
        console.debug('Rendering', Comp.name);
      return <Comp {...this.props} />;
    }
  };
  wrapper.propTypes = {
    router: PropTypes.object,
    user: PropTypes.object,
    ...Comp.propTypes,
  };

  const mapDispatchToPropsAuto = dispatch => {
    const externalDispatchToProps = mapDispatchToProps
      ? mapDispatchToProps(dispatch)
      : {};
    return {
      dispatch,
      actions: bindActionCreators(actions, dispatch),
      ...externalDispatchToProps,
    };
  };
  const mapStateToPropsAuto = state => {
    const externalStateToProps = mapStateToProps ? mapStateToProps(state) : {};
    return {
      router: refreshOnNav ? state.router : {},
      user: state.auth.user,
      token: state.auth.token,
      ...externalStateToProps,
    };
  };
  return withRouter(
    connect(
      mapStateToPropsAuto,
      mapDispatchToPropsAuto,
    )(wrapper),
  );
};
