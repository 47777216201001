import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Header, Icon, Segment } from 'semantic-ui-react';

import connect from 'lib/reduxConnect';
import { API } from 'lib/rest';
import * as network from 'config/network';

class Status extends Component {
  state = {
    status: 'API is available',
    statusSubtext: 'Nothing to report',
    statusIcon: 'check',
    statusColor: 'green',
  };

  componentDidMount() {
    API.get(network.ENDPOINTS.status).catch(err => {
      this.setState({
        status: 'API is not available',
        statusSubtext: 'Cannot contact API',
        statusIcon: 'warning sign',
        statusColor: 'red',
      });
    });
  }

  render() {
    return (
      <div className="contentMargin">
        <Segment>
          <Header as="h4">
            <Icon name={this.state.statusIcon} color={this.state.statusColor} />
            <Header.Content>
              {this.state.status}
              <Header.Subheader>{this.state.statusSubtext}</Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
      </div>
    );
  }
}

export default connect()(Status);
