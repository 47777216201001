import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Feed, Table, Divider } from 'semantic-ui-react';
import { isStringNotEmpty } from 'app/explorer/utils';

export default class History extends Component {
  renderEvent = (event, index) => {
    return (
      <Feed.Event key={index}>
        <Feed.Label icon={event.icon} />
        <Feed.Content>
          <Feed.Date>{event.date}</Feed.Date>
          <Feed.Summary>
            <Feed.User>{event.user}</Feed.User> {event.text}
          </Feed.Summary>
          {event.values && (
            <Feed.Extra>
              <Table unstackable compact definition>
                <Table.Body>
                  {event.values.map((entry, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{entry.field}</Table.Cell>
                      {(isStringNotEmpty(entry.value) && (
                        <Table.Cell width="12">{entry.value}</Table.Cell>
                      )) ||
                        null}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Feed.Extra>
          )}
          <Divider hidden />
        </Feed.Content>
      </Feed.Event>
    );
  };
  render() {
    return (
      <Segment>
        <Feed size="large">
          {this.props.history.map(this.renderEvent.bind(this))}
        </Feed>
      </Segment>
    );
  }
}

History.propTypes = {
  history: PropTypes.array,
};
