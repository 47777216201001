import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  minimizedMenu: false,
  search: '',
  searchResults: {},
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.UI_MINIMIZE:
        draft.minimizedMenu = !state.minimizedMenu;
        break;
      case types.UI_SEARCH:
        draft.search = action.payload.search;
        break;
      case types.UI_SET_SEARCH_RESULTS:
        draft.searchResults = action.payload.results;
        break;
    }
  });
