import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import connect from 'lib/reduxConnect';

import Login from './containers/authLogin';
import Profile from './containers/authProfile';
import Messages from './containers/messages';

class Auth extends Component {
  static getPathName = location => {
    if (location.pathname.includes('messages')) return 'Notifications';
    if (location.pathname.includes('profile')) return 'Profile';
    return null;
  };
  render() {
    const prefix = this.props.match.url;
    return (
      <div className="scrollableContainer">
        <Route path={`${prefix}/login`} component={Login} />
        <Route path={`${prefix}/profile/:tab?`} component={Profile} />
        <Route path={`${prefix}/messages`} component={Messages} />
        <Route
          exact
          path={prefix}
          render={() => (
            <Redirect
              to={this.props.user === null ? `${prefix}/login` : '/dashboard'}
            />
          )}
        />
      </div>
    );
  }
}

export default connect()(Auth);
