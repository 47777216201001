/* eslint-disable */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

let dataKpi = {
  series: [
    {
      data: [],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
  },
};

export default class RequestByFamilyEquipementsBar extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(dataKpi));
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    dataKpi.series = this.props.dataExtraKpi.series.values;
    dataKpi.options.xaxis.categories = this.props.dataExtraKpi.categories.values;
    this.setState(dataKpi);
  };

  render() {
    return (
      <>
        <div className="kpiContent">
          {/* <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            width="500"
          /> */}
          <div class="card">
            <div class="content">
              <div class="header">
                <h4>{this.props.dataExtraKpi.extra.title}</h4>
              </div>
              <div class="meta">{this.props.dataExtraKpi.extra.subtitle}</div>
              <div class="description">
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
