/* eslint-disable */
import React, { useState } from 'react';
import Chart from 'react-apexcharts';

let pageNumber = 0;

export const ChartBarSelector = ({
  sizeHeight,
  options,
  onClickCategorie,
  onClickSize,
  defaultPage = 0,
}) => {
  const setEventClick = page => {
    if (page >= options.data.length) page = 0;
    let data = options.data[page];
    data.options.chart['events'] = {
      dataPointSelection: function(event, chartContext, config) {
        clickCategorie(config.dataPointIndex);
      },
      xAxisLabelClick: function(event, chartContext, config) {
        clickCategorie(config.labelIndex);
      },
    };
    return data;
  };
  const [tableExpanded, setTableExpanded] = useState(false);
  const [pagination, setPagination] = useState(defaultPage);
  const [chartOptions, setChartOptions] = useState(setEventClick(defaultPage));

  const clickNext = () => {
    if (pagination >= options.data.length - 1) return;
    pageNumber = pagination + 1;
    setPagination(pagination + 1);
    setChartOptions(options.data[pagination + 1]);
  };

  const clickPrevious = () => {
    if (pagination <= 0) return;
    pageNumber = pagination - 1;
    setPagination(pagination - 1);
    setChartOptions(options.data[pagination - 1]);
  };

  const clickFirst = () => {
    pageNumber = 0;
    setPagination(0);
    setChartOptions(options.data[0]);
  };

  const clickLast = () => {
    pageNumber = options.data.length - 1;
    setPagination(options.data.length - 1);
    setChartOptions(options.data[options.data.length - 1]);
  };

  const clickExpand = (expand = true) => {
    onClickSize(expand);
    setTableExpanded(expand);
  };

  const clickCategorie = dataPointIndex => {
    const ids =
      options.data[pageNumber].options.xaxis.categoriesIds[dataPointIndex];
    onClickCategorie(ids, pageNumber);
  };

  const paginator = () => {
    if (options.data.length <= 1) return null;
    return (
      <div class="extra content ">
        <div
          class="ui buttons right floated"
          style={{ marginTop: '-25px', paddingRight: '20px' }}
        >
          <button class="ui icon button" onClick={clickFirst}>
            <i class="angle double left icon" />
          </button>
          <button class="ui icon button" onClick={clickPrevious}>
            <i class="left chevron icon" />
          </button>
          <button class="ui button">
            {pagination + 1 + '/' + options.data.length}
          </button>
          <button class="ui icon button" onClick={clickNext}>
            <i class="right chevron icon" />
          </button>
          <button class="ui right icon button" onClick={clickLast}>
            <i class="angle double right icon" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="kpiContent w-100" style={{ marginTop: '-25px' }}>
      <div class="card">
        <div class="content">
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type="bar"
            height={sizeHeight}
          />
        </div>
        {paginator()}
      </div>
    </div>
  );
};
