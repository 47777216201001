import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  editKPILayout: true,
  dataLoaded: false,
  kpis: [
    // {
    //   i: 'requestCount',
    //   x: 1,
    //   y: 0,
    //   w: 2,
    //   h: 1,
    //   payload: {
    //     type: 'requestCount',
    //     focus: 'created',
    //     dataAmount: 6,
    //     interval: {
    //       pickerType: 0,
    //       intervalType: 'month',
    //       intervalAmount: 6,
    //     },
    //   },
    //  },
    // {
    //   i: 'maintenanceActions',
    //   x: 0,
    //   y: 0,
    //   w: 1,
    //   h: 1,
    //   payload: {
    //     type: 'maintenanceActions',
    //   },
    // },
    // {
    //   i: 'defectiveSubassyOverTime',
    //   x: 0,
    //   y: 1,
    //   w: 2,
    //   h: 1,
    //   payload: {
    //     type: 'defectiveSubassyOverTime',
    //     focus: 'specific',
    //     dataAmount: 6,
    //     interval: {
    //       pickerType: 0,
    //       intervalType: 'month',
    //       intervalAmount: 6,
    //     },
    //     target: null,
    //     targetType: 'Aircraftregistration',
    //     avgBy: 'Aircrafttype',
    //   },
    // },
    {
      i: 'timeline',
      x: 0,
      y: 0,
      w: 4,
      h: 2,
      payload: {
        type: 'timeline',
      },
    },
    // {
    //   i: 'metric',
    //   x: 0,
    //   y: 2,
    //   w: 1,
    //   h: 1,
    //   payload: {
    //     type: 'metric',
    //   },
    // },
    // {
    //   i: 'defectiveSubassy',
    //   x: 1,
    //   y: 2,
    //   w: 1,
    //   h: 1,
    //   payload: {
    //     type: 'defectiveSubassy',
    //     focus: 'open',
    //     dataAmount: 4,
    //   },
    // },
  ],
  kpiData: {},
  timelineSyncs: false,
  timelineInterval: { moment: 'days', es: 'd' },
  timelineSyncData: {
    rawData: [],
    formattedData: [],
    offset: 0,
  },
  kpiInterval: {
    pickerType: 0,
    intervalType: 'month',
    intervalAmount: 6,
  },
  rawData: {
    aircraft: [],
  },
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.AUTH_RESET:
        draft.kpis = initialState.kpis;
        break;
      case types.HOME_EDIT_KPI:
        draft.editKPILayout = !state.editKPILayout;
        break;
      case types.HOME_SAVE_KPI_LAYOUT:
        draft.kpis = action.payload.kpis;
        break;
      case types.HOME_SAVE_KPI: {
        const kpi = draft.kpis.find(k => k.i === action.payload.id);
        const kpiIndex = state.kpis.indexOf(kpi);
        kpi.payload = { ...kpi.payload, ...action.payload.config };
        draft.kpis[kpiIndex] = kpi;
        break;
      }
      case types.HOME_ADD_KPI:
        if (!draft.kpis) draft.kpis = [];
        draft.kpis.push(action.payload.kpi);
        break;
      case types.HOME_SET_KPI_DATA:
        if (!draft.kpiData) draft.kpiData = {};
        draft.kpiData[action.payload.id] = action.payload.data || {
          error: action.payload.error,
        };
        draft.dataLoaded = true;
        break;
      case types.HOME_DELETE_KPI: {
        if (!draft.kpis) break;
        const kpi = draft.kpis.find(k => k.i === action.payload.id);
        if (kpi) {
          const index = draft.kpis.indexOf(kpi);
          if (index !== -1) {
            draft.kpis.splice(index, 1);
            delete draft.kpiData[action.payload.id];
          }
        }
        break;
      }
      case types.HOME_TIMELINE_SYNC:
        draft.timelineSyncs = !draft.timelineSyncs;
        break;
      case types.HOME_TIMELINE_UPDATE_BLOCK:
        if (draft.kpiData) {
          if (
            draft.kpiData.timeline &&
            draft.kpiData.timeline instanceof Array &&
            draft.kpiData.timeline.length > action.payload.index
          ) {
            draft.kpiData.timeline[action.payload.index].events =
              action.payload.events;
            draft.kpiData.timeline[action.payload.index].loading = false;
          }
        }
        break;
      case types.HOME_TIMELINE_SET_INTERVAL:
        draft.timelineInterval = action.payload.interval;
        break;
      case types.HOME_TIMELINE_SET_SYNC_DATA:
        draft.timelineSyncData = action.payload.timelineSyncData;
        break;
      case types.HOME_TIMELINE_SET_AIRCRAFT:
        if (!draft.rawData) draft.rawData = {};
        draft.rawData.aircraft = action.payload.aircraft.sort((a, b) => {
          const aCount =
            parseFloat(a.routineCount) + parseFloat(a.criticalCount);
          const bCount =
            parseFloat(b.routineCount) + parseFloat(b.criticalCount);
          if (aCount > bCount) return -1;
          if (aCount < bCount) return 1;
          return 0;
        });
        break;
      case types.HOME_SET_INTERVAL:
        draft.kpiInterval = action.payload.interval;
        break;
    }
  });
