import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'lib/reduxConnect';
import { API } from 'lib/rest';
import { hasPermissionToRead } from 'lib/permissions';
import * as network from 'config/network';
import produce from 'immer';
import { Segment, Loader, Dimmer, Header, Icon } from 'semantic-ui-react';

import Hero from '../components/modifyHero';
import Corrections from '../components/modifyCorrections';
import Comments from '../components/modifyComments';
import Interop from '../components/modifyInterop';

class Modify extends Component {
  state = {
    data: null,
    loading: true,
    changing: false,
    generalError: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if (this.props.urlId && this.state.loading) this.getData();
  }

  getData = async () => {
    if (this.props.urlId) {
      const requestIds = this.props.match.params.requestId.split(',');
      if (
        !this.props.requestIds ||
        this.props.requestIds.length === 0 ||
        requestIds.some(rId => !this.props.requestIds.includes(rId))
      ) {
        this.props.actions.modifyRequests(requestIds, true);
        return;
      }
    }
    if (!this.props.requestIds || this.props.requestIds.length === 0) {
      return this.props.actions.navigate('/explorer');
    }
    try {
      const response = await API.post(
        network.ENDPOINTS.webreporting.attachments,
        this.props.token,
        { requestIds: this.props.requestIds },
      );
      await this.setState(
        produce(draft => {
          draft.data = response;
          draft.loading = false;
          draft.changing = false;
        }),
      );
    } catch (err) {
      await this.setState(
        produce(draft => {
          draft.generalError = true;
          draft.loading = false;
        }),
      );
    }
  };

  applyChangeset = async changeset => {
    try {
      this.props.actions.markDirty(true);
      await this.setState(
        produce(draft => {
          draft.changing = true;
        }),
      );
      const syncId = await API.get(
        network.ENDPOINTS.sync.syncId,
        this.props.token,
        { device: 'webreporting' },
      );
      if (syncId.syncid) {
        const upload = await API.post(
          network.ENDPOINTS.sync.userdata,
          this.props.token,
          {
            device: 'webreporting',
            syncid: syncId.syncid,
            json: { changeset },
          },
        );
        await this.getData();
        return upload;
      } else {
        await this.setState(
          produce(draft => {
            draft.changing = false;
            draft.generalError = true;
          }),
        );
        return null;
      }
    } catch (err) {
      await this.setState(
        produce(draft => {
          draft.generalError = true;
          draft.loading = false;
        }),
      );
    }
  };

  render() {
    if (this.state.loading)
      return <Loader active size="massive" content="Loading data" />;
    if (this.state.generalError)
      return (
        <div className="slimContentMargin scrollableContainer">
          <h1
            style={{
              color: 'red',
              fontWeight: 'lighter',
              textAlign: 'center',
            }}
          >
            There was a network error, please reload your browser page
          </h1>
        </div>
      );
    return (
      <div className="slimContentMargin scrollableContainer">
        <Segment.Group raised>
          <Hero
            data={this.state.data}
            applyChangeset={this.applyChangeset}
            permissions={this.props.permissions}
          />
          {hasPermissionToRead(this.props.permissions, 'Correction') && (
            <Corrections
              data={this.state.data}
              userId={this.props.user.id}
              applyChangeset={this.applyChangeset}
              permissions={this.props.permissions}
            />
          )}
          {hasPermissionToRead(this.props.permissions, 'Comment') && (
            <Comments
              data={this.state.data}
              userId={this.props.user.id}
              applyChangeset={this.applyChangeset}
              permissions={this.props.permissions}
            />
          )}
          <div id="Interop" />
          {this.state.data &&
            this.state.data.customerInterops &&
            this.state.data.customerInterops.map(customerInterop => (
              <Interop
                key={customerInterop.id}
                customerInterop={customerInterop}
                data={this.state.data}
                userId={this.props.user.id}
                applyChangeset={this.applyChangeset}
                token={this.props.token}
                permissions={this.props.permissions}
              />
            ))}
        </Segment.Group>

        <Dimmer active={this.state.changing} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" loading color="pink" />
            Saving data
            <Header.Subheader>Please wait</Header.Subheader>
          </Header>
        </Dimmer>
      </div>
    );
  }
}

Modify.propTypes = {
  requestIds: PropTypes.array,
  permissions: PropTypes.array,
  urlId: PropTypes.bool,
};

const mapStateToProps = state => ({
  requestIds: state.explorer.modifiedRequestIds,
  permissions: state.auth.profile.permissions,
});

export default connect(mapStateToProps)(Modify);
