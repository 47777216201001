import { diff } from 'deep-diff';
import { debounce } from 'debounce';

const initialState = {};
let currentState;

// increment this when changing the redux state
// with props needed at login so that it can be
// cleaned up on new version
const STATE_VERSION = 2;

// add reducers you do not want to persist here
const ignoredReducers = [
  'router',
  { name: 'home', field: 'editKPILayout' },
  { name: 'home', field: 'kpiData' },
  { name: 'home', field: 'dataLoaded' },
  { name: 'home', field: 'timelineSyncs' },
  { name: 'ui', field: 'search' },
  { name: 'ui', field: 'searchResults' },
  { name: 'explorer', field: 'filters' },
  { name: 'explorer', field: 'selectedData' },
  { name: 'explorer', field: 'modifiedRequestIds' },
  { name: 'explorer', field: 'breadcrumbs' },
  { name: 'explorer', field: 'currentBreadcrumb' },
  { name: 'explorer', field: 'suggestion' },
  { name: 'explorer', field: 'tableDirty' },
  { name: 'explorer', field: 'showTable' },
  { name: 'explorer', field: 'requests' },
  { name: 'explorer', field: 'availableColumns' },
  { name: 'explorer', field: 'displayedColumns' },
  { name: 'explorer', field: 'currentFilter' },
  { name: 'explorer', field: 'standaloneRequestIds' },
  { name: 'report', field: 'layouts' },
  { name: 'report', field: 'data' },
  { name: 'report', field: 'lists' },
  { name: 'request', field: 'id' },
  { name: 'request', field: 'data' },
  'linkedData',
  'rfid',
];

export const loadState = () => {
  try {
    const localState = localStorage.getItem('ais-wr-state');
    if (localState === null) return initialState;
    currentState = JSON.parse(localState);
    if (process.env.NODE_ENV !== 'production')
      console.debug('loading state...');
    if (!currentState.version || currentState.version !== STATE_VERSION)
      return initialState;
    return currentState.data;
  } catch (err) {
    return initialState;
  }
};

export const saveState = debounce(state => {
  try {
    // clean the state to remove unnecessary reducers like router
    const savedState = JSON.parse(JSON.stringify(state));
    ignoredReducers.forEach(reducer => {
      if (typeof reducer === 'string') delete savedState[reducer];
      else if (typeof reducer === 'object') {
        delete savedState[reducer.name][reducer.field];
      }
    });
    const changes = diff(currentState, savedState);
    if (changes) {
      if (process.env.NODE_ENV !== 'production')
        console.debug('saving state...');
      currentState = savedState;
      localStorage.setItem(
        'ais-wr-state',
        JSON.stringify({ version: STATE_VERSION, data: savedState }),
      );
    }
  } catch (err) {
    console.error(err);
  }
}, 500);
