export const defaultColumns = [
  'date',
  'itemPartNumber',
  'quantity',
  'Aircraft-registration',
  'LopaEquipment-position',
  'Document-type_Document-ata',
  'itemDescriptionDoc',
  'figureDoc',
  'itemDoc',
  'itemLocationPax',
  'Correction-quantity',
  '_Photo-COUNT',
];
