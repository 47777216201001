import React, { Component } from 'react';
import { VictoryBar, VictoryStack } from 'victory';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';
import { svgDefs, linearGradient, dropShadow } from 'lib/svg';
import Chart from 'app/home/components/chart';

import { Header, Form, Dropdown } from 'semantic-ui-react';

export default class DefectiveSubassyKPI extends Component {
  state = {
    dataOpen: [],
    focus: 'open',
    count: 0,
    loading: true,
    error: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.focus !== props.kpi.payload.focus)
      return {
        focus: props.kpi.payload.focus,
        count: state.dataOpen.reduce((acc, d) => acc + d.y, 0),
      };
    return null;
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = async () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data instanceof Array) {
        this.setState({
          loading: false,
          dataOpen: this.props.data,
          count: this.props.data.reduce((acc, d) => acc + d.y, 0),
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  setTarget = async target => {
    await this.props.saveKPI(this.props.kpi.i, { target });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  getHeader() {
    return [
      <Header key="aircraftHeader">
        Selected aircraft
        <Header.Subheader>
          Select an aircraft registration in the dropdown to get its most
          requested subassemblies. You can type in the dropdown to filter the
          list. The number is the amount of currently open requests.
        </Header.Subheader>
      </Header>,
      <Form key="aircraftSettings">
        <Form.Field>
          <Dropdown
            placeholder="Selected aircraft"
            fluid
            search
            selection
            options={
              this.props.rawData.aircraft &&
              this.props.rawData.aircraft.map(aircraft => {
                const requestCount =
                  parseFloat(aircraft.routineCount) +
                  parseFloat(aircraft.criticalCount);
                return {
                  key: aircraft.registration,
                  value: aircraft.registration,
                  text: `${aircraft.registration} - ${aircraft.type}`,
                  description: `${requestCount}`,
                };
              })
            }
            value={this.props.kpi.payload.target}
            onChange={(e, { value }) => this.setTarget(value)}
          />
        </Form.Field>
      </Form>,
    ];
  }

  render() {
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.state.loading}
        error={this.state.error}
        getData={this.getData}
        noData={this.state.dataOpen.length === 0}
        deleteKPI={this.props.deleteKPI}
      >
        <KPI.Header
          title={`Defects ${
            this.props.kpi.payload.target
              ? `for ${this.props.kpi.payload.target}`
              : '(please configure)'
          }`}
        >
          {this.getHeader()}
        </KPI.Header>
        <KPI.Count
          value={this.state.count}
          unit={`${this.state.focus} requests`}
        />
        <KPI.Data>
          {defs}
          <Chart
            heightMultiplier={this.props.kpi.h}
            padding={{ top: 10, left: 0, right: 0, bottom: 60 }}
          >
            <VictoryStack colorScale={['url(#openGrad)', 'url(#closedGrad)']}>
              <VictoryBar
                data={this.state.dataOpen}
                style={{ labels: style.label }}
                labels={d => d.y}
              />
            </VictoryStack>
          </Chart>
        </KPI.Data>
      </KPI>
    );
  }
}

DefectiveSubassyKPI.propTypes = {
  ...KPIProps,
};

const style = {
  tooltip: {
    strokeWidth: 0,
    fill: 'white',
    filter: 'url(#dropshadow)',
  },
  openRequests: {
    fill: 'url(#openGrad)',
  },
  closedRequests: {
    fill: 'url(#closedGrad)',
  },
  legend: {
    labels: {
      fontSize: 14,
      fill: 'rgba(0,0,0,0.5)',
      fontFamily: 'Open Sans',
    },
  },
  label: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fill: '#fe8c00',
  },
};

const defs = svgDefs(
  linearGradient('closedGrad', [
    { offset: '0%', color: '#56ab2f' },
    { offset: '100%', color: '#a8e063' },
  ]),
  linearGradient('openGrad', [
    { offset: '0%', color: '#fe8c00' },
    { offset: '100%', color: '#f83600' },
  ]),
  dropShadow('dropshadow'),
);
