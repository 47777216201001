import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { hasPermissionToRead } from 'lib/permissions';

import ModifyInteropForm from './ModifyInteropForm';
import { ModifyInteropGeneric } from './ModifyInteropGeneric';

export default ({
  customerInterop,
  data,
  applyChangeset,
  userId,
  token,
  permissions,
}) => {
  if (!hasPermissionToRead(permissions, 'RequestInterop', customerInterop.id))
    return null;
  const requestInterops = data.attachments.requestInterops[customerInterop.id];
  return (
    <Segment padded color="yellow">
      <Header as="h2">{customerInterop.description}</Header>
      {customerInterop.type === 'form' && (
        <ModifyInteropForm
          customerInterop={customerInterop}
          requestInterops={requestInterops}
          data={data}
          userId={userId}
          applyChangeset={applyChangeset}
          permissions={permissions}
          token={token}
        />
      )}
      {customerInterop.type === 'list' && (
        <ModifyInteropGeneric
          customerInterop={customerInterop}
          requestInterops={requestInterops}
          data={data}
          userId={userId}
          applyChangeset={applyChangeset}
          permissions={permissions}
          mode={1}
        />
      )}
      {customerInterop.type === 'free' && (
        <ModifyInteropGeneric
          customerInterop={customerInterop}
          requestInterops={requestInterops}
          data={data}
          userId={userId}
          applyChangeset={applyChangeset}
          permissions={permissions}
          mode={0}
        />
      )}
    </Segment>
  );
};
