import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default ({ location }) => (
  <div className="contentMargin">
    <Message negative icon>
      <Icon name="warning sign" size="massive" />
      <Message.Content>
        <Message.Header>
          Cannot load <code>{location.pathname}</code>
        </Message.Header>
        <Link to="/dashboard" className="ui button basic">
          Go back home
        </Link>
      </Message.Content>
    </Message>
  </div>
);
