/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'react-grid-layout';
import sizeMe from 'react-sizeme';
import { Button } from 'semantic-ui-react';
import crypto from 'crypto-browserify';

import * as constants from 'app/home/homeConstants';
import { GridLayoutItemType } from './types';

import RequestCountKPI from './kpis/requestCountKPI';
import MaintenanceActionsKPI from './kpis/maintenanceActionsKPI';
import DefectiveSubassyOverTimeKPI from './kpis/defectiveSubassyOverTimeKPI';
import DefectiveSubassyKPI from './kpis/defectiveSubassyKPI';
import MetricKPI from './kpis/metricKPI';
import TATKPI from './kpis/tatKPI';
import TATOverTimeKPI from './kpis/tatByTimeKPI';
import Timeline from './kpis/timelineKPI';
import Latest from 'app/home/containers/latestKPI';
import RFIDTagAssign from './kpis/rfidTagAssignKPI';
import RequestCountAloneKPI from './kpis/requestCountAloneKpi';
import CriticalLinearKPI from './kpis/newKpisCriticalLinear';
import LatestPhotos from './kpis/newKpisLatestPhotos';
import OpenRequestPolarKPI from './kpis/newKpisRequestAircraftTypePolar';
import RequestByFamilyEquipementsBar from './kpis/newKpisRequestByFamilyEquipements';
import PercentRequestCorrected from './kpis/newKpisPercentRequestCorrected';
import HoursAndPriceMaintenance from './kpis/newKpisHoursAndPriceMaintenance';
import { SimpleCounters } from './kpis/newKpisSimpleCounters';
import { RequestsBySubAssemblies } from './kpis/newKpisRequestsBySubAssemblies';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

const ResponsiveGridLayout = sizeMe({ refreshRate: 100 })(
  ({
    kpis,
    kpiData,
    width,
    isDraggable,
    onLayoutChange,
    buildGrid,
    saveKPI,
    loadKPI,
    deleteKPI,
    navigate,
    token,
    customer,
    rawData,
    interval,
  }) => (
    <Responsive
      width={width}
      layouts={{ lg: kpis }}
      breakpoints={{ lg: 400, md: 300, sm: 200 }}
      cols={{
        lg: constants.defaultColumnCount,
        md: constants.defaultColumnCount - 1,
        sm: 1,
      }}
      rowHeight={constants.defaultRowHeight}
      containerPadding={[0, 0]}
      isResizable={false}
      isDraggable={isDraggable}
      onLayoutChange={onLayoutChange}
    >
      {buildGrid(
        kpis,
        kpiData,
        isDraggable,
        saveKPI,
        loadKPI,
        deleteKPI,
        navigate,
        token,
        customer,
        rawData,
        interval,
      )}
    </Responsive>
  ),
);

const layoutCompare = (left, right) =>
  left.x === right.x &&
  left.y === right.y &&
  left.w === right.w &&
  left.h === right.h;

let resizeThrottleTimer = null;

// God hates us, specs too
// TODO : redo this shitshow
class KPIGrid extends Component {
  state = {
    checkLayout: true,
    width: 0,
    dataExtraKpi: { loaded: false, data: {} },
  };

  componentDidMount() {
    this.getDataForExtraKpis();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      prevProps.interval.intervalType !== this.props.interval.intervalType
    ) {
      this.setState({ dataExtraKpi: { loaded: false, data: {} } });
      this.getDataForExtraKpis();
    }
  }

  getDataForExtraKpis = async () => {
    const token = this.props.token;
    let interval = this.props.interval.intervalType;
    if (!interval) interval = 'm-12';
    if (
      [
        'd-7',
        'd-14',
        'h-48',
        'h-24',
        'm-12',
        'm-6',
        'm-3',
        'm-2',
        'm-1',
      ].indexOf(interval) === -1
    )
      interval = 'm-12';
    const data = await API.get(ENDPOINTS.kpi.latestrequests, token, {
      interval: interval,
    });
    this.setState({ dataExtraKpi: { loaded: true, data: data } });
  };

  buildGrid(
    kpis,
    kpiData,
    isDraggable,
    saveKPI,
    loadKPI,
    deleteKPI,
    navigate,
    token,
    customer,
    rawData,
    interval,
  ) {
    return kpis.map(kpi => {
      const props = {
        kpi,
        data: kpiData[kpi.i],
        isDraggable,
        saveKPI,
        loadKPI,
        deleteKPI,
        navigate,
        token,
        customer,
        rawData,
        interval,
      };
      switch (kpi.payload.type) {
        case 'requestCount':
          return (
            <div key={kpi.i}>
              <RequestCountKPI {...props} />
            </div>
          );
        case 'maintenanceActions':
          return (
            <div key={kpi.i}>
              <MaintenanceActionsKPI {...props} />
            </div>
          );
        case 'defectiveSubassyOverTime':
          return (
            <div key={kpi.i}>
              <DefectiveSubassyOverTimeKPI {...props} />
            </div>
          );
        case 'defectiveSubassy':
          return (
            <div key={kpi.i}>
              <DefectiveSubassyKPI {...props} />
            </div>
          );
        case 'metric':
          return (
            <div key={kpi.i}>
              <MetricKPI {...props} />
            </div>
          );
        case 'timeline':
          return (
            <div key={kpi.i}>
              <Timeline {...props} />
            </div>
          );
        case 'tat':
          return (
            <div key={kpi.i}>
              <TATKPI {...props} />
            </div>
          );
        case 'tatTime':
          return (
            <div key={kpi.i}>
              <TATOverTimeKPI {...props} />
            </div>
          );
        case 'latest':
          return (
            <div key={kpi.i}>
              <Latest {...props} />
            </div>
          );
        case 'rfidtagassign':
          return (
            <div key={kpi.i}>
              <RFIDTagAssign {...props} />
            </div>
          );
        default:
          return <div key={kpi.i} />;
      }
    });
  }

  onResize = size => {
    if (resizeThrottleTimer !== null) clearTimeout(resizeThrottleTimer);
    resizeThrottleTimer = setTimeout(() => {
      this.setState({ checkLayout: true });
    }, 32);
    this.setState({ checkLayout: false, width: size.width });
  };

  onLayoutChange = currentLayout => {
    if (!this.props.isDraggable || !this.state.checkLayout) return;
    // we need to diff by hand currentLayout because it returns
    // different objects from kpis prop
    let needsUpdate = false;
    const newLayouts = currentLayout.map(layout => {
      const kpi = this.props.kpis.find(k => k.i === layout.i);
      if (!kpi || !layoutCompare(layout, kpi)) {
        needsUpdate = true;
        return {
          ...kpi,
          x: layout.x,
          y: layout.y,
          w: layout.w,
          h: layout.h,
        };
      }
      return kpi;
    });
    if (needsUpdate) this.props.saveKPILayout(newLayouts);
  };

  addKPI = kpi => {
    const index = `${kpi.type}${
      kpi.multi ? crypto.randomBytes(32).toString('hex') : ''
    }`;
    this.props.addKPI({
      i: index,
      x: 0,
      y: Infinity,
      ...kpi.config,
      payload: {
        type: kpi.type,
        ...kpi.defaultPayload,
      },
    });
  };

  renderAdditionalKPIs() {
    const entries = constants.kpis
      .filter(
        k => !this.props.kpis.some(ck => ck.payload.type === k.type) || k.multi,
      )
      .map((k, i) => (
        <Button
          key={i}
          color="green"
          icon="add"
          content={k.name}
          onClick={() => this.addKPI(k)}
        />
      ));
    return <div className="additionalKPIs">{entries}</div>;
  }

  render() {
    return (
      <>
        {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
          <SimpleCounters
            dataExtraKpi={this.state.dataExtraKpi.data.kpiSimpleCounters}
          />
        )}
        <div class="ui two column grid" style={{ marginTop: '5px' }}>
          <div class="column">
            <div class="ui segment">
              <div style={{ height: '270px' }}>
                {!this.state.dataExtraKpi.loaded && (
                  <div class="ui active inverted dimmer">
                    <div class="ui large text loader">Build in progress...</div>
                  </div>
                )}
                {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
                  <HoursAndPriceMaintenance
                    {...this.props}
                    dataExtraKpi={
                      this.state.dataExtraKpi.data.kpiHoursCostMaintenance
                    }
                  />
                )}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="ui segment">
              <div style={{ height: '270px' }}>
                {!this.state.dataExtraKpi.loaded && (
                  <div class="ui active inverted dimmer">
                    <div class="ui large text loader">Build in progress...</div>
                  </div>
                )}
                {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
                  <RequestsBySubAssemblies
                    dataExtraKpi={
                      this.state.dataExtraKpi.data.kpiRequestsBySubAssemblies
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="ui two column grid" style={{ marginTop: '5px' }}>
          <div class="column" style={{ height: '265px' }}>
            {!this.state.dataExtraKpi.loaded && (
              <div class="ui active inverted dimmer">
                <div class="ui large text loader">Build in progress...</div>
              </div>
            )}
            {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
              <LatestPhotos
                {...this.props}
                dataExtraKpi={this.state.dataExtraKpi.data.latestPhotos}
              />
            )}
          </div>
          <div class="column">
            <div class="ui segment">
              <div style={{ height: '265px' }}>
                {!this.state.dataExtraKpi.loaded && (
                  <div class="ui active inverted dimmer">
                    <div class="ui large text loader">Build in progress...</div>
                  </div>
                )}
                {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
                  <PercentRequestCorrected
                    {...this.props}
                    dataExtraKpi={
                      this.state.dataExtraKpi.data.kpiPercentRequestCorrected
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div key="r1" class="ui two column grid">
          <div key="c1" class="column">
            <div class="ui segment">
              <div key="kpi-1" style={{ height: '400px' }}>
                {!this.state.dataExtraKpi.loaded && (
                  <div class="ui active inverted dimmer">
                    <div class="ui large text loader">Build in progress...</div>
                  </div>
                )}
                {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
                  <RequestCountAloneKPI
                    {...this.props}
                    dataExtraKpi={
                      this.state.dataExtraKpi.data.kpiRequestEvolutionBar
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div key="c2" class="column">
            <div class="ui segment">
              <div key="kpi-2" style={{ height: '400px' }}>
                {!this.state.dataExtraKpi.loaded && (
                  <div class="ui active inverted dimmer">
                    <div class="ui large text loader">Build in progress...</div>
                  </div>
                )}
                {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
                  <CriticalLinearKPI
                    {...this.props}
                    dataExtraKpi={
                      this.state.dataExtraKpi.data.kpiCriticalLinear
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div key="r2" class="ui two column grid">
          <div key="c3" class="column">
            <div class="ui segment">
              <div key="kpi-3" style={{ height: '400px' }}>
                {!this.state.dataExtraKpi.loaded && (
                  <div class="ui active inverted dimmer">
                    <div class="ui large text loader">Build in progress...</div>
                  </div>
                )}
                {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
                  <OpenRequestPolarKPI
                    {...this.props}
                    dataExtraKpi={
                      this.state.dataExtraKpi.data.kpiRequestAircarftPolar
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div key="c4" class="column">
            <div class="ui segment">
              <div key="kpi-4" style={{ height: '400px' }}>
                {!this.state.dataExtraKpi.loaded && (
                  <div class="ui active inverted dimmer">
                    <div class="ui large text loader">Build in progress...</div>
                  </div>
                )}
                {this.state.dataExtraKpi && this.state.dataExtraKpi.loaded && (
                  <RequestByFamilyEquipementsBar
                    {...this.props}
                    dataExtraKpi={
                      this.state.dataExtraKpi.data.kpiRequestFamilyBar
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            (this.props.isDraggable ? 'kpiGridEdited' : '') + 'aisMarginTop'
          }
        >
          {this.props.isDraggable && this.renderAdditionalKPIs()}
          <ResponsiveGridLayout
            kpis={this.props.kpis}
            kpiData={this.props.kpiData}
            width={this.state.width}
            isDraggable={this.props.isDraggable}
            onLayoutChange={this.onLayoutChange.bind(this)}
            buildGrid={this.buildGrid}
            onSize={this.onResize}
            saveKPI={this.props.saveKPI}
            loadKPI={this.props.loadKPI}
            deleteKPI={this.props.deleteKPI}
            navigate={this.props.navigate}
            token={this.props.token}
            customer={this.props.customer}
            rawData={this.props.rawData}
            interval={this.props.interval}
          />
        </div>
      </>
    );
  }
}

KPIGrid.propTypes = {
  kpis: PropTypes.arrayOf(GridLayoutItemType).isRequired,
  kpiData: PropTypes.object,
  isDraggable: PropTypes.bool.isRequired,
  saveKPILayout: PropTypes.func.isRequired,
  saveKPI: PropTypes.func.isRequired,
  loadKPI: PropTypes.func,
  deleteKPI: PropTypes.func,
  addKPI: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  customer: PropTypes.object,
  rawData: PropTypes.object,
  interval: PropTypes.object,
};

export default KPIGrid;
