import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';

class ReportListActions extends Component {
  render() {
    return (
      <div className="pageActions">
        <Button
          basic
          active={!this.props.listLayout || this.props.listLayout === 'grid'}
          icon="grid layout"
          circular
          onClick={() => this.props.actions.setReportListLayout('grid')}
        />
        <Button
          basic
          active={this.props.listLayout === 'list'}
          icon="list"
          circular
          onClick={() => this.props.actions.setReportListLayout('list')}
        />
      </div>
    );
  }
}

ReportListActions.propTypes = {
  listLayout: PropTypes.string,
};

const mapStateToProps = state => ({
  listLayout: state.report.listLayout,
});

export default connect(mapStateToProps)(ReportListActions);
