import { push } from 'connected-react-router';
import * as types from 'config/types';

export const setAircraft = aircraftId => dispatch => {
  dispatch({
    type: types.CREATION_SET_AIRCRAFT,
    payload: { aircraftId },
  });
  dispatch(push('/creations/cmm'));
};

export const setCMM = (documentId, lopaId) => dispatch => {
  dispatch({
    type: types.CREATION_SET_CMM,
    payload: { documentId, lopaId },
  });
  dispatch(push('/creations/figures'));
};
