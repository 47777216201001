/* eslint-disable */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

let dataKpi = {
  series: [],
  options: {
    yaxis: {
      labels: {
        offsetX: 20,
        offsetY: 20,
        formatter: value => {
          return value.toFixed(1);
        },
      },
    },
    chart: {
      type: 'polarArea',
    },
    labels: [],
    stroke: {
      colors: ['#fff'],
    },
    fill: {
      opacity: 0.8,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  },
};

export default class OpenRequestPolarKPI extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(dataKpi));
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    dataKpi.series = this.props.dataExtraKpi.series.values;
    dataKpi.options.labels = this.props.dataExtraKpi.categories.values;
    this.setState(dataKpi);
  };

  render() {
    return (
      <>
        <div className="kpiContent">
          <div class="card">
            <div class="content">
              <div class="header">
                <h4>{this.props.dataExtraKpi.extra.title}</h4>
              </div>
              <div class="meta">{this.props.dataExtraKpi.extra.subtitle}</div>
              <div class="description">
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="polarArea"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
