import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Form, List, Button } from 'semantic-ui-react';
import produce from 'immer';
import moment from 'moment';
import { API } from 'lib/rest';
import { ENDPOINTS, FILE_API_URL } from 'config/network';
import {
  hasPermissionToCreate,
  hasPermissionToDelete,
  hasPermissionToEdit,
} from 'lib/permissions';
import connect from 'lib/reduxConnect';
import { randomNonce } from '../utils';
import ModifyFormListModal from './modifyFormListModal';
import ImagesInLine from 'app/util/imageInLine';

const sortFormFields = (a, b) => {
  if (parseInt(a.sort, 10) > parseInt(b.sort, 10)) return 1;
  if (parseInt(a.sort, 10) < parseInt(b.sort, 10)) return -1;
  return 0;
};

class ModifyInteropForm extends Component {
  state = {
    editedReport: null,
    editMode: false,
    openFormModal: false,
    forms: {},
  };
  editReport = report => {
    this.setState(
      produce(draft => {
        draft.editMode = true;
        draft.editedReport = report;
      }),
    );
  };
  buildDeleteChangeset = reportId => {
    const changeset = {
      RequestInterop: this.props.requestInterops.reduce((deleted, interop) => {
        if (typeof interop.report !== 'object' && interop.report === reportId)
          return [
            ...deleted,
            {
              action: 'delete',
              data: {
                id: interop.id,
              },
            },
          ];
        else if (interop.report.id === reportId)
          return [
            ...deleted,
            {
              action: 'delete',
              data: {
                id: interop.id,
              },
            },
          ];
        return deleted;
      }, []),
    };
    this.props.applyChangeset(changeset);
  };
  updateForm = () => {
    const changeset = {
      Report: [
        {
          action: 'update',
          data: {
            id: this.state.editedReport.id,
            data: JSON.stringify(this.state.editedReport.data),
          },
        },
      ],
    };
    this.props.applyChangeset(changeset);
    this.cancelEditMode();
  };
  cancelEditMode = () => {
    this.setState(
      produce(draft => {
        draft.editMode = false;
      }),
    );
  };
  updateField = field => (e, { value }) => {
    const originalField = this.state.editedReport.data.find(
      f => f.id === field.id,
    );
    if (!originalField) return;
    const index = this.state.editedReport.data.indexOf(originalField);
    const refresh = parseInt(field.refreshable, 10);
    const newField = {
      ...field,
      value,
      refreshable: refresh > 1 ? 1 : refresh,
    };
    this.setState(
      produce(draft => {
        draft.editedReport.data.splice(index, 1, newField);
      }),
    );
  };

  addForm = async () => {
    this.setState(
      produce(draft => {
        draft.openFormModal = true;
      }),
    );
    const response = await API.post(
      ENDPOINTS.webreporting.applicableForms,
      this.props.token,
      {
        requestIds: this.props.data.ids,
        customerInteropId: this.props.customerInterop.id,
      },
    );

    if (!response || !(response instanceof Array)) return;

    const forms = {};
    response.forEach(requestForms => {
      if (!forms[requestForms.value]) {
        forms[requestForms.value] = {
          list: [...requestForms.forms],
          requests: [requestForms.requestId],
        };
      } else {
        forms[requestForms.value].requests.push(requestForms.requestId);
        requestForms.forms.forEach(form => {
          if (!forms[requestForms.value].list.some(f => f.id === form.id)) {
            forms[requestForms.value].push(form);
          }
        });
      }
    });

    this.setState(
      produce(draft => {
        draft.forms = forms;
      }),
    );
  };

  addNew = async () => {
    this.closeFormModal();
    const changeset = { RequestInterop: [], Report: [] };
    let filteringValuesByRequests = null;
    try {
      // we need to filter out requests that already have a request interop
      const { requestInterops } = this.props;
      const requests = this.props.data.requests.filter(
        request =>
          requestInterops.find(
            rI => rI.RequestId.toString() === request.id.toString(),
          ) === undefined,
      );

      if (this.props.customerInterop.filteringFields)
        filteringValuesByRequests = await API.post(
          ENDPOINTS.webreporting.filteringvalues,
          this.props.token,
          {
            requestIds: this.props.data.ids,
            fields: this.props.customerInterop.filteringFields,
          },
        );

      // not ideal / 10
      // we need to go over reports first to only create those
      // with different filtering values
      // thing is, nonce generation requires async, but we need to insure
      // that we can read the report list synchronously, so we first need
      // to create the new reports without a nonce...
      requests.map((request, index) => {
        const filteringValuesResult =
          filteringValuesByRequests.find(v => v.requestId === request.id) ||
          null;
        const filteringValues =
          (filteringValuesResult && filteringValuesResult.value) || null;
        const existingReport = changeset.Report.find(
          rep => rep.data.filteringValues === filteringValues,
        );
        if (!existingReport)
          changeset.Report.push({
            action: 'create',
            data: {
              id: -(index + 1),
              date: moment().toISOString(),
              name: `F${moment().unix() + index}`,
              UserId: this.props.userId,
              filteringValues,
              data: null,
              CustomerInteropId: this.props.customerInterop.id,
            },
          });
      });
      // ...and then add the nonce
      changeset.Report = await Promise.all(
        changeset.Report.map(async report => ({
          ...report,
          data: {
            ...report.data,
            nonce: await randomNonce(),
          },
        })),
      );
      // and now we can connect the new request interops to the correct reports
      await Promise.all(
        requests.map(async (request, index) => {
          const filteringValuesResult =
            filteringValuesByRequests.find(v => v.requestId === request.id) ||
            null;
          const filteringValues =
            (filteringValuesResult && filteringValuesResult.value) || null;
          let ReportId = null;
          const existingReport = changeset.Report.find(
            rep => rep.data.filteringValues === filteringValues,
          );
          if (existingReport) ReportId = existingReport.data.id;
          // ReportId should never be null at this point
          // pray the old ones it is the case
          changeset.RequestInterop.push({
            action: 'create',
            data: {
              id: -(index + 1),
              date: moment().toISOString(),
              nonce: await randomNonce(),
              UserId: this.props.userId,
              RequestId: request.id,
              CustomerInteropId: this.props.customerInterop.id,
              value: '',
              ReportId,
            },
          });
        }),
      );
      this.props.applyChangeset(changeset);
    } catch (err) {
      this.props.applyChangeset(changeset);
    }
  };

  attachExisting = async (form, requests) => {
    this.closeFormModal();
    const changeset = { RequestInterop: [] };
    // attaching forms is way easier lol
    // we stan a lot more this logic
    await Promise.all(
      requests.map(async (requestId, index) => {
        changeset.RequestInterop.push({
          action: 'create',
          data: {
            id: -(index + 1),
            date: moment().toISOString(),
            nonce: await randomNonce(),
            UserId: this.props.userId,
            RequestId: requestId,
            CustomerInteropId: this.props.customerInterop.id,
            value: form.name,
            ReportId: form.id,
          },
        });
      }),
    );
    this.props.applyChangeset(changeset);
  };

  closeFormModal = () => {
    this.setState(
      produce(draft => {
        draft.openFormModal = false;
      }),
    );
  };

  renderSection(field) {
    return (
      <Header as="h4" key={field.id}>
        {field.value}
      </Header>
    );
  }
  renderFree(field) {
    const refresh = parseInt(field.refreshable, 10);
    return (
      <Form.Group key={field.id}>
        <Form.TextArea
          value={field.value || ''}
          label={field.title}
          autoHeight
          width={refresh > 0 ? '15' : '16'}
          onChange={this.updateField(field).bind(this)}
        />
        {refresh > 0 && <Form.Checkbox toggle checked={refresh > 1} />}
      </Form.Group>
    );
  }

  getPhotoSrc = photofilename =>
    `${FILE_API_URL}${
      this.props.customer.directory
    }photos/${photofilename}?token=${this.props.token}`;

  renderPhotos(field) {
    if (!field.value || field.value.length <= 0) return null;
    const photos = JSON.parse(field.value);
    if (!photos || photos.length <= 0) return null;
    return (
      <>
        <h4>{field.title}</h4>
        <div style={{ padding: '10px' }}>
          <ImagesInLine photos={photos} getPhotoSrc={this.getPhotoSrc} />
        </div>
      </>
    );
  }
  renderList(field) {
    return (
      <Form.Dropdown
        key={field.id}
        label={field.title}
        selection
        options={this.props.data.reportLists[field.parameter].map(option => ({
          text: option.title,
          value: option.value,
        }))}
        value={field.value}
        onChange={this.updateField(field).bind(this)}
      />
    );
  }
  renderEditMode() {
    const data = [...this.state.editedReport.data].sort(sortFormFields);
    return (
      <div>
        <Header as="h3">
          {this.state.editedReport.value}
          <Header.Subheader>{this.state.editedReport.date}</Header.Subheader>
        </Header>
        <Form>
          {data.map(field => {
            switch (field.type) {
              case 'section':
                return this.renderSection(field);
              case 'list':
                return this.renderList(field);
              case 'hidden':
                return null;
              case 'photo':
                return this.renderPhotos(field);
              default:
                return this.renderFree(field);
            }
          })}
          <Form.Group inline>
            <Form.Button
              icon="save"
              primary
              basic
              content="Update"
              onClick={this.updateForm}
            />
            <Form.Button basic content="Cancel" onClick={this.cancelEditMode} />
          </Form.Group>
        </Form>
      </div>
    );
  }
  render() {
    if (this.state.editMode) return this.renderEditMode();
    const requestInterops = this.props.requestInterops;
    const customerInterop = this.props.customerInterop;
    return (
      <List verticalAlign="middle" divided relaxed="very">
        {requestInterops &&
          requestInterops.map(interop => {
            if (typeof interop.report !== 'object') return null;
            return (
              <List.Item key={interop.id}>
                <List.Content floated="right">
                  <Button
                    circular
                    basic
                    icon="edit"
                    disabled={
                      !hasPermissionToEdit(
                        this.props.permissions,
                        'RequestInterop',
                        '',
                        customerInterop.id,
                      )
                    }
                    onClick={() => this.editReport(interop.report)}
                  />
                  <Button
                    circular
                    basic
                    color="red"
                    icon="remove"
                    disabled={
                      !hasPermissionToDelete(
                        this.props.permissions,
                        'RequestInterop',
                        customerInterop.id,
                      )
                    }
                    onClick={() => this.buildDeleteChangeset(interop.report.id)}
                  />
                </List.Content>
                <List.Content>
                  <List.Header>{interop.value}</List.Header>
                  <List.Description>{interop.date}</List.Description>
                </List.Content>
              </List.Item>
            );
          })}
        {(!requestInterops ||
          requestInterops.length < this.props.data.requests.length) && (
          <List.Item>
            <List.Content floated="right">
              <Button
                color="green"
                icon="add"
                content="Add form"
                disabled={
                  !hasPermissionToCreate(
                    this.props.permissions,
                    'RequestInterop',
                    customerInterop.id,
                  )
                }
                onClick={() => this.addForm()}
              />
            </List.Content>
            {(!requestInterops || requestInterops.length === 0) && (
              <List.Content>
                <List.Header>{customerInterop.description} not set</List.Header>
              </List.Content>
            )}
          </List.Item>
        )}
        <ModifyFormListModal
          open={this.state.openFormModal}
          forms={this.state.forms}
          addNew={this.addNew}
          attachExisting={this.attachExisting}
          customerInterop={customerInterop}
          requestInterops={requestInterops}
          onClose={this.closeFormModal}
        />
      </List>
    );
  }
}

ModifyInteropForm.propTypes = {
  customerInterop: PropTypes.object,
  requestInterops: PropTypes.array,
  data: PropTypes.object,
  applyChangeset: PropTypes.func,
  userId: PropTypes.number,
  token: PropTypes.string,
  permissions: PropTypes.array,
  customer: PropTypes.object,
};

const mapStateToProps = state => ({
  customer: state.auth.customer,
});

export default connect(mapStateToProps)(ModifyInteropForm);
