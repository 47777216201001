import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import connect from 'lib/reduxConnect';

/**
 * This component is here to handle the callback called by SAML login
 * It's rendered when the /auth/login/authenticated route is called, containing the jwt in the query string
 */
class AuthHandler extends Component {
  constructor(props) {
    super(props);
    const queryString = props.location.search;
    const token = new URLSearchParams(queryString).get('token');

    this.state = {
      done: !token,
    };

    // noNavigation has no sense here because we've been redirected to Saudia login page anyway
    props.actions.verifyToken(token, false);
  }

  render() {
    if (this.state.done) return <Redirect to="/" />;
    return <div>Logging in...</div>;
  }
}

AuthHandler.propTypes = {
  token: PropTypes.string,
  location: PropTypes.object,
};

export default connect()(AuthHandler);
