import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FilterList = ({ filters, availableFilters, addFilter }) => {
  const lastFilter = filters[filters.length - 1];
  return (
    <div className="explorerFilters" id="filterList">
      {availableFilters.map((filter, index) => {
        const activeFilter = filters.find(
          f => f.type === filter.type && f.name === filter.name,
        );
        return (
          <div
            key={index}
            className={`filter ${
              lastFilter &&
              lastFilter.type === filter.type &&
              lastFilter.name === filter.name
                ? 'active'
                : ''
            }`}
            onClick={activeFilter ? null : () => addFilter(filter)}
            role="button"
            tabIndex={index}
          >
            {activeFilter && activeFilter.values && (
              <span className="locked">
                <FontAwesomeIcon inverse fixedWidth icon="lock" />
              </span>
            )}
            {filter.name}
          </div>
        );
      })}
    </div>
  );
};
