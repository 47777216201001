import * as types from 'config/types';

export const setFirstCall = firstCall => ({
  type: types.MAINTENANCE_SET_FIRST_CALL,
  payload: { firstCall },
});

export const setChartConfig = chartConfig => ({
  type: types.MAINTENANCE_SET_CHART_CONFIG,
  payload: { chartConfig },
});
