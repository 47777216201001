import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Form, Button } from 'semantic-ui-react';
import produce from 'immer';
import { debounce } from 'debounce';
import moment from 'moment';
import hash from 'object-hash';
import {
  hasPermissionToCreate,
  hasPermissionToDelete,
  hasPermissionToEdit,
  hasPermissionToRead,
} from 'lib/permissions';

import { round, randomNonce } from '../utils';

export default class ModifyCorrections extends Component {
  state = {
    canAddCorrection: true,
    updateOnEmptyBN: false,
    quantity: 1,
    addBN: '',
    updateBN: '',
    dataHash: '',

    addError: false,
  };

  componentDidMount() {
    this.setupComponent();
  }
  componentDidUpdate(prevProps, prevState) {
    if (hash(this.props.data) !== prevState.dataHash) this.setupComponent();
  }

  setupComponent = () => {
    this.setState(
      produce(draft => {
        draft.canAddCorrection = this.props.data.requests.some(request => {
          const correctionQty = this.props.data.attachments.corrections
            .filter(c => c.RequestId === request.id)
            .reduce((sum, c) => sum + parseFloat(c.quantity), 0);
          return correctionQty < parseFloat(request.reqQty);
        });
        draft.dataHash = hash(this.props.data);
      }),
    );
  };

  buildAddChangeset = async () => {
    const changeset = { Correction: [] };
    await Promise.all(
      this.props.data.requests.map(async (request, index) => {
        changeset.Correction.push({
          action: 'create',
          data: {
            id: -index,
            date: moment().toISOString(),
            nonce: await randomNonce(),
            UserId: this.props.userId,
            quantity: this.state.quantity,
            batchNumber: this.state.addBN,
            RequestId: request.id,
          },
        });
      }),
    );
    const add = await this.props.applyChangeset(changeset);
    if (!add || add.changeset.Correction[0].action === 'delete') {
      this.setState(
        produce(draft => {
          draft.addError = true;
        }),
      );
      this.setupComponent();
    } else {
      this.setState(
        produce(draft => {
          draft.addError = false;
        }),
      );
    }
  };

  buildCloseChangeset = async () => {
    const changeset = { Correction: [] };
    await Promise.all(
      this.props.data.requests.map(async (request, index) => {
        const correctionQty = this.props.data.attachments.corrections
          .filter(c => c.RequestId === request.id)
          .reduce((sum, c) => sum + parseFloat(c.quantity), 0);
        if (correctionQty < parseFloat(request.reqQty))
          changeset.Correction.push({
            action: 'create',
            data: {
              id: -index,
              date: moment().toISOString(),
              nonce: await randomNonce(),
              UserId: this.props.userId,
              quantity: parseFloat(request.reqQty) - correctionQty,
              batchNumber: this.state.addBN,
              RequestId: request.id,
            },
          });
      }),
    );
    this.props.applyChangeset(changeset);
  };

  buildDeleteChangeset = () => {
    const changeset = { Correction: [] };
    this.props.data.attachments.corrections.forEach(correction => {
      changeset.Correction.push({
        action: 'delete',
        data: {
          id: correction.id,
        },
      });
    });
    this.props.applyChangeset(changeset);
  };

  buildUpdateChangeset = () => {
    const changeset = { Correction: [] };
    this.props.data.attachments.corrections.forEach(correction => {
      if (
        !this.state.updateOnEmptyBN ||
        (this.state.updateOnEmptyBN &&
          (!correction.batchNumber || correction.batchNumber === ''))
      )
        changeset.Correction.push({
          action: 'update',
          data: {
            id: correction.id,
            batchNumber: this.state.updateBN,
          },
        });
    });
    this.props.applyChangeset(changeset);
  };

  updateField = field => (e, { value }) => {
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
    if (field === 'quantity') this.keepQuantityCast();
  };

  updateOnEmptyBN = () => {
    this.setState(
      produce(draft => {
        draft.updateOnEmptyBN = !this.state.updateOnEmptyBN;
      }),
    );
  };

  incrementQuantity = value => () => {
    const quantity = parseFloat(this.state.quantity);
    if (!isNaN(value))
      this.setState(
        produce(draft => {
          if (quantity + value > 0) draft.quantity = round(quantity + value, 1);
          else draft.quantity = 0.1;
        }),
      );
  };

  keepQuantityCast = debounce(() => {
    const quantity = parseFloat(this.state.quantity);
    this.setState(
      produce(draft => {
        if (!isNaN(quantity) && quantity > 0)
          draft.quantity = round(quantity, 1);
        else draft.quantity = 0.1;
      }),
    );
  }, 500);

  render() {
    if (!this.props.data) return;
    let headerEditLabel = '';
    if (
      this.props.data.attachments &&
      this.props.data.attachments.corrections
    ) {
      headerEditLabel += this.props.data.attachments.corrections.length;
      headerEditLabel += ' total corrections';
      headerEditLabel += this.props.data.attachments.corrections.filter(
        c => !c.batchNumber || c.batchNumber === '',
      ).length;
      headerEditLabel += ' without a batch number';
    }
    return (
      <Segment id="Correction" padded color="purple">
        <Header as="h2">Corrections</Header>

        {this.state.canAddCorrection && (
          <div>
            <Header as="h3">Add</Header>
            {this.state.addError && (
              <div className="modifyRequestError">
                Could not add correction, invalid quantity
              </div>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  label="Batch Number"
                  placeholder="Batch Number"
                  value={this.state.addBN}
                  onChange={this.updateField('addBN')}
                  fluid
                />
                <Form.Input
                  label="Quantity"
                  placeholder="Quantity"
                  fluid
                  value={this.state.quantity}
                  onChange={this.updateField('quantity')}
                  action
                >
                  <input />
                  <Button icon="minus" onClick={this.incrementQuantity(-1)} />
                  <Button icon="add" onClick={this.incrementQuantity(1)} />
                </Form.Input>
              </Form.Group>
              <Form.Group inline>
                <Form.Button
                  icon="add"
                  basic
                  primary
                  content="Add correction"
                  disabled={
                    !hasPermissionToCreate(this.props.permissions, 'Correction')
                  }
                  onClick={this.buildAddChangeset}
                />
                <Form.Button
                  color="green"
                  icon="check"
                  basic
                  content="Close all requests"
                  disabled={
                    !hasPermissionToCreate(this.props.permissions, 'Correction')
                  }
                  onClick={this.buildCloseChangeset}
                />
              </Form.Group>
            </Form>
          </div>
        )}

        {!this.state.canAddCorrection && (
          <div style={{ fontSize: '1.25em', fontWeight: 'lighter' }}>
            All requests are corrected
          </div>
        )}

        {hasPermissionToRead(this.props.permissions, 'Correction') && (
          <Fragment>
            {this.props.data.attachments.corrections.length > 0 && (
              <div style={{ marginTop: '2em' }}>
                <Header as="h3">
                  Edit
                  <Header.Subheader>{headerEditLabel}</Header.Subheader>
                </Header>
                <Form>
                  <Form.Input
                    label="Batch Number"
                    placeholder="Batch Number"
                    fluid
                    value={this.state.updateBN}
                    onChange={this.updateField('updateBN')}
                  />
                  <Form.Checkbox
                    label="Update only corrections without a batch number"
                    checked={this.state.updateOnEmptyBN}
                    onChange={this.updateOnEmptyBN}
                    toggle
                  />
                  <Form.Group inline>
                    <Form.Button
                      icon="save"
                      basic
                      primary
                      content="Update corrections"
                      disabled={
                        !hasPermissionToEdit(
                          this.props.permissions,
                          'Correction',
                        )
                      }
                      onClick={this.buildUpdateChangeset}
                    />
                    <Form.Button
                      basic
                      color="red"
                      icon="remove"
                      content="Delete all corrections"
                      disabled={
                        !hasPermissionToDelete(
                          this.props.permissions,
                          'Correction',
                        )
                      }
                      onClick={this.buildDeleteChangeset}
                    />
                  </Form.Group>
                </Form>
              </div>
            )}
          </Fragment>
        )}
      </Segment>
    );
  }
}

ModifyCorrections.propTypes = {
  data: PropTypes.object,
  applyChangeset: PropTypes.func,
  userId: PropTypes.number,
  permissions: PropTypes.array,
};
