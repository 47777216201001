import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import MainMaintenance from './containers/maintenance';
import Modify from '../explorer/containers/modify';
import ExplorerActions from './containers/MaintenanceActionsComponent';
// eslint-disable-next-line max-len
import ModifyRequestsActions from '../explorer/containers/ModifyRequestsActions';
import StandaloneTable from '../explorer/containers/standaloneTable';

import connect from 'lib/reduxConnect';

class Maintenance extends Component {
  static getActions(location, actions) {
    if (location.pathname.includes('modify')) return <ModifyRequestsActions />;
    if (location.pathname.includes('table')) return null;
    return <ExplorerActions />;
  }
  static getPathName = location => {
    if (location.pathname.includes('modify')) return 'Modify request';
    if (location.pathname.includes('table')) return 'Request table';
    return null;
  };

  render() {
    const prefix = this.props.match.url;
    return (
      <div style={{ display: 'flex', flex: 1, overflowY: 'hidden' }}>
        <Route exact path={`${prefix}/modify`} render={() => <Modify />} />
        <Route
          path={`${prefix}/modify/:requestId([0-9,]+)`}
          render={() => <Modify urlId />}
        />
        <Route path={`${prefix}/table`} render={() => <StandaloneTable />} />
        <Route
          exact
          path={prefix}
          render={() => (
            <MainMaintenance
              token={this.props.token}
              setRequestsIds={this.props.actions.setStandaloneRequestIds}
            />
          )}
        />
      </div>
    );
  }
}

export default connect()(Maintenance);
