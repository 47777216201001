/* eslint-disable */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

let dataKpi = {
  series: [],
  options: {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Month',
      },
    },
    yaxis: {
      title: {
        text: 'Number of requests',
      },
      min: 0,
      max: 200,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  },
};

export default class CriticalLinearKPI extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(dataKpi));
    this.state.loaded = false;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    dataKpi.options.yaxis.max = this.props.dataExtraKpi.extra.max;
    dataKpi.series = this.props.dataExtraKpi.series.values;
    dataKpi.options.xaxis.categories = this.props.dataExtraKpi.categories.values;
    dataKpi.options.xaxis.title.text = this.getXaxisTitle();
    this.setState(dataKpi);
    this.setState({ loaded: true });
  };

  getXaxisTitle() {
    const interval = this.props.interval.intervalType;
    if (interval === 'h-24') return 'Last day';
    if (interval === 'h-48') return 'Last 2 days';
    if (interval === 'd-7') return 'Last 7 days';
    if (interval === 'd-14') return 'Last 14 days';
    if (interval === 'm-1') return 'Last month';
    if (interval === 'm-2') return 'Last 2 months';
    if (interval === 'm-3') return 'Last 3 months';
    if (interval === 'm-6') return 'Last 6 months';
    if (interval === 'm-12') return 'Last 12 months';
    return 'Last 12 months';
  }

  render() {
    return (
      <>
        <div className="kpiContent">
          <div class="card">
            <div class="content">
              <div class="header">
                <h4>{this.props.dataExtraKpi.extra.title}</h4>
              </div>
              <div class="meta">{this.props.dataExtraKpi.extra.subtitle}</div>
              <div class="description">
                {this.state.loaded && (
                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={350}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
