import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class HistoryModal extends Component {
  render() {
    let synchros = [];
    if (this.props.history) {
      synchros = this.props.history.map(history => (
        <div
          style={{
            backgroundColor: '#eee',
            color: '#666',
            padding: 10,
            margin: 10,
            fontSize: 14,
          }}
        >
          {history.fieldValue !== '' ? (
            <div>
              {history.firstName} {history.lastName} has edited the field "
              {history.fieldName}" with <b>{history.fieldValue}</b>
            </div>
          ) : (
            <div>
              {history.firstName} {history.lastName} has cleaned the field "
              {history.fieldName}"
            </div>
          )}
          <div style={{ fontSize: 12 }}>{history.modificationTime}</div>
        </div>
      ));

      return (
        <Modal
          onClose={() => this.props.closeModalCallback()}
          open={this.props.isOpen}
        >
          <Modal.Header>{this.props.modalTitle}</Modal.Header>
          <Modal.Content>
            <Modal.Description>{synchros}</Modal.Description>
          </Modal.Content>
        </Modal>
      );
    } else {
      return <div />;
    }
  }
}

HistoryModal.propTypes = {
  modalTitle: PropTypes.string,
  history: PropTypes.array,
  isOpen: PropTypes.bool,
  closeModalCallback: PropTypes.func,
};
