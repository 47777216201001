import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  documents: [],
  history: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.DOCUMENTS_LOAD_DOCUMENTS:
        draft.documents = action.payload.documents;
        break;
      case types.DOCUMENTS_LOAD_HISTORY:
        draft.history = action.payload.history;
        break;
    }
  });
