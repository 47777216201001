import PropTypes from 'prop-types';

export const KPIConfig = PropTypes.shape({
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
});

// there are more parameters https://github.com/STRML/react-grid-layout#grid-item-props
export const GridLayoutItemType = PropTypes.shape({
  i: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    type: PropTypes.string.isRequired,
    filter: PropTypes.string,
    configs: PropTypes.arrayOf(KPIConfig),
  }).isRequired,
}).isRequired;

export const KPIProps = {
  kpi: GridLayoutItemType,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDraggable: PropTypes.bool.isRequired,
  saveKPI: PropTypes.func,
  loadKPI: PropTypes.func,
  deleteKPI: PropTypes.func,
  navigate: PropTypes.func,
  token: PropTypes.string,
  customer: PropTypes.object,
  rawData: PropTypes.object,
  interval: PropTypes.object,
};
