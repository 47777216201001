import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import connect from 'lib/reduxConnect';

import List from './containers/list';
import Figures from './containers/figures';

class Creation extends Component {
  static getPathName = location => {
    if (location.pathname.includes('cmm')) return 'Documents';
    if (location.pathname.includes('figures')) return 'Figures';
    return 'Aircrafts';
  };

  render() {
    const prefix = this.props.match.url;
    return (
      <div className="contentMargin" style={{ display: 'flex', flex: 1 }}>
        <Route exact path={prefix} render={() => <List mode="Aircraft" />} />
        <Route path={`${prefix}/cmm`} render={() => <List mode="CMM" />} />
        <Route path={`${prefix}/figures`} render={() => <Figures />} />
      </div>
    );
  }
}

export default connect()(Creation);
