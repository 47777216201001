import React from 'react';
import { VictoryPie, VictoryTooltip, VictoryLegend } from 'victory';
import { round } from 'app/explorer/utils';

export default ({ loading, widget, data }) => {
  if (loading) return <div>Loading...</div>;
  if (!data) return <div>No data available</div>;
  const formattedData = widget.options.data.map((d, dindex) => ({
    x: d.name,
    y: round(data[0][dindex], 1),
  }));
  const colorScale = widget.options.data.map(d => d.color);
  const legendData = widget.options.data.map(d => ({
    name: d.name,
    symbol: { fill: d.color },
  }));
  const startAngle = widget.options.gauge ? 90 : 0;
  const endAngle = widget.options.gauge ? -90 : 360;
  const piePadding = widget.options.gauge ? -140 : 10;
  return (
    <div>
      <VictoryPie
        padding={{ top: 10, left: 10, right: 10, bottom: piePadding }}
        width={300}
        height={300}
        data={formattedData}
        colorScale={colorScale}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={75}
        padAngle={5}
        labels={d => d.y}
        labelComponent={<VictoryTooltip />}
      />
      <VictoryLegend
        width={300}
        height={30}
        orientation="horizontal"
        data={legendData}
      />
    </div>
  );
};
