import React, { Component } from 'react';
import connect from 'lib/reduxConnect';
import { Button } from 'semantic-ui-react';

class ModifyRequestsActions extends Component {
  render() {
    return (
      <div className="pageActions">
        <Button
          primary
          icon="chevron left"
          content="Go back"
          onClick={() => this.props.actions.navBack()}
        />
      </div>
    );
  }
}

export default connect()(ModifyRequestsActions);
