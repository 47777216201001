import * as types from 'config/types';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

export const getLinkedData = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const customFields = await API.get(
    ENDPOINTS.webreporting.customfields,
    token,
  );
  dispatch({
    type: types.LINKED_DATA_SET_DATA,
    payload: {
      customFields,
    },
  });
};

export const selectCustomField = selectedCustomField => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.LINKED_DATA_SELECT_CF,
    payload: { selectedCustomField },
  });
  const { tei } = getState().linkedData.customFields[selectedCustomField];
  dispatch(loadItems(tei));
};

export const loadItems = (tei, page = 0) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const search = getState().linkedData.itemSearch;
  const order = getState().linkedData.itemOrder;
  const itemInfo = await API.get(ENDPOINTS.webreporting.items, token, {
    tei,
    page,
    search,
    order,
  });
  dispatch({
    type: types.LINKED_DATA_SET_ITEMS,
    payload: itemInfo,
  });
};

export const selectItem = selectedItem => ({
  type: types.LINKED_DATA_SELECT_ITEM,
  payload: { selectedItem },
});

export const setItemsLoading = () => ({
  type: types.LINKED_DATA_SET_ITEM_LOADING,
});

export const setItemOrder = (order, tei) => async (dispatch, getState) => {
  dispatch(setItemsLoading());
  dispatch({
    type: types.LINKED_DATA_SET_ITEM_ORDER,
    payload: { order },
  });
  dispatch(loadItems(tei));
};

export const toggleUnsetItems = () => ({
  type: types.LINKED_DATA_TOGGLE_UNSET_ITEM,
});

export const searchItems = search => ({
  type: types.LINKED_DATA_SEARCH_ITEM,
  payload: { search },
});

export const getCFSV = (itemId, tei) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const cfsv = await API.get(ENDPOINTS.webreporting.cfsv, token, {
    itemId,
    tei,
  });
  dispatch({
    type: types.LINKED_DATA_SET_CFSV,
    payload: { index: `${itemId}_${tei}`, cfsv },
  });
  return Promise.resolve();
};

export const getCFSVBatch = (itemIds, tei) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const cfsv = await API.get(ENDPOINTS.webreporting.cfsvBatch, token, {
    itemIds,
    tei,
  });

  const formattedCFSV = {};

  Object.keys(cfsv).forEach(itemId => {
    const itemData = cfsv[itemId];
    Object.keys(itemData).forEach(tei => {
      formattedCFSV[`${itemId}_${tei}`] = itemData[tei];
    });
  });

  dispatch({
    type: types.LINKED_DATA_SET_CFSV_BATCH,
    payload: { cfsv: formattedCFSV },
  });
  return Promise.resolve();
};

export const applyChangeset = (changeset, itemId, tei) => async (
  dispatch,
  getState,
) => {
  const token = getState().auth.token;
  const syncId = await API.get(ENDPOINTS.sync.syncId, token, {
    device: 'webreporting',
  });
  if (syncId.syncid) {
    await API.post(ENDPOINTS.sync.userdata, token, {
      device: 'webreporting',
      syncid: syncId.syncid,
      json: { changeset },
    });
    dispatch(getCFSV(itemId, tei));
  }
  return Promise.resolve();
};

export const setActiveItemPage = page => ({
  type: types.LINKED_DATA_SET_ITEM_PAGE,
  payload: { page },
});
