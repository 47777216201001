import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { loadState, saveState } from 'lib/reduxPersist';
import rootReducer from 'config/reducers';
import { WR_SUBDIR } from 'config/network';

export const history = createBrowserHistory({
  basename: WR_SUBDIR,
});

const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

// redux chrome debugger setup
if (process.env.NODE_ENV !== 'production') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

export const store = createStore(
  connectRouter(history)(rootReducer),
  loadState(),
  compose(
    applyMiddleware(...middleware),
    ...enhancers,
  ),
);
store.subscribe(() => {
  saveState(store.getState());
});
