import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'app/explorer/components/table';
import { convertIcon } from 'app/explorer/utils';

import AttachmentEntry from './attachmentEntry';

export default class Attachment extends Component {
  renderElement = print => (element, index) => (
    <AttachmentEntry
      key={index}
      element={element}
      print={print}
      getPhotoSrc={this.props.getPhotoSrc}
      handleOpen={this.props.handleOpen}
    />
  );

  render() {
    const tableHeight = 400;
    let attachmentContent;
    if (this.props.attachment.type === 'photos') {
      attachmentContent = (
        <div
          className="contentMargin"
          style={{
            ...containerBaseStyle,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {this.props.attachment.elements &&
            this.props.attachment.elements.map(
              this.renderElement(this.props.attachment.print).bind(this),
            )}
        </div>
      );
    } else {
      attachmentContent = (
        <div className="contentMargin" style={containerBaseStyle}>
          {this.props.attachment.elements &&
            this.props.attachment.elements.map(
              this.renderElement(this.props.attachment.print).bind(this),
            )}
        </div>
      );
    }
    return (
      <Segment>
        <Header>
          <FontAwesomeIcon
            fixedWidth
            icon={convertIcon(this.props.attachment.icon)}
            className="attachmentTitleIcon"
          />
          {this.props.attachment.title}
        </Header>
        {attachmentContent}
        {this.props.attachment.values && (
          <div style={{ maxHeight: tableHeight, paddingBottom: '1em' }}>
            <Table
              token={this.props.token}
              modifiedRequestIds={[]}
              modifyRequests={this.props.modifyRequests}
              viewRequest={this.props.viewRequest}
              requestIds={this.props.attachment.values.filter(
                id => this.props.requestId.toString() !== id.toString(),
              )}
              height={tableHeight}
              dirty
              markDirty={() => null}
              setCache={() => null}
            />
          </div>
        )}
      </Segment>
    );
  }
}

const containerBaseStyle = {
  maxWidth: '60em',
  margin: '1rem auto',
};

Attachment.propTypes = {
  requestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  attachment: PropTypes.object,
  getPhotoSrc: PropTypes.func,
  modifyRequests: PropTypes.func,
  viewRequest: PropTypes.func,
  handleOpen: PropTypes.func,
  token: PropTypes.string,
};
