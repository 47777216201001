import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import connect from 'lib/reduxConnect';

import ReportRenderer from './containers/reportRenderer';

class ReportContainer extends Component {
  render() {
    const prefix = this.props.match.url;
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
        }}
      >
        <Route
          path={`${prefix}/:type([a-zA-Z]+)/:reportId(.+)`}
          component={ReportRenderer}
        />
        <Route exact path={prefix} render={() => <Redirect to="/" />} />
      </div>
    );
  }
}

export default connect()(ReportContainer);
