import React, { useState } from 'react';
import { Header, Modal, Button, Form } from 'semantic-ui-react';

const ModifyPrioritiesModal = ({
  open,
  priorities,
  families,
  requests,
  onClose,
  save,
}) => {
  const [newPriorities, setNewPriorities] = useState(families.map(() => -1));
  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Header icon="sync" content="Update action" />
      <Modal.Content>
        <div>
          <p>
            Select the action for each different family. Families with value "No
            update" are ignored.
          </p>
          <Form>
            {families.map((fam, famIndex) => (
              <Form.Dropdown
                key={famIndex}
                label={fam.name}
                selection
                options={[
                  {
                    text: 'No update',
                    value: -1,
                  },
                  ...priorities
                    .filter(prio => prio.families.includes(fam.id))
                    .map(prio => {
                      const famIndex = prio.families
                        .split(',')
                        .indexOf(fam.id.toString());
                      const prioId = prio.ids.split(',')[famIndex];
                      return {
                        text: prio.groupName,
                        value: prioId,
                      };
                    }),
                ]}
                value={newPriorities[famIndex]}
                onChange={(e, { value }) => {
                  const updatedPriorities = [...newPriorities];
                  updatedPriorities[famIndex] = value;
                  setNewPriorities(updatedPriorities);
                }}
              />
            ))}
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          onClick={() => save(newPriorities)}
          inverted
          icon="save"
          content="Save"
        />
        <Button color="red" onClick={() => onClose()} inverted>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModifyPrioritiesModal;
