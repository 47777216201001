import moment from 'moment';
import XLSX from 'xlsx';

import AsbestosGloves from 'assets/icons/security/01_AsbestosGloves_00.png';
import CockpitDoorKey from 'assets/icons/security/02_CockpitDoorKey_00.png';
import CrashAxe from 'assets/icons/security/03_CrashAxe_00.png';
import CrewLifeVest from 'assets/icons/security/04_CrewLifeVest_00.png';
import DemoBag from 'assets/icons/security/05_DemoBag_00.png';
import ExtensionSeat from 'assets/icons/security/06_ExtensionSeatBelt_00.png';
import FireExtinguisher from 'assets/icons/security/07_FireExtinguisher_00.png';
import FirstAidKit from 'assets/icons/security/08_FirstAidKit_00.png';
import Flashlight from 'assets/icons/security/09_Flashlight_00.png';
import InfantLifeVest from 'assets/icons/security/10_InfantLifeVest_00.png';
import InfantOxygenMask from 'assets/icons/security/11_InfantOxygenMask_00.png';
import InfantSeatBelt from 'assets/icons/security/12_InfantSeatBelt_00.png';
import ManualRelease from 'assets/icons/security/13_ManualReleaseTool_00.png';
import PaxLifeVest from 'assets/icons/security/14_PaxLifeVest_00.png';
import OGenerator from 'assets/icons/security/15_O2Generator_00.png';
import OxygenBottle from 'assets/icons/security/16_OxygenBottle_00.png';
import OxygenMask from 'assets/icons/security/17_OxygenMask_00.png';
import PBE from 'assets/icons/security/19_PBE_00.png';
import PhysicianKit from 'assets/icons/security/20_PhysicianKit_00.png';
import RadioBeacon from 'assets/icons/security/21_RadioBeaconPortable_00.png';
import SpareSeatBelt from 'assets/icons/security/23_SpareSeatBelt_00.png';
import Megaphone from 'assets/icons/security/24_Megaphone_00.png';
import EscapeSlide from 'assets/icons/security/29_EscapeSlide_00.png';

export const icons = [
  AsbestosGloves,
  CockpitDoorKey,
  CrashAxe,
  CrewLifeVest,
  DemoBag,
  ExtensionSeat,
  FireExtinguisher,
  FirstAidKit,
  Flashlight,
  InfantLifeVest,
  InfantOxygenMask,
  InfantSeatBelt,
  ManualRelease,
  PaxLifeVest,
  OGenerator,
  OxygenBottle,
  OxygenMask,
  PBE,
  PhysicianKit,
  RadioBeacon,
  SpareSeatBelt,
  Megaphone,
  EscapeSlide,
];

export const getIconFromDescription = description => {
  if (!description) return null;
  // because some descriptionDoc have a space for no reason at the end
  const fixedDescription = description.replace(/ \s*$/, '');
  switch (fixedDescription) {
    case 'Attendant Life Vest':
      return CrewLifeVest;
    case 'Flight Crew Life Vest':
      return CrewLifeVest;
    case 'Infant Life Vest':
      return InfantLifeVest;
    case 'Pax Life Vest':
      return PaxLifeVest;
    case 'Pax LifeVest':
      return PaxLifeVest;
    case 'Fire Extinguisher':
      return FireExtinguisher;
    // case 'Smoke Hood':
    //   return tagSmokeHood;
    case 'O2 generator':
      return OGenerator;
    case 'Flash Light-Holder Assy':
      return Flashlight;
    case 'Additional Survival Kit':
      return FirstAidKit;
    case 'Manual Release Tool':
      return ManualRelease;
    case 'Escape Slide / Raft Door  2 LH':
      return EscapeSlide;
    case 'Escape Slide / Raft Door  2 RH':
      return EscapeSlide;
    default:
      return null;
  }
};

export const rfidMoment = date => {
  const momentObj = moment(date, 'YYYYMMDD');
  if (momentObj.isValid()) return momentObj;
  // TODO : shitty fallback for broken data
  return moment();
};

export const getMostRecentReading = readings => {
  let reading;

  readings.forEach(r => {
    try {
      const data = JSON.parse(r.reading);
      const date = moment(data.date);
      if (!reading || date.isAfter(moment(reading.date))) reading = r;
    } catch (e) {
      console.warn('Reading problem :', e);
      // spicy fucked up data
      // will send undefined which is the normal case for
      // no reading in the rest of the logic
    }
  });

  return reading;
};

export const manufacturerTable = {
  'William Frick': ' 3QRP3',
  ATAspec: ' 57399',
  AFencoded: '157399',
  P3: 'CE579',
};

export const isTagWF = tag => {
  const data = getMostRecentReading(tag.readings);
  if (!data) return false;
  const rawData = JSON.parse(data.reading);
  let isWF = false;
  // god is dead
  if (rawData.records.AirFranceRecords)
    isWF =
      rawData.records.AirFranceRecords.MFR ===
        manufacturerTable['William Frick'] ||
      ` ${rawData.records.AirFranceRecords.MFR}` ===
        manufacturerTable['William Frick'];
  if (!isWF && rawData.records.SingleRecord)
    isWF =
      rawData.records.SingleRecord.SPL === manufacturerTable['William Frick'] ||
      ` ${rawData.records.SingleRecord.SPL}` ===
        manufacturerTable['William Frick'];
  // TODO : this ain't over chief, API check on EPC for constant info
  return isWF;
};

export const getTEIList = alreadyAssignedTEIs =>
  getTEIListRaw(alreadyAssignedTEIs).map(tei => ({ text: tei, value: tei }));

export const getTEIListRaw = alreadyAssignedTEIs =>
  ['PNO', 'PNR', 'SER', 'MFR', 'EXP', 'DOH'].filter(
    tei => !alreadyAssignedTEIs.includes(tei),
  );

export const isDateTEI = tei => ['EXP', 'DOH'].includes(tei);

export const makeXLSX = tags => {
  const standardTEIs = getTEIListRaw([]);
  const data = [
    [
      'Registration',
      'Row',
      'Position',
      'Area',
      'Location Pax',
      'Type',
      'PN',
      'Alternate PN',
      'Description',
      'date',
      ...standardTEIs,
      'EPC',
      'Other',
    ],
  ];
  tags.forEach(tag => {
    if (tag.description === 'All tags') return;
    const reading = tag.reading || getMostRecentReading(tag.readings);
    const alternates = tag.alternatePartNumber
      ? JSON.parse(tag.alternatePartNumber).join('\n')
      : 'N/A';
    const excelLine = [
      tag.registration,
      tag.row,
      tag.position,
      tag.area,
      tag.locationPax,
      tag.subAssemblyName,
      tag.partNumber,
      alternates,
      tag.descriptionDoc,
      (tag.epc !== 'NC' && reading && reading.date && reading.date) || 'N/A',
    ];
    let other = '';
    if (tag.epc !== 'NC' && reading) {
      const teiData = ['', '', '', '', '', 'N/A'];
      const data = JSON.parse(reading.reading);
      const sortedRecords = Object.entries(data.records).sort(
        ([recordA], [recordB]) => {
          if (recordB === 'LifeCycleRecord') {
            return -1;
          }
          if (recordA === 'LifeCycleRecord') {
            return 1;
          }
          return 0;
        },
      );
      standardTEIs.map(tei => {
        // ES6 champions 2019
        let latestExpiry = null;
        for (let [, teiList] of sortedRecords) {
          for (let [teiKey, teiValue] of Object.entries(teiList)) {
            if (tei === teiKey) {
              if (tei === 'EXP' && teiValue) {
                if (!latestExpiry) {
                  latestExpiry = teiValue;
                  teiData[standardTEIs.indexOf(tei)] = teiValue;
                } else if (
                  rfidDateToMoment(latestExpiry).isBefore(
                    rfidDateToMoment(teiValue),
                  )
                ) {
                  latestExpiry = teiValue;
                  teiData[standardTEIs.indexOf(tei)] = teiValue;
                }
              } else {
                teiData[standardTEIs.indexOf(tei)] = teiValue;
              }
            }
          }
        }
      });
      Object.keys(data.records).forEach(category => {
        const teiValues = data.records[category];
        const nonStandardTEI = Object.keys(teiValues).filter(
          recordTEI => !standardTEIs.includes(recordTEI),
        );
        nonStandardTEI.forEach(recordTEI => {
          other = `${other}${category} - ${recordTEI} : ${
            teiValues[recordTEI]
          }\n`;
        });
      });
      excelLine.push(...teiData);
    } else {
      excelLine.push(...standardTEIs.map(() => 'NC'));
    }
    excelLine.push(tag.epc);
    if (other !== '') excelLine.push(other);
    data.push(excelLine);
  });
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Tags');
  XLSX.writeFile(workbook, 'tags.xlsx');
};

const table = {
  '001': 'P01202-205',
  '002': 'P01202-205D',
  '003': 'P01202-205WC',
  '004': 'P0640-105',
};
export const getRealAFPartNumber = encodedPN =>
  table[encodedPN] ? table[encodedPN] : encodedPN;

export const rfidDateToMoment = exp =>
  checkDateRegex(exp) ? moment(exp, 'YYYY-MM-DD') : moment(exp, 'YYYYMMDD');

export const checkDateRegex = date =>
  RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/, 'g').test(date);
