import * as types from 'config/types';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

export const getRFIDData = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const data = await API.get(ENDPOINTS.rfid.data, token);
  if (!data.aircraft || !data.tags) return;
  dispatch({
    type: types.RFID_SET_DATA,
    payload: data,
  });
};

export const resetRFIDAircraft = () => ({
  type: types.RFID_SET_SELECTED_AIRCRAFT,
  payload: { aircraftId: -1 },
});

export const selectRFIDAircraft = aircraftId => dispatch => {
  dispatch({
    type: types.RFID_SET_SELECTED_AIRCRAFT,
    payload: { aircraftId },
  });
  dispatch(loadRFIDScene(aircraftId));
};

export const loadRFIDScene = aircraft => async (dispatch, getState) => {
  const token = getState().auth.token;
  const data = await API.get(ENDPOINTS.rfid.scene, token, { aircraft });
  dispatch({
    type: types.RFID_SET_SCENE,
    payload: data,
  });
};

export const selectRFIDEquipment = selectedEquipmentId => ({
  type: types.RFID_SET_SELECTED_EQUIPMENT,
  payload: { selectedEquipmentId },
});

export const selectRFIDCategory = category => ({
  type: types.RFID_SET_SELECTED_CATEGORY,
  payload: { category },
});

export const setRFIDTagFilter = tagFilter => ({
  type: types.RFID_SET_TAG_FILTER,
  payload: { tagFilter },
});

export const setRFIDEditedTag = editedTag => ({
  type: types.RFID_SET_EDITED_TAG,
  payload: { editedTag },
});
