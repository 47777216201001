import React from 'react';
import { Segment, Grid, Image, Button } from 'semantic-ui-react';
import { hasPermissionToEdit } from 'lib/permissions';
import placeholder from 'assets/logo-placeholder.png';

export default ({
  requestId,
  data,
  modifyRequests,
  getPDFSrc,
  handleOpenPDFModal,
  noPDF,
  permissions,
}) => {
  const thumbnailSrc = noPDF
    ? placeholder
    : getPDFSrc()
        .replace('.pdf', '.jpg')
        .replace('figurepdf', 'figurejpeg');
  return (
    <Segment>
      <Grid columns={3}>
        <Grid.Column width={3}>
          <Image
            fluid
            src={thumbnailSrc}
            bordered
            rounded
            onClick={() => (noPDF ? null : handleOpenPDFModal())}
            className={noPDF ? '' : 'pointerCursor'}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <div>
            <h1 style={styles.lightText}>{data.headerLeft.document}</h1>
            <h1 style={styles.lightText}>
              <span style={styles.purpleText}>Figure: </span>
              {data.headerLeft.figure}
              <span> </span>
              <span style={styles.purpleText}>Item: </span>
              {data.headerLeft.item}
            </h1>
            <h1 style={styles.lightText}>
              <span style={styles.purpleText}>PN: </span>
              <span style={styles.boldText}>{data.headerLeft.pn}</span>
            </h1>
            <h1 style={{ ...styles.lightText, ...styles.italicText }}>
              {data.headerLeft.description}
            </h1>
            {data.remarks.map((remark, index) => (
              <h1
                key={index}
                style={{
                  ...styles.lightText,
                  ...styles.italicText,
                  ...styles.smallText,
                }}
              >
                {remark}
              </h1>
            ))}
          </div>
        </Grid.Column>
        <Grid.Column width={3}>
          <div style={{ textAlign: 'center' }}>
            <h1
              style={{
                marginTop: '0.25em',
                marginBottom: '0.25em',
              }}
            >
              {data.headerRight.aircraft}
            </h1>
            <h1 style={styles.lightText}>{data.headerRight.msn}</h1>
            <h1
              style={{
                fontSize: '1.5em',
                marginTop: '0.25em',
                marginBottom: '0.25em',
              }}
            >
              {data.headerRight.pax}
            </h1>
            <div className="requestModifyButton">
              {hasPermissionToEdit(permissions, 'Request') && (
                <Button
                  icon="edit"
                  label="Modify request"
                  onClick={() => modifyRequests([requestId])}
                />
              )}
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

const styles = {
  lightText: {
    fontWeight: '400',
    fontSize: '1.25em',
    marginTop: '0.25em',
    marginBottom: '0.25em',
  },
  purpleText: {
    color: '#d949a0',
  },
  boldText: {
    fontWeight: 'bold',
  },
  italicText: {
    fontStyle: 'italic',
  },
  smallText: {
    fontSize: '1em',
  },
};
