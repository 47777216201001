import React from 'react';
import { Label } from 'semantic-ui-react';

export default ({ step, tooltipProps }) => (
  <div {...tooltipProps}>
    <Label size="big" color="green">
      {step.content}
    </Label>
  </div>
);
