/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { ChartBarSelector } from '../components/charBarSelector';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';
import StandaloneTable from 'app/explorer/containers/standaloneTable';
import connect from 'lib/reduxConnect';

const MainMaintenance = ({
  token,
  setRequestsIds,
  firstCall,
  chartConfig,
  actions,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [tableExpanded, setTableExpanded] = useState(false);
  const [previousPeriode, setPreviousPeriode] = useState(null);
  const [previousGroupBy, setPreviousGroupBy] = useState(null);
  const [noData, setNoData] = useState(false);
  const [noDataTitle, setNoDataTitle] = useState(
    'No data available for this selection!',
  );
  const [noDataSubtitle, setNoDataSubtitle] = useState(
    'Try changing your selection criteria.',
  );

  useEffect(() => {
    if (
      (previousPeriode && chartConfig.periode !== previousPeriode) ||
      (previousGroupBy && chartConfig.groupBy !== previousGroupBy)
    ) {
      setShowTable(false);
      setIsLoaded(false);
    }
    if (firstCall) {
      getData();
    } else {
      setNoData(false);
      setData(chartConfig.data);
      setShowTable(true);
      setIsLoaded(true);
    }
  }, [chartConfig]);

  const getData = async () => {
    setPreviousPeriode(chartConfig.periode);
    setPreviousGroupBy(chartConfig.groupBy);
    try {
      const data = await API.get(
        ENDPOINTS.webreporting.requestsMaintenance,
        token,
        {
          interval: chartConfig.periode,
          groupBy: chartConfig.groupBy || 'date',
        },
      );

      if (data.code && data.message && data.stack) {
        setNoDataTitle(`Error ${data.code}: ${data.message}`);
        setNoDataSubtitle(data.stack);
        setNoData(true);
      }

      if (!data.error.value) {
        setNoData(false);
        setData(data);
        setNoData(false);
      } else {
        setNoDataTitle('No data available for this selection!');
        setNoDataSubtitle('Try changing your selection criteria.');
        setNoData(true);
      }
    } catch (error) {
      setNoData(true);
    }

    setIsLoaded(true);
  };

  const categorieClicked = (idsRequests, pagination) => {
    actions.setFirstCall(false);
    actions.setChartConfig({
      ...chartConfig,
      pagination: pagination,
      data: data,
    });
    setRequestsIds(idsRequests);
    setShowTable(true);
  };

  return (
    <div className="w-100 h-100">
      {!isLoaded && (
        <div class="ui active inverted dimmer">
          <div class="ui large text loader">Build in progress...</div>
        </div>
      )}
      {noData && (
        <div
          class="ui warning message"
          style={{ marginLeft: '10px', marginRight: '10px' }}
        >
          <div class="header">{noDataTitle}</div>
          {noDataSubtitle}
        </div>
      )}
      {isLoaded && !noData && (
        <div className={tableExpanded ? 'h-10' : 'h-50'}>
          <ChartBarSelector
            sizeHeight={tableExpanded ? 0 : 300}
            options={data}
            onClickCategorie={(idsRequests, pagination) => {
              categorieClicked(idsRequests, pagination);
            }}
            onClickSize={expand => {
              setTableExpanded(expand);
            }}
            defaultPage={chartConfig ? chartConfig.pagination : 0}
          />
        </div>
      )}

      {showTable && (
        <div
          className="explorer w-100"
          style={{ height: tableExpanded ? '95%' : '55%', marginTop: '30px' }}
        >
          <StandaloneTable />
          {/* <SizeMe monitorHeight>
            {({ size }) => (
              <div className="explorerTable">
                <Table
                  filters={filters}
                  currentSelectedData={selectedData}
                  token={token}
                  modifyRequests={actions.modifyRequests}
                  viewRequest={actions.viewRequest}
                  modifiedRequestIds={modifiedRequestIds}
                  requestIds={idsRequests}
                  height={size.height}
                  search={search}
                  dirty={tableDirty}
                  markDirty={actions.markDirty}
                  cache={tableCache}
                  setCache={actions.setTableRequests}
                />
              </div>
            )}
          </SizeMe> */}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = function(state) {
  return {
    firstCall: state.maintenance.firstCall,
    chartConfig: state.maintenance.chartConfig,
    selectedData: state.explorer.selectedData,
    modifiedRequestIds: state.explorer.modifiedRequestIds,
    tableDirty: state.explorer.tableDirty,
    tableCache: {
      requests: state.explorer.requests,
      availableColumns: state.explorer.availableColumns,
      displayedColumns: state.explorer.displayedColumns,
    },
    search: state.ui.search,
    filters: state.explorer.filters,
  };
};

export default connect(mapStateToProps)(MainMaintenance);
