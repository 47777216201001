import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  VictoryChart,
  VictoryPie,
  VictoryAxis,
  VictoryLegend,
  VictoryLabel,
  VictoryTooltip,
} from 'victory';
import { Popup } from 'semantic-ui-react';
import sizeMe from 'react-sizeme';
import { defaultChartHeight } from 'app/home/homeConstants';

class Chart extends PureComponent {
  render() {
    return (
      <div
        style={{
          height: defaultChartHeight * this.props.heightMultiplier,
        }}
      >
        <svg height={this.props.size.height} width={this.props.size.width}>
          <VictoryChart
            standalone={false}
            domainPadding={{ x: 40, y: [0, 20] }}
            height={this.props.size.height}
            width={this.props.size.width}
            scale={this.props.scale}
            padding={
              this.props.padding || { top: 10, left: 10, right: 10, bottom: 60 }
            }
            animate={{
              duration: 400,
              easing: 'circleInOut',
              onLoad: { duration: 400, easing: 'circleInOut' },
            }}
          >
            {this.props.children}
            {this.props.empty === false && (
              <VictoryAxis
                style={style.axis}
                tickValues={this.props.ticks}
                tickFormat={this.props.tickFormat}
              />
            )}
          </VictoryChart>
        </svg>
      </div>
    );
  }
}

Chart.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  heightMultiplier: PropTypes.number,
  children: PropTypes.node.isRequired,
  scale: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ticks: PropTypes.array,
  tickFormat: PropTypes.func,
  empty: PropTypes.bool,
  padding: PropTypes.object,
};

Chart.defaultProps = {
  scale: 'linear',
  empty: false,
};

export default sizeMe({
  monitorHeight: true,
  refreshRate: 128,
  refreshMode: 'debounce',
})(Chart);

const TooltipInteractionRadius = 10;
const TooltipInteractionWidth = 30;
const TooltipInteractionHeightMod = 90;
export class Tooltip extends React.Component {
  state = {
    maxHeight: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.allData) {
      const maxValue = Math.max(...props.allData.map(d => d.y));
      const biggestSerie = props.allData.find(d => d.y === maxValue).x;
      const maxHeight = props.allData
        .filter(d => d.x === biggestSerie)
        .reduce((acc, d) => acc + d.y, 0);

      return { maxHeight };
    }
    return null;
  }

  renderCircleTrigger() {
    return (
      <g>
        <circle
          cx={this.props.x}
          cy={this.props.y + 10}
          r={TooltipInteractionRadius}
          fill="transparent"
        />
        <circle
          cx={this.props.x}
          cy={this.props.y + 10}
          r={5}
          style={this.props.style}
        />
      </g>
    );
  }
  renderRectTrigger() {
    return (
      <g>
        <rect
          x={this.props.x - TooltipInteractionWidth / 2}
          y={this.props.y + 10}
          width={TooltipInteractionWidth}
          height={
            (this.props.datum.y / this.state.maxHeight) *
            (defaultChartHeight - TooltipInteractionHeightMod)
          }
          fill="transparent"
        />
      </g>
    );
  }

  render() {
    return (
      <Popup
        position="top center"
        hideOnScroll
        inverted
        trigger={
          this.props.rect
            ? this.renderRectTrigger()
            : this.renderCircleTrigger()
        }
      >
        <div style={this.props.flyoutStyle}>
          {this.props.text(this.props.datum)}
        </div>
      </Popup>
    );
  }
}
Tooltip.defaultEvents = VictoryTooltip.defaultEvents;

const normalizedPieSize = 250;
const pieTooltipSize = 80;

class PieLabel extends React.Component {
  render() {
    const total = this.props.data.reduce((acc, d) => acc + d.y, 0);
    const percentage = Math.round((this.props.datum.y / total) * 100);
    return (
      <g>
        <VictoryLabel {...this.props} />
        <VictoryTooltip
          {...this.props}
          text={`${this.props.datum.x}
          
          ${this.props.datum.y}
          ${percentage}%`}
          renderInPortal={false}
          orientation="top"
          x={normalizedPieSize / 2}
          y={normalizedPieSize / 2 + pieTooltipSize / 2}
          pointerLength={0}
          cornerRadius={pieTooltipSize / 2}
          width={pieTooltipSize}
          height={pieTooltipSize}
          flyoutStyle={{
            fill: d => this.props.colorScale[this.props.index],
            strokeWidth: 0,
          }}
        />
      </g>
    );
  }
}

PieLabel.defaultEvents = VictoryTooltip.defaultEvents;
PieLabel.propTypes = {
  colorScale: PropTypes.array,
  datum: PropTypes.object,
  data: PropTypes.array,
  index: PropTypes.number,
};

class BasePieChart extends PureComponent {
  render() {
    const colorScale = this.props.colorScale || colors;
    return (
      <svg
        height={this.props.size.height}
        width={this.props.size.width}
        viewBox={`0 0 ${normalizedPieSize} ${normalizedPieSize}`}
      >
        <g transform="translate(0 -25)">
          <VictoryPie
            name="pieChart"
            colorScale={colorScale}
            data={this.props.data}
            height={normalizedPieSize}
            width={normalizedPieSize}
            radius={this.props.radius || normalizedPieSize / 2 - 40}
            labelRadius={normalizedPieSize / 2 - 62}
            innerRadius={this.props.innerRadius || normalizedPieSize / 2 - 70}
            style={{
              labels: {
                ...style.tickLabels,
                fill: 'white',
                fontFamily: 'Open Sans',
                fontSize: 12,
              },
            }}
            padAngle={1}
            labels={d => d.y}
            labelComponent={<PieLabel colorScale={colorScale} />}
            animate={{
              duration: 400,
              easing: 'circleInOut',
              onLoad: { duration: 400, easing: 'circleInOut' },
            }}
            standalone={false}
          />
        </g>
        <VictoryLegend
          y={normalizedPieSize - 45}
          standalone={false}
          orientation="horizontal"
          style={style.legend}
          itemsPerRow={3}
          data={this.props.data.map((d, index) => ({
            name: d.x,
            symbol: { fill: colorScale[index] },
          }))}
        />
        {this.props.children}
      </svg>
    );
  }
}
export const PieChart = sizeMe({ monitorHeight: true, refreshRate: 128 })(
  BasePieChart,
);

export const makeVictoryStyle = (data, labels) => (
  addedStyle,
  labelAddedStyle,
) => ({
  data: { ...data, ...addedStyle },
  labels: { ...labels, ...(labelAddedStyle || addedStyle) },
});

const colors = ['#1A535C', '#4ECDC4', '#B4BAB4', '#FF6B6B', '#FFE66D'];

const style = {
  axis: {
    axis: {
      strokeWidth: 0.25,
      strokeOpacity: 0.25,
    },
    tickLabels: {
      fontSize: 12,
      fill: 'rgba(0,0,0,0.5)',
      fontFamily: 'Open Sans',
    },
  },
  legend: {
    labels: {
      fontSize: 12,
      fill: 'rgba(0,0,0,0.75)',
      fontFamily: 'Open Sans',
    },
  },
};
