import React, { PureComponent } from 'react';
import { List, Icon, Header } from 'semantic-ui-react';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';
import { PieChart } from 'app/home/components/chart';

const targets = [
  {
    title: 'Actions',
    type: 'PrioritygroupName',
  },
  {
    title: 'Request status',
    type: 'Requeststatus',
  },
  {
    title: 'Aircraft Type',
    type: 'Aircrafttype',
  },
  {
    title: 'Equipment Family',
    type: 'Familyname',
  },
  {
    title: 'Equipment Class',
    type: 'LopaEquipmentclass',
  },
];

export default class MaintenanceActionsKPI extends PureComponent {
  state = {
    loading: true,
    error: false,
    data: [],
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data instanceof Array) {
        this.setState({
          loading: false,
          data: this.props.data,
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  onSettingsClose = async () => {
    await this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
    this.forceUpdate();
  };

  setTarget = async targetType => {
    await this.props.saveKPI(this.props.kpi.i, { targetType });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  getHeader() {
    const currentTarget = targets.find(
      target => target.type === this.props.kpi.payload.targetType,
    );
    return (
      <KPI.Header
        title={`maintenance by ${(currentTarget && currentTarget.title) ||
          'Actions'}`}
        onClose={this.onSettingsClose}
      >
        <List relaxed divided selection>
          <Header>
            Maintenance actions
            <Header.Subheader>
              Choose which kind of data you wish to display in this block
            </Header.Subheader>
          </Header>
          {targets.map((filter, index) => (
            <List.Item key={index} onClick={() => this.setTarget(filter.type)}>
              <List.Content floated="right">
                <Icon name="chevron right" />
              </List.Content>
              <List.Content>
                <List.Header>{filter.title}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </KPI.Header>
    );
  }

  render() {
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.state.loading}
        error={this.state.error}
        getData={this.getData}
        noData={this.state.data.length === 0}
        deleteKPI={this.props.deleteKPI}
      >
        {this.getHeader()}
        <KPI.Count
          value={this.state.data.reduce((acc, d) => acc + d.y, 0)}
          unit={'total requests'}
        />
        <KPI.Data>
          <PieChart data={this.state.data} />
        </KPI.Data>
      </KPI>
    );
  }
}

MaintenanceActionsKPI.propTypes = {
  ...KPIProps,
};
