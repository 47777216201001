import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

const ModifyFilterModal = ({ open, lastFilter, onClose, onConfirm }) => (
  <Modal open={open} onClose={onClose} closeIcon>
    <Header icon="unlock" content="Modify filter" />
    <Modal.Content>
      <p>
        {lastFilter
          ? `This will reset this filter's selection, do you wish to continue?`
          : `This will remove all filters set after this one, do you wish to continue?`}
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button color="red" onClick={onClose}>
        <Icon name="remove" /> No
      </Button>
      <Button color="green" onClick={onConfirm}>
        <Icon name="checkmark" /> Yes
      </Button>
    </Modal.Actions>
  </Modal>
);

export default ModifyFilterModal;
