import * as network from 'config/network';

export const request = (url, options, json = true) => {
  return fetch(url, options)
    .then(response => {
      if (json) return response.json();
      return response;
    })
    .catch(err => {
      console.warn(err);
      return null;
    });
};

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export const get = (
  url,
  {
    queries = {},
    headers = {
      ...defaultHeaders,
    },
  } = {},
  json = true,
) => {
  let queryUrl = `${url}?`;
  Object.keys(queries).forEach((query, index, arr) => {
    let suffix = '';
    if (index < arr.length) suffix = '&';
    queryUrl = `${queryUrl}${query}=${queries[query]}${suffix}`;
  });

  return request(queryUrl, { method: 'GET', headers }, json);
};

export const post = (
  url,
  {
    body = null,
    headers = {
      ...defaultHeaders,
    },
  } = {},
  json = true,
) => {
  const options = { method: 'POST', headers };
  if (body !== null && typeof body === 'object')
    options.body = JSON.stringify(body);
  return request(url, options, json);
};

export const buildHeaders = token => {
  const headers = {
    ...defaultHeaders,
  };
  if (token) headers.Authorization = `Bearer ${token}`;
  return headers;
};

export const buildURL = endpoint => {
  let base = false;
  let endpointPath = endpoint;
  if (endpoint.base === true) {
    base = true;
    endpointPath = endpoint.path;
  }
  return `${base ? network.API_BASE : network.API}${endpointPath}`;
};

export const buildData = data => ({
  ...data,
  ...network.DEVICE_ID,
});

export const API = {
  get: (endpoint, token = false, baseQueries = {}) => {
    const headers = buildHeaders(token);
    const queries = buildData(baseQueries);
    return get(buildURL(endpoint), {
      queries,
      headers,
    });
  },
  post: (endpoint, token = false, baseBody = {}) => {
    const headers = buildHeaders(token);
    const body = buildData(baseBody);
    return post(buildURL(endpoint), {
      body,
      headers,
    });
  },
};
