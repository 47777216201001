import React, { PureComponent } from 'react';
import { VictoryBar, VictoryGroup, VictoryLegend } from 'victory';
import { defaultChartHeight } from 'app/home/homeConstants';

import { KPIProps } from 'app/home/components/types';
import KPI from 'app/home/components/kpi';
import Chart from 'app/home/components/chart';
import { svgDefs, linearGradient, dropShadow } from 'lib/svg';
import { Header, Form, Dropdown } from 'semantic-ui-react';

export default class TATKPI extends PureComponent {
  state = {
    loading: true,
    error: false,
    avgClosed: [],
    specificClosed: [],
  };

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = async () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data.specificClosed) {
        const { specificClosed, avgClosed } = this.props.data;
        this.setState({
          loading: false,
          error: false,
          specificClosed,
          avgClosed,
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  setTarget = async target => {
    await this.props.saveKPI(this.props.kpi.i, { target });
    this.props.loadKPI(this.props.kpi.i, this.props.kpi.payload);
  };

  getHeader() {
    return [
      <Header key="aircraftHeader">
        Compared aircraft
        <Header.Subheader>
          Select an aircraft registration in the dropdown to get its TAT
          compared to your fleet. You can type in the dropdown to filter the
          list. The number is the amount of currently open requests.
        </Header.Subheader>
      </Header>,
      <Form key="aircraftSettings">
        <Form.Field>
          <Dropdown
            placeholder="Selected aircraft"
            fluid
            search
            selection
            options={
              this.props.rawData.aircraft &&
              this.props.rawData.aircraft.map(aircraft => {
                const requestCount =
                  parseFloat(aircraft.routineCount) +
                  parseFloat(aircraft.criticalCount);
                return {
                  key: aircraft.registration,
                  value: aircraft.registration,
                  text: `${aircraft.registration} - ${aircraft.type}`,
                  description: `${requestCount}`,
                };
              })
            }
            value={this.props.kpi.payload.target}
            onChange={(e, { value }) => this.setTarget(value)}
          />
        </Form.Field>
      </Form>,
    ];
  }

  render() {
    return (
      <KPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        loading={this.state.loading}
        error={this.state.error}
        getData={this.getData}
        deleteKPI={this.props.deleteKPI}
      >
        <KPI.Header
          title={`Turn-Around Time ${
            this.props.kpi.payload.target
              ? `for ${this.props.kpi.payload.target}`
              : '(please configure)'
          }`}
        >
          {this.getHeader()}
        </KPI.Header>
        <KPI.Data>
          {defs}
          <Chart heightMultiplier={this.props.kpi.h}>
            <VictoryLegend
              y={defaultChartHeight / 2 + 100}
              orientation="horizontal"
              style={style.legend}
              data={[
                {
                  name: this.props.kpi.payload.target || 'No selected aircraft',
                  value: this.props.kpi.payload.target || 'none',
                  symbol: { fill: '#56ab2f' },
                },
                {
                  name: 'Fleet average',
                  value: 'Fleet average',
                  symbol: { fill: '#bc4e9c' },
                },
              ]}
            />
            <VictoryGroup
              offset={style.group.data.width}
              style={style.group}
              colorScale={['url(#closedGrad)', 'url(#closedGrad2)']}
            >
              <VictoryBar
                data={this.state.specificClosed}
                style={{ labels: { ...style.label, fill: '#56ab2f' } }}
                labels={d => d.y}
              />
              <VictoryBar
                data={this.state.avgClosed}
                style={{ labels: { ...style.label, fill: '#bc4e9c' } }}
                labels={d => d.y}
              />
            </VictoryGroup>
          </Chart>
        </KPI.Data>
      </KPI>
    );
  }
}

TATKPI.propTypes = {
  ...KPIProps,
};

const style = {
  tooltip: {
    strokeWidth: 0,
    fill: 'white',
    filter: 'url(#dropshadow)',
  },
  openRequests: {
    fill: 'url(#openGrad)',
  },
  closedRequests: {
    fill: 'url(#closedGrad)',
  },
  legend: {
    labels: {
      fontSize: 14,
      fill: 'rgba(0,0,0,0.5)',
      fontFamily: 'Open Sans',
    },
  },
  group: {
    data: {
      width: 40,
      fillOpacity: 0.7,
    },
  },
  label: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
};

const defs = svgDefs(
  linearGradient('closedGrad', [
    { offset: '0%', color: '#56ab2f' },
    { offset: '100%', color: '#a8e063' },
  ]),
  linearGradient('closedGrad2', [
    { offset: '0%', color: '#bc4e9c' },
    { offset: '100%', color: '#f80759' },
  ]),
  dropShadow('dropshadow'),
);
