import React, { PureComponent } from 'react';
import moment from 'moment';
import { Radio, Header, Form } from 'semantic-ui-react';

import { KPIProps } from 'app/home/components/types';
import { svgDefs, linearGradient, dropShadow } from 'lib/svg';
import { formatStepLabel } from 'app/util/datepicker';

import TimedKPI, { getVariation } from './timedKPI';

const getDataArray = (requestsCreated, requestsClosed, requestsOpen, focus) => {
  let dataArray;
  switch (focus) {
    case 'closed':
      dataArray = requestsClosed;
      break;
    case 'open':
      dataArray = requestsOpen;
      break;

    default:
      dataArray = requestsCreated;
      break;
  }
  return dataArray;
};

export default class RequestCountKPI extends PureComponent {
  state = {
    requestsCreated: [],
    requestsClosed: [],
    requestsOpen: [],
    series: [],
    ticks: [],
    tickFormat: 'HH:00',
    loading: true,
    error: false,
    focus: 'created',
    variation: {
      value: 0,
      diff: 0,
      inverted: false,
      unit: 'requests',
    },
  };

  // called on init and updates, prevents desynced focus props
  static getDerivedStateFromProps(props, state) {
    if (state.focus !== props.kpi.payload.focus)
      return {
        focus: props.kpi.payload.focus,
        variation: getVariation(
          getDataArray(
            state.requestsCreated,
            state.requestsClosed,
            state.requestsOpen,
            props.kpi.payload.focus,
          ),
          props.kpi.payload.focus !== 'closed',
          'requests',
        ),
      };
    return null;
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) this.getData();
  }

  getData = () => {
    if (this.props.data) {
      if (this.props.data.loading) {
        this.setState({ loading: true });
      } else if (this.props.data.ticks) {
        const {
          ticks,
          requestsCreated,
          requestsClosed,
          requestsOpen,
        } = this.props.data;
        this.setState({
          requestsCreated,
          requestsClosed,
          requestsOpen,
          series: [
            {
              name: 'created',
              type: 'bar',
              data: requestsCreated,
              styles: { data: style.createdRequests },
            },
            {
              name: 'closed',
              type: 'bar',
              data: requestsClosed,
              styles: { data: style.closedRequests },
            },
            {
              name: 'open',
              type: 'line',
              data: requestsOpen,
              styles: {
                data: style.openRequests,
                labels: style.openRequestsTooltip,
              },
            },
          ],
          loading: false,
          error: false,
          variation: getVariation(
            getDataArray(
              requestsCreated,
              requestsClosed,
              requestsOpen,
              this.state.focus,
            ),
            this.state.focus !== 'closed',
            'requests',
          ),
          ticks,
          tickFormat: formatStepLabel(this.props.interval.intervalType),
          data: this.props.data,
        });
      } else if (this.props.data.error) {
        this.setState({ loading: false, error: true });
      }
    }
  };

  handleFocusChange = (e, { value }) => {
    this.props.saveKPI(this.props.kpi.i, { focus: value });
  };

  getLegend = () => [
    {
      name: 'Created',
      symbol: style.createdRequests,
      labels: {
        fontWeight: () =>
          this.state.focus === 'created' ? 'bolder' : 'normal',
      },
    },
    {
      name: 'Closed',
      symbol: style.closedRequests,
      labels: {
        fontWeight: () => (this.state.focus === 'closed' ? 'bolder' : 'normal'),
      },
    },
    {
      name: 'Open',
      symbol: style.openRequestsTooltip,
      labels: {
        fontWeight: () => (this.state.focus === 'open' ? 'bolder' : 'normal'),
      },
    },
  ];

  getHeader() {
    return [
      <Header key="focusHeader">
        Focused count
        <Header.Subheader>
          The selected count is reflected in the tendency widget
        </Header.Subheader>
      </Header>,
      <Form key="focusSettings">
        <Form.Field>
          <Radio
            toggle
            label="Created"
            name="focusGroup"
            value="created"
            checked={this.state.focus === 'created'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label="Closed"
            name="focusGroup"
            value="closed"
            checked={this.state.focus === 'closed'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            toggle
            label="Open"
            name="focusGroup"
            value="open"
            checked={this.state.focus === 'open'}
            onChange={this.handleFocusChange.bind(this)}
          />
        </Form.Field>
      </Form>,
    ];
  }

  render() {
    return (
      <TimedKPI
        kpi={this.props.kpi}
        isDraggable={this.props.isDraggable}
        saveKPI={this.props.saveKPI}
        loadKPI={this.props.loadKPI}
        deleteKPI={this.props.deleteKPI}
        title="Request Evolution"
        series={this.state.series}
        getData={this.getData}
        ticks={this.state.ticks}
        tickFormat={t => moment(t).format(this.state.tickFormat)}
        variation={this.state.variation}
        legend={this.getLegend()}
        loading={this.state.loading}
        error={this.state.error}
        extraSettings={this.getHeader()}
        svgDefs={defs}
      />
    );
  }
}

RequestCountKPI.propTypes = {
  ...KPIProps,
};

const style = {
  createdRequests: {
    fill: 'url(#createdGrad)',
  },
  closedRequests: {
    fill: 'url(#closedGrad)',
  },
  openRequests: {
    stroke: '#fc4a1aaa',
    strokeWidth: 2,
  },
  openRequestsTooltip: {
    fill: '#fc4a1aaa',
  },
};

const defs = svgDefs(
  linearGradient('closedGrad', [
    { offset: '0%', color: '#56ab2f' },
    { offset: '100%', color: '#a8e063' },
  ]),
  linearGradient('createdGrad', [
    { offset: '0%', color: '#2193b0' },
    { offset: '100%', color: '#6dd5ed' },
  ]),
  dropShadow('dropshadow'),
);
