import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

export default ({ customField, value, updateValue }) => {
  return (
    <Form>
      <Dropdown
        placeholder="Value..."
        value={value}
        fluid
        selection
        options={customField.list.map(entry => ({
          ...entry,
          key: entry.value,
        }))}
        onChange={(e, d) => updateValue(d.value)}
      />
    </Form>
  );
};
