import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button } from 'semantic-ui-react';
import connect from 'lib/reduxConnect';
import ActionBar from '../components/actionBar';

const dateGroupingOptions = [
  {
    key: 'day',
    value: 'day',
    children: ['By day'],
  },
  {
    key: 'week',
    value: 'week',
    children: ['By week'],
  },
  {
    key: 'month',
    value: 'month',
    children: ['By month'],
  },
];

class ExplorerActions extends Component {
  toggleTable = () => {
    if (this.props.showTable) this.props.actions.hideTable();
    else this.props.actions.showTable();
  };

  renderActions() {
    const requestCount =
      (this.props.selectedData.length > 0 &&
        this.props.selectedData.reduce(
          (count, data) => count + data.requestCount,
          0,
        )) ||
      1;
    return (
      <div className="pageActions">
        <ActionBar
          requestCount={requestCount}
          currentSelectedData={this.props.selectedData}
          filters={this.props.filters}
          openWarningModal={this.openWarningModal}
          toggleTable={this.toggleTable}
          tableShown={this.props.showTable}
          saveBreadcrumb={this.props.actions.saveBreadcrumb}
        />
      </div>
    );
  }

  render() {
    if (this.props.showTable) return this.renderActions();
    const queryOptions =
      (this.props.breadcrumbs &&
        this.props.breadcrumbs.map((breadcrumb, index) => ({
          value: index,
          children: [
            <Button
              key={index}
              color="red"
              basic
              circular
              size="mini"
              icon="trash"
              onClick={e => {
                e.stopPropagation();
                this.props.deleteBreadcrumb(index);
              }}
            />,
            breadcrumb.name,
          ],
        }))) ||
      [];
    let dateOptions = null;
    if (this.props.currentFilter === 'Date') {
      dateOptions = (
        <Dropdown
          text="Date grouping"
          labeled
          button
          floating
          className="icon"
          icon="calendar"
          value={this.props.dateGrouping || 'day'}
          onChange={(e, { value }) => {
            this.props.actions.setDateGrouping(value);
          }}
          options={dateGroupingOptions}
        />
      );
    }
    return (
      <div className="pageActions">
        <Dropdown
          text="Saved queries"
          labeled
          button
          floating
          className="icon"
          icon="filter"
          disabled={
            !this.props.breadcrumbs || this.props.breadcrumbs.length === 0
          }
          value={this.props.currentBreadcrumb}
          onChange={(e, { value }) => {
            this.props.onBreadcrumbChange(value);
          }}
          options={queryOptions}
        />
        {dateOptions}
        <div>Sort by</div>
        <Dropdown
          placeholder="Sort"
          selection
          value={this.props.sortCards}
          onChange={(e, { value }) => {
            this.props.onChange(value);
          }}
          options={[
            { text: 'Requests High to Low', value: 'desc' },
            { text: 'Requests Low to High', value: 'asc' },
            { text: 'Alphabetical', value: 'alphaAsc' },
            { text: 'Inverse Alphabetical', value: 'alphaDesc' },
          ]}
        />
      </div>
    );
  }
}

ExplorerActions.propTypes = {
  sortCards: PropTypes.string,
  currentBreadcrumb: PropTypes.number,
  breadcrumbs: PropTypes.array,
  onChange: PropTypes.func,
  onBreadcrumbChange: PropTypes.func,
  deleteBreadcrumb: PropTypes.func,
  showTable: PropTypes.bool,
  selectedData: PropTypes.array,
  filters: PropTypes.array,
  currentFilter: PropTypes.string,
  dateGrouping: PropTypes.string,
};

const mapStateToProps = state => ({
  sortCards: state.explorer.sortCards,
  breadcrumbs: state.explorer.breadcrumbs,
  currentBreadcrumb: state.explorer.currentBreadcrumb,
  showTable: state.explorer.showTable,
  selectedData: state.explorer.selectedData,
  filters: state.explorer.filters,
  currentFilter: state.explorer.currentFilter,
  dateGrouping: state.explorer.dateGrouping,
});

export default connect(mapStateToProps)(ExplorerActions);
