import React, { Component } from 'react';
import {
  Form,
  Input,
  Button,
  Grid,
  Header,
  Segment,
  Message,
} from 'semantic-ui-react';
import moment from 'moment';

import connect from 'lib/reduxConnect';

class Profile extends Component {
  state = {
    userInformations: {
      email: this.props.user.email,
      firstname: this.props.user.firstName,
      lastname: this.props.user.lastName,
      phone: this.props.user.phone,
      mobile: this.props.user.mobile,
      title: this.props.user.title,
      station: this.props.user.station,
    },
    passwordInfos: {
      actualPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    userSuccessMessage: '',
    userErrorMessage: '',
    userPasswordSuccessMessage: '',
    userPasswordErrorMessage: '',
  };

  changeTab(tab) {
    this.props.actions.navigate(`/auth/profile/${tab}`);
  }

  handleUserChange = (e, { name, value }) => {
    this.handleChenge('userInformations', name, value);
  };

  handlePasswordChange = (e, { name, value }) => {
    this.handleChenge('passwordInfos', name, value);
  };

  handleChenge = (offset, name, value) => {
    const stateCopy = Object.assign({}, this.state);
    stateCopy[offset][name] = value;
    this.setState(stateCopy);
  };

  renderTabs() {
    return (
      <div className="accountTabs">
        <Button
          color={
            !this.props.match.params.tab ||
            this.props.match.params.tab === 'user'
              ? 'grey'
              : 'blue'
          }
          size="small"
          onClick={() => this.changeTab('user')}
        >
          User
        </Button>
        <Button
          color={this.props.match.params.tab === 'reports' ? 'grey' : 'blue'}
          size="small"
          onClick={() => this.changeTab('reports')}
        >
          Reports
        </Button>
      </div>
    );
  }

  updateUserInformations = async () => {
    const response = await this.props.actions.updateUserInformations(
      this.state.userInformations,
    );

    if (response) {
      this.setState({
        userSuccessMessage: 'Modifications saved',
        userErrorMessage: '',
        userPasswordSuccessMessage: '',
        userPasswordErrorMessage: '',
      });
    } else {
      this.setState({
        userErrorMessage: 'Error occured, please try later',
        userSuccessMessage: '',
        userPasswordSuccessMessage: '',
        userPasswordErrorMessage: '',
      });
    }
  };

  updatePassword = async () => {
    const response = await this.props.actions.updatePassword(
      this.state.passwordInfos,
    );
    if (response.success) {
      this.setState({
        userSuccessMessage: '',
        userErrorMessage: '',
        userPasswordSuccessMessage: response.success,
        userPasswordErrorMessage: '',
      });
    } else if (response.errors) {
      const err = Object.values(response.errors).join('\n');
      this.setState({
        userErrorMessage: '',
        userSuccessMessage: '',
        userPasswordSuccessMessage: '',
        userPasswordErrorMessage: err,
      });
    }
  };

  renderUser() {
    return (
      <Grid columns={2}>
        <Grid.Column>
          <Header as="h2">Personal information</Header>
          <Form>
            <Form.Field>
              <label>User name</label>
              <Input fluid value={this.props.user.login} disabled />
            </Form.Field>
            <Form.Field>
              <label>Company</label>
              <Input fluid value={this.props.user.customer} disabled />
            </Form.Field>
            <Form.Field>
              <label>Mail</label>
              <Input
                fluid
                name="email"
                value={this.state.userInformations.email}
                placeholder="email@company.com"
                onChange={this.handleUserChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Firstname</label>
              <Input
                fluid
                name="firstname"
                value={this.state.userInformations.firstname}
                onChange={this.handleUserChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Lastname</label>
              <Input
                fluid
                name="lastname"
                value={this.state.userInformations.lastname}
                onChange={this.handleUserChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Phone</label>
              <Input
                fluid
                name="phone"
                value={this.state.userInformations.phone}
                onChange={this.handleUserChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Mobile</label>
              <Input
                fluid
                name="mobile"
                value={this.state.userInformations.mobile}
                onChange={this.handleUserChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Title</label>
              <Input
                fluid
                name="title"
                value={this.state.userInformations.title}
                onChange={this.handleUserChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Station</label>
              <Input
                fluid
                name="station"
                value={this.state.userInformations.station}
                onChange={this.handleUserChange}
              />
            </Form.Field>
            <Form.Field
              onClick={() => this.updateUserInformations()}
              control={Button}
              primary
              content="Save"
            />
            {this.state.userSuccessMessage !== '' ? (
              <Form success>
                <Message success header={this.state.userSuccessMessage} />
              </Form>
            ) : (
              ''
            )}
            {this.state.userErrorMessage !== '' ? (
              <Form error>
                <Message error header={this.state.userErrorMessage} />
              </Form>
            ) : (
              ''
            )}
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Header as="h2">Password update</Header>
          <Form>
            <Form.Field>
              <label>Actual password</label>
              <Input
                type="password"
                name="actualPassword"
                fluid
                value={this.state.passwordInfos.actualPassword}
                onChange={this.handlePasswordChange}
              />
            </Form.Field>
            <Form.Field>
              <label>New password</label>
              <Input
                type="password"
                name="newPassword"
                fluid
                value={this.state.passwordInfos.newPassword}
                onChange={this.handlePasswordChange}
              />
            </Form.Field>
            <Form.Field>
              <label>New password confirmation</label>
              <Input
                type="password"
                name="newPasswordConfirm"
                fluid
                value={this.state.passwordInfos.newPasswordConfirm}
                onChange={this.handlePasswordChange}
              />
            </Form.Field>
            <Form.Field
              onClick={() => this.updatePassword()}
              control={Button}
              primary
              content="Save"
            />
          </Form>
          {this.state.userPasswordSuccessMessage !== '' ? (
            <Form success>
              <br />
              <Message success header={this.state.userPasswordSuccessMessage} />
            </Form>
          ) : (
            ''
          )}
          {this.state.userPasswordErrorMessage !== '' ? (
            <Form error>
              <br />
              <Message
                error
                header={this.state.userPasswordErrorMessage
                  .split('\n')
                  .map(item => (
                    <>
                      {' '}
                      {item} <br />{' '}
                    </>
                  ))}
              />
            </Form>
          ) : (
            ''
          )}
        </Grid.Column>
      </Grid>
    );
  }

  renderReports() {
    const reports = [];
    for (let i = 0; i < 10; i++) {
      reports.push(
        <Segment key={i} padded className="device">
          <Header>Maintenance Highlights</Header>
          <div className="deviceActions">
            <Button positive icon="eye" />
            <Button negative icon="remove" />
          </div>
          <div className="deviceMeta">
            <div>
              <b>Created:</b> {moment().format('Do MMMM YYYY')}
            </div>
          </div>
        </Segment>,
      );
    }
    return (
      <div>
        <Header as="h2">Saved reports</Header>
        <Segment.Group>{reports}</Segment.Group>
      </div>
    );
  }

  render() {
    return (
      <div className="secondaryContentMargin">
        {(!this.props.match.params.tab ||
          this.props.match.params.tab === 'user') &&
          this.renderUser()}
        {this.props.match.params.tab === 'reports' && this.renderReports()}
      </div>
    );
  }
}

export default connect()(Profile);
