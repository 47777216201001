import { push } from 'connected-react-router';
import * as types from 'config/types';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

export const getRequestDetail = id => async (dispatch, getState) => {
  const token = getState().auth.token;
  const data = await API.post(ENDPOINTS.webreporting.detail, token, {
    id,
  });
  dispatch({
    type: types.REQUEST_SET_DATA,
    payload: {
      id,
      data: data || 'error',
    },
  });
};

export const viewRequest = requestId => dispatch => {
  dispatch(getRequestDetail(requestId));
  dispatch(push(`/detail/${requestId}`));
  window.scrollTo(0, 0);
};
