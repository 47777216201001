import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

export default ({ location }) => (
  <div className="contentMargin">
    <Message negative icon>
      <Icon name="warning sign" size="massive" />
      <Message.Content>
        <Message.Header>You are not allowed to view this page</Message.Header>
      </Message.Content>
    </Message>
  </div>
);
