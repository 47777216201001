import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

export const getTagAssign = (id, payload, saveKPIData, saveKPIError) => async (
  dispatch,
  getState,
) => {
  const token = getState().auth.token;
  const body = {};
  if (payload.targetType !== 'all' && payload.target) {
    body[payload.targetType] = payload.target;
  }
  const kpiData = await API.get(ENDPOINTS.rfid.assign, token, body);
  if (!kpiData || kpiData.error) {
    dispatch(saveKPIError(id));
    return;
  }
  dispatch(saveKPIData(id, kpiData));
};
