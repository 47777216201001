import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  id: null,
  data: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.REQUEST_SET_DATA:
        draft.id = action.payload.id;
        draft.data = action.payload.data;
        break;
    }
  });
